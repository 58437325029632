<template>
  <div>
    <div v-if="!addPostSHow">
      <div class="posotListFlex postPadding">
        <p>职位列表</p>
        <div class="posotListFlex">
          <el-input placeholder="搜索"  v-model="searchName"></el-input>
          <el-button type="primary" round @click="getCompanyByJobUserId">筛选</el-button>
        </div>
      </div>
      <div class="addPOst" @click="goOpenPost">
        +新增职位
      </div>
      <div class="postFlex postJuscontent" v-for="(item,index) in postList" :key="index">
        <div>
          <p>{{item.jobTitle}}</p>
          <p class="postFontIntroduce">{{item.jobCity}}   |   {{item.jobExperience}}  |   {{item.jobSchool}}   |   {{item.jobMinimumSalary}}-{{item.jobMaximumSalary}}K</p>
        </div>
<!--        <div class="postFlex">-->
<!--          <p>-->
<!--            <span style="font-size: 20px">22</span>-->
<!--            <span class="postFontSize">人查看</span>-->
<!--          </p>-->
<!--          <p>-->
<!--            <span style="font-size: 20px">18</span>-->
<!--            <span class="postFontSize">人沟通</span>-->
<!--          </p>-->
<!--        </div>-->
        <div class="postFlex">
<!--          <p class="postFontSize">（2021-03-19日发布）</p>-->
          <p class="postColor" @click="redactPost(item)">编辑</p>
          <p class="postColor" @click="deleteCompanypost(item)">删除</p>
        </div>
      </div>
      <div style="margin: 30px 0">
        <el-pagination
            class="text_center"
            background
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="pagesize"
            layout="total,  prev, pager, next, jumper"
            :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <div v-if="addPostSHow" class="postListWindow" >
      <div style="width: 600px;margin: 0 auto">
        <p style="font-size: 20px;margin: 20px 0">1 职位基本信息</p>
        <el-form class="postise" :label-position="labelPosition" label-width="80px" :model="postformLabelAlign">
          <el-form-item label="公司">
            <el-input v-model="postformLabelAlign.companyTitle"></el-input>
          </el-form-item>
          <el-form-item label="招聘类型">
            <el-select v-model="postformLabelAlign.jobPublishType" placeholder="请选择">
              <el-option
                  v-for="item in postTypeArr"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="职位名称">
            <el-input v-model="postformLabelAlign.jobTitle"></el-input>
          </el-form-item>
          <el-form-item label="工作城市">
            <el-input v-model="postformLabelAlign.jobCity" @focus="allCity"></el-input>
          </el-form-item>
          <el-form-item label="工作地点">
            <el-input v-model="postformLabelAlign.jobArea"></el-input>
          </el-form-item>
        </el-form>
        <p style="font-size: 20px;margin: 20px 0">2 职位要求</p>
        <el-form class="postAskWd" :label-position="labelPosition" label-width="80px" :model="postAskform">
          <el-form-item label="经验和学历" >
            <div style="display: flex;width: 90%;justify-content: space-between">
              <el-select v-model="postformLabelAlign.jobExperience" placeholder="请选择">
                <el-option
                    v-for="item in experience"
                    :key="item.id"
                    :label="item.label"
                    :value="item.label">
                </el-option>
              </el-select>
              <el-select v-model="postformLabelAlign.jobSchool" placeholder="请选择">
                <el-option
                    v-for="item in education"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name">
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="薪资范围">
            <div style="display: flex;width: 89%;justify-content: space-between">
              <el-input v-model="postformLabelAlign.jobMinimumSalary" type="number" oninput="if(value){value=value.replace(/[^\d]/g,'')} if(value<=0){value=''} if(value>20){value=20}"></el-input><span style="margin-left: 10px">K</span>
              <span style="display: inline-block;margin: 0 14px">-</span>
              <el-input v-model="postformLabelAlign.jobMaximumSalary" type="number" oninput="if(value){value=value.replace(/[^\d]/g,'')} if(value<=0){value=''} if(value>20){value=20}"></el-input><span style="margin-left: 10px">K</span>
            </div>
          </el-form-item>
          <el-form-item label="职位描述">
            <el-input v-model="postformLabelAlign.jobMessageDetailed" type="textarea"
                      :rows="6"
                      placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-form-item label="职位类型">
            <el-select v-model="postformLabelAlign.jobType" placeholder="请选择">
              <el-option
                  v-for="item in postgenreArr"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div style="display: flex;justify-content: flex-end;padding-bottom: 60px">
          <el-button type="primary" @click="clearWindow">取消</el-button>
          <el-button type="primary" @click="submitPost()">保存</el-button>
        </div>
      </div>
    </div>
    <!--    所有城市-->
    <el-dialog :visible.sync="cityWindowShow" :show-close="true" width="1000px">
      <div>
        <p class="cityBorder">直辖市、省及自治区</p>
        <div style="display: flex;align-items: baseline;" >
          <div class="citySheng" >
            <div v-for="(item,index) in cityList" :key="index">
              <p class="cityShengp" @click="changeCity(item,index)" :class="cityShow==index?'cityActive':''">{{item.name}}</p>
            </div>
          </div>
          <div class="cityShi" >
            <p class="cityShip" :class="cityquShow==index?'cityquShowActive':''" v-for="(item,index) in jobCityArr" :key="index" @click="changeDistrict(item,index)">{{item.name}}</p>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {getCityList} from "@/utils/city";
import {
  publishCompanypost,
  selectCompanyJobByCompanyId,
  updateCompanyJobById,
  deleteCompanyJobById,
} from "@/api"
export default {
  components:{
  },
  data(){
    return{
      searchName:'',
      currentPage:1,
      pagesize:20,
      total:0,
      jobCityArr:[],
      cityList:[],
      cityShow:0,
      cityquShow:0,
      cityWindowShow:false,
      addPostSHow:false,
      labelPosition: 'right',
      experience:[
        {id:0,label:'经验不限'},
        {id:1,label:'1年以内'},
        {id:2,label:'1-3年'},
        {id:3,label:'3-5年'},
        {id:4,label:'10年以上'},
      ],
      education:[
        {id:0,name:'学历不限'},
        {id:1,name:'初中及以下'},
        {id:2,name:'中专/中技'},
        {id:3,name:'高中'},
        {id:4,name:'大专'},
        {id:5,name:'本科'},
        {id:6,name:'硕士'},
        {id:7,name:'博士'},
      ],
      postgenreArr:[
        {id:1,name:'全职'},
        {id:2,name:'兼职'}
      ],
      postformLabelAlign: {
        companyTitle: '',
        jobPublishType: '',
        jobTitle: '',
        jobCity: '',
        jobArea: '',
        jobExperience: '',
        jobSchool: '',
        jobMinimumSalary: '',
        jobMaximumSalary: '',
        jobMessageDetailed: '',
        jobType: '',
      },
      postTypeArr:[
        {value:'1',label:'社招'},
        {value:'2',label:'校招'},
        {value:'3',label:'实习'},
      ],
      postAskform:{
        name: '',
        region: '',
        type: ''
      },
      userInfo:{},
      postList:[],
      redactPostshow:false
    }
  },
  methods:{
    //删除职位
    deleteCompanypost(item){
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data={
          id:item.id
        }
        deleteCompanyJobById(data).then(res=>{
          if(res.code==0){
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.getCompanyByJobUserId()
          }
        })
      }).catch(() => {

      });
    },
    // 分页
    handleCurrentChange: function (currentPage) {
      this.currentPage = currentPage;
      this.getCompanyByJobUserId();
    },
    //获取职位列表
    getCompanyByJobUserId(item){
      let data={
        companyId:item&&item.companyId?item.companyId:item.bindJobUserId,
        pageNumber:this.currentPage,
        pageSize:this.pagesize
      }
      if(this.searchName){
        data.jobTitle=this.searchName
      }
      selectCompanyJobByCompanyId(data).then(res=>{
        this.postList=res.data.records;
        this.total=JSON.parse(res.data.total);
      })
    },
    //添加职位
    addPOst(){
      if(!this.postformLabelAlign.companyTitle){
        this.$message('公司名称不可为空');return
      }
      if(!this.postformLabelAlign.jobTitle){
        this.$message('职位名称不可为空');return
      }
      let data={
        companyId:this.userInfo.companyId?this.userInfo.companyId:this.userInfo.bindJobUserId,
        companyTitle:this.postformLabelAlign.companyTitle,
        jobPublishPersonId:this.userInfo.id,
        jobPublishType:this.postformLabelAlign.jobPublishType,
        jobTitle:this.postformLabelAlign.jobTitle,
        jobCity:this.postformLabelAlign.jobCity,
        jobArea:this.postformLabelAlign.jobArea,
        jobExperience:this.postformLabelAlign.jobExperience,
        jobSchool:this.postformLabelAlign.jobSchool,
        jobMinimumSalary:this.postformLabelAlign.jobMinimumSalary,
        jobMaximumSalary:this.postformLabelAlign.jobMaximumSalary,
        jobMessageDetailed:this.postformLabelAlign.jobMessageDetailed,
        jobType:this.postformLabelAlign.jobType,
      }
      publishCompanypost(data).then(res=>{
        if(res.code==0){
          this.$message({
            message: "发布成功",
            type: "success",
          });
          this.addPostSHow=false
          this.getCompanyByJobUserId()
        }else{
          this.$message.error(res.msg)
        }
      })
    },
    //保存
    submitPost(){
      if(this.redactPostshow){
        this.amendPOst()
      }else{
        this.addPOst()
      }

    },
    //点击城市,显示区
    changeDistrict(item,index){
      this.postformLabelAlign.jobCity=item.name
      this.cityWindowShow=false;
      this.cityquShow=index
    },
    //点击省份,显示城市
    changeCity(item,index){
      this.jobCityArr=item.city;
      this.cityShow=index;
      this.cityquShow=0
    },
    //修改职位
    amendPOst(){
      if(!this.postformLabelAlign.companyTitle){
        this.$message('公司名称不可为空');return
      }
      if(!this.postformLabelAlign.jobTitle){
        this.$message('职位名称不可为空');return
      }
      let data={
        id:this.postformLabelAlign.id,
        // companyId:this.userInfo.bindJobUserId,
        // companyTitle:this.postformLabelAlign.companyTitle,
        // jobPublishPersonId:this.userInfo.id,
        jobPublishType:this.postformLabelAlign.jobPublishType,
        jobTitle:this.postformLabelAlign.jobTitle,
        jobCity:this.postformLabelAlign.jobCity,
        jobArea:this.postformLabelAlign.jobArea,
        jobExperience:this.postformLabelAlign.jobExperience,
        jobSchool:this.postformLabelAlign.jobSchool,
        jobMinimumSalary:this.postformLabelAlign.jobMinimumSalary,
        jobMaximumSalary:this.postformLabelAlign.jobMaximumSalary,
        jobMessageDetailed:this.postformLabelAlign.jobMessageDetailed,
        jobType:this.postformLabelAlign.jobType,
      }
      updateCompanyJobById(data).then(res=>{
        if(res.code==0){
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.addPostSHow=false
          this.getCompanyByJobUserId()
        }
      })
    },
    //编辑职位
    redactPost(item){
      this.addPostSHow=true
      this.postformLabelAlign=item
      this.redactPostshow=true

    },
  //  显示发布职位
    goOpenPost(){
      this.addPostSHow=true
      this.postformLabelAlign={}
    },
    //显示全部城市
    allCity(){
      this.cityWindowShow=true;
    },
  //  取消
    clearWindow(){
      this.addPostSHow=false
    }
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))
    let cityArr=getCityList();
    this.cityList=cityArr;
    this.cityList=cityArr;
    this.jobCityArr=cityArr[0].city;
    this.getCompanyByJobUserId(userInfo)
  }
}
</script>
<style scoped>
@import "../../../../public/css/enterpriseIndex.css";
</style>
