<template>
  <div>
    <div v-if="compileUndergo">
      <div class="superiority">
        <div class="inviteInfoResumeFlex" style="justify-content: space-between;width: 100%">
          <div class="superiorityDiv">
            <span></span>
            <span>工作经历</span>
          </div>
          <div class="compile" @click="addExperience">
            <i class="el-icon-circle-plus-outline"></i>
            <span>添加</span>
          </div>
        </div>
      </div>
      <div @mousemove="enterWork(i)" @mouseout="leaveWork" v-for="(item,i) in userExperiences" :key="i" class="inviteMb">
        <div class="expectedFlex">
          <div>{{item.companyName}}</div>
          <div class="expectedFlex" >
            <span style="font-size: 14px;" v-show="item.isShow==false">{{item.hiredate}}-{{item.dimission}}</span>
            <div v-show="item.isShow==true">
              <div style="display: flex">
                <p class="expectedFlex compile expectedMr" @click="compileExperience(item)">
                  <i class="el-icon-edit"></i>
                  <span>编辑</span>
                </p>
                <p class="expectedFlex compile expectedMr" @click="deletecompileExperience(item)">
                  <i class="el-icon-delete"></i>
                  <span>删除</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="expectedFlex expectedDepartment" >
          <span>{{item.department}}</span>
          <span></span>
          <span>{{item.position}}</span>
        </div>
        <div>内容：{{item.workContent}}</div>
      </div>
    </div>
    <div v-if="compileUndergo==false">
      <p style="font-size: 18px;margin: 30px 0">添加工作经历</p>
      <div>
        <el-form :inline="true" :model="experience" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="公司名称" prop="name">
            <el-input v-model="experience.companyName"></el-input>
          </el-form-item>
          <el-form-item label="所属行业" prop="name1" >
            <el-input v-model="experience.industryInvolved" @focus="changeIndustry"></el-input>
          </el-form-item>
          <el-form-item label="所属部门" prop="name2">
            <el-input v-model="experience.department"></el-input>
          </el-form-item>
          <el-form-item label="职位名称" prop="name3"  >
            <el-input v-model="experience.position" @focus="changePost"></el-input>
          </el-form-item>
          <el-form-item label="职位类型" prop="name4">
            <el-input v-model="experience.positionType"></el-input>
          </el-form-item>
          <el-form-item label="在职时间" prop="date1" >
            <el-date-picker
                value-format="yyyy-MM"
                v-model="experience.experienceTime"
                type="monthrange"
                range-separator="至"
                start-placeholder="开始月份"
                end-placeholder="结束月份">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="工作内容" prop="name5" style="width: 89%">
            <el-input
                type="textarea"
                :rows="6"
                placeholder="请输入内容"
                v-model="experience.workContent"
                style="width: 522px">
            </el-input>
          </el-form-item>
          <el-form-item style="display: flex;justify-content: flex-end;">
            <el-button type="primary" @click="clearcompile">取消</el-button>
            <el-button @click="publishExprience">完成</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!--    期望职位弹窗-->
    <el-dialog :visible.sync="postWindows" :show-close="true" >
      <div style="min-height: 300px">
        <el-tabs :tab-position="tabPosition" >
          <el-tab-pane v-for="(item,index) in postListArr" :key="index" :label="item.name">
            <div class="postListArrFelx">
              <p class="postListArrFelxp" :class="postShow==index1?'postActive':''" v-for="(item1,index1) in item.children" :key="index1" @click="choosePost(item1,index1)">{{item1.name}}</p>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-dialog>
    <!--    期望行业弹窗-->
    <el-dialog :visible.sync="industryWindows" :show-close="true">
      <div>
        <el-tabs :tab-position="tabPosition" >
          <el-tab-pane v-for="(item,index) in industryLists" :key="index" :label="item.name" >
            <div class="postListArrFelx">
              <p class="postListArrFelxp" :class="industryShow==index1?'postActive':''" v-for="(item1,index1) in item.children" :key="index1" @click="chooseIndustry(item1,index1)">{{item1.name}}</p>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import {industryList, postList, updateUserPost} from "@/api";

export default {
  props: {
    //用户信息
    userExperiences: {
      required: true,
      default() {
        return []
      },
    },
  },
  components:{
  },
  data(){
    return{
      industryShow:'-1',
      tabPosition: 'left',
      compileUndergo:true,
      experience: {
        companyName: '',
        industryInvolved:'',
        department:'',
        position:'',
        positionType:'',
        experienceTime:[],
        workContent:''
      },
      userInfo:{},
      compileShow:false,
      compileId:'',
      postWindows:false,
      postListArr:[],
      postShow:'-1',
      industryWindows:false,
      industryLists:[],
    }
  },
  methods:{
    //  获取行业领域
    getindustryList(){
      industryList().then(res=>{
        this.industryLists=res.data;
      })
    },
    //选择职位
    choosePost(item,index){
      this.postWindows=false;
      this.experience.position=item.name;
      this.postShow=index
    },
    //选择行业
    chooseIndustry(item,index){
      this.industryWindows=false;
      this.experience.industryInvolved=item.name;
      this.industryShow=index
    },
    //获取职位列表
    getPOstList(){
      postList().then(res=>{
        this.postListArr=res.data
      })
    },
    //删除
    deletecompileExperience(item){
      this.$emit('experienceId',item.id)
      this.$emit('delectEducationInfo')
    },
    //编辑经历
    compileExperience(item){
      this.compileShow=true
      this.compileId=item.id
      this.getPOstList();
      this.getindustryList();
      this.compileUndergo=false
      this.experience.companyName=item.companyName
      this.experience.industryInvolved=item.industryInvolved
      this.experience.positionType=item.positionType
      this.experience.department=item.department
      this.experience.position=item.position
      this.experience.workContent=item.workContent
      this.experience.experienceTime[0]=item.hiredate;
      this.experience.experienceTime[1]=item.dimission
    },
    enterWork(index){
      this.userExperiences.forEach((item,index2)=>{
        if(index==index2){
          item.isShow=true
        }else{
          item.isShow=false
        }
      })

    },
    leaveWork(){
      this.userExperiences.forEach(item=>{
        item.isShow=false
      })
    },
  //  添加
    addExperience(){
      this.compileUndergo=false
      this.getPOstList();
      this.getindustryList();
      this.experience={}
    },
  //  取消
    clearcompile(){
      this.compileUndergo=true;
    },
  //  完成publishExprience
    publishExprience(){

      let data={
        experience:{
          uId:this.userInfo.id,
          companyName:this.experience.companyName,
          industryInvolved:this.experience.industryInvolved,
          positionType:this.experience.positionType,
          department:this.experience.department,
          position:this.experience.position,
          hiredate:'',
          dimission:'',
          workContent:this.experience.workContent,
        }
      }
      if(this.experience.experienceTime){
        data.experience.hiredate=this.experience.experienceTime[0];
        data.experience.dimission=this.experience.experienceTime[1]
      }
      if(this.compileShow){
        data.experience.id=this.compileId
      }
      updateUserPost(data).then(res=>{
        if(res.code==0){
          this.compileUndergo=true;
          this.$emit('getfindUserByuId')
          if(this.compileShow){
            this.$message({
              message: "修改成功",
              type: "success",
            });
          }else{
            this.$message({
              message: "添加成功",
              type: "success",
            });
          }
        }
      })
    },
    //期望职位
    changePost(){
      this.postWindows=true
    },
    //  期望行业
    changeIndustry(){
      this.industryWindows=true
    }

  },
  mounted() {
    this.userInfo=JSON.parse(localStorage.getItem("userInfo")) ;
  },
  watch:{
    userExperiences(val,oldVal){
      if(val){
        val.forEach((item,index)=>{
          this.$set(val[index],  "isShow",false);
        })
        this.userExperiences=val
      }
    }
  }
}
</script>
<style scoped>
@import "../../../../public/css/inviteInfo.css";
</style>
