<template>
  <div>
    <div class="educationAsk">
      <div class="tjfx" >
        <p >学历要求</p>
        <div class="numberRadius">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div style="width: 100%;height:368px;top: -18px" id="education"></div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import {getEducation} from "@/api";
export default{
  props:{
    cityValue:''
  },
  data(){
    return{
      educationList:[],
      educationName:[],
      province:''
    }
  },

  methods:{
    getEductionList(){
      let data={
        province:this.province
      }
      getEducation(data).then(res=>{
        if(res.code==0){
          for (var item of res.data){
            let obj={
              value:item.number,
              name:item.name
            }
            this.educationList.push(obj)
            this.educationName.push(item.name)
          }
          this.getEducation()
        }else{
          this.$message.error(res.msg)
        }
      })
    },
    getEducation(){
      var ROOT_PATH =
          'https://cdn.jsdelivr.net/gh/apache/echarts-website@asf-site/examples';

      var chartDom = document.getElementById('education');
      var myChart = echarts.init(chartDom);
      var option;

      const weatherIcons = {
        Sunny: ROOT_PATH + '/data/asset/img/weather/sunny_128.png',
        Cloudy: ROOT_PATH + '/data/asset/img/weather/cloudy_128.png',
        Showers: ROOT_PATH + '/data/asset/img/weather/showers_128.png'
      };
      option = {

        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          bottom: 10,
          left: 'center',
          "textStyle": {
            color: '#fff'
          },
          data: this.educationName,
        },

        series: [
          {
            name: '学历要求',
            type: 'pie',
            radius: '50%',
            center: ['50%', '50%'],
            selectedMode: 'single',
            data:this.educationList,

            label: {
              normal: {
                textStyle: {
                  color: '#fff'  // 改变标示文字的颜色
                }
              }
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: '#fff'
              }
            }
          }
        ]
      };

      option && myChart.setOption(option);
    }
  },
  watch:{
    cityValue(val,oldVal){
      if(val){
        this.educationList=[];
        if(val!=='全国'){
          this.province=val;
          this.getEductionList();
        }else{
          this.province='';
          this.getEductionList();
        }
      }
    }
  },
  mounted() {
    this.getEductionList();
  }
}
</script>
<style scoped>
@import "../../../public/css/bigNumber.css";
</style>
