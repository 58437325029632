<template>
  <div>
    <p style="font-size: 18px">公司介绍</p>
    <p style="font-size: 12px;color: #999999;margin: 10px 0 30px 0">可以简单介绍一下公司发展状况、服务领域、主要产品等信息</p>
    <div style="display: flex;align-items: center;margin-bottom: 20px">
      <p style="width: 200px">一句话介绍（非必填）</p>
      <el-input v-model="introduce"></el-input>
    </div>
    <el-input
        v-model="details"
        type="textarea"
        :rows="15"
        placeholder="请输入内容"></el-input>
    <div style="display: flex;justify-content: flex-end;margin-top: 30px">
      <el-button @click="submitIntroduce">保存</el-button>
    </div>
  </div>
</template>
<script>
import {updateCompany} from "@/api";

export default {
  props:{
    companyInfo:{}
  },
  components: {
  },
  data(){
    return{
      introduce:'',
      details:''
    }
  },
  methods:{
  //保存
    submitIntroduce(){
      if(!this.details){
        this.$message('内容不可为空');return
      }
      let data={
        id:this.userInfo.companyId,
        introduce:this.introduce,
        details:this.details
      }
      updateCompany(data).then(res=>{
        if(res.code=="0"){
          this.$message({
            message: '操作成功',
            type: 'success'
          });
        }else{
          this.$message.error('失败，请重试');
        }
      })
    }
  },
  mounted(){
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))

  },
  watch:{
    companyInfo(val,oldVal){
      this.companyInfo=val
      this.introduce=val.introduce
      this.details=val.details
    }
  }
}
</script>
<style scoped>
@import "../../../../public/css/enterpriseIndex.css";
</style>



