<template>
  <div>
    <el-upload
        action="/frontApi/baseFile/upload"
        list-type="picture-card"
        :headers="headers"
        :file-list='fileList'
        :data="{'bizType':bizType,'aliVideoOssData':aliVideoOssData}"
        :show-file-list="model == 2 ? true: false"
        :before-remove="handleAvatarRemove"
        :on-success="handleAvatarSuccess">
      <el-image v-if="imageUrl && model == 1 " :src="imageUrl" class="avatar"/>
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
  </div>
</template>
<!--
  调用示例   上传文件类型(bizType), 默认 头像 (详细看接口文档)
  <custom-upload v-model="test" bizType='setUp_UserHeadPortrait'></custom-upload>
-->
<script>
import Cookie from "js-cookie";
export default {
  name: "custom-upload",
  props: {
    // 上传文件类型, 默认 头像 (详细看接口文档)
    bizType: {default: '',type: String},
    imageUrl: {default: '', type: [String, Array]},
    model: {default: '1', type: String},
    aliVideoOssData:{default: '0',type: String},
  },
  model:{
    prop: 'imageUrl',
    event: 'changeImageUrl',
  },
  data() {
    return {
      changeData: true,
      fileList:[],
      headers: {},
    };
  },
  watch:{
    imageUrl(item){
      if(this.changeData)
        this.setImage();
      this.changeData = true;

    }
  },
  created() {
    let userToken = Cookie.get('userToken')
    if (userToken) this.headers['xzmallWebToken'] = userToken;
    this.setImage();
  },
  methods: {
    setImage(){
      if( typeof this.imageUrl === "string"){
        let imgArr = this.imageUrl.split(',')
        this.fileList =
            imgArr.map((item)=>{
              let tmp = item.split('.')
              return {name:tmp[1],url:item}
            }).filter((item)=>item.url)
      }else{
        this.fileList = this.imageUrl
      }
    },
    handleAvatarSuccess(response, file, fileList) {
      this.changeData = false;
      if(this.model === '1'){
        this.$emit('changeImageUrl', response.data.url);
      }else{
        fileList = this.getFileUrl(fileList);
        this.$emit('changeImageUrl', fileList.join(','));
      }
      // sessionStorage.setItem("changeImageUrl", response.data[0].downPath);
    },
    handleAvatarRemove(file, fileList){
      this.changeData = false;
      if(this.model !== "1"){
        fileList = this.getFileUrl(fileList, file);
      }
    },
    getFileUrl(fileList, cutFile={}){
      try {
        cutFile = cutFile.response? cutFile.response.data.url: cutFile.url;
        return  fileList.map((item,index)=>{
          if(!item.response){
            if(cutFile !== item.url)
              return item.url;
          }else{
            if(cutFile !== item.response.data.url){
              return item.response.data.url
            }
          }
          return false;
        }).filter((item)=>item)
      }catch (e){
      }
    }
  }
}
</script>

<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  line-height: 148px;
  text-align: center;
}

.avatar {
  width: 148px;
  height: 148px;
  display: block;
}

</style>
