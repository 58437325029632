<template>
  <div>
    <div style="padding-bottom: 60px">
      <div class="postFlex" style="justify-content: space-between">
        <div class="postFlex">
          <img class="findUserAvatar" :src="postDetail.headPortraitUrl">
          <div >
            <p class="findUserImg">
              <span>{{postDetail.name}}</span>
              <img v-if="postDetail.gender=='male'" src="images/findIcon2.png">
              <img v-if="postDetail.gender=='unknown'" src="images/findIcon1.png">
              <span>刚刚活跃</span>
            </p>
            <p style="font-size: 14px;color: #999999;margin-top: 6px" v-html="postDetail.personalAdvantage"></p>
          </div>
        </div>
        <div class="postFlex findTalentImg">
          <p>
            <img src="images/findIcon3.png">
            <span>{{ postDetail.ageYear }}岁</span>
          </p>
          <p>
            <img src="images/findIcon4.png">
            <span>{{postDetail.workYear}}年</span>
          </p>
          <p>
            <img src="images/findIcon5.png">
            <span>{{postDetail.jobSchool}}</span>
          </p>
          <p>
            <img src="images/findIcon6.png">
            <span v-if="postDetail.asState==0">在职-暂不考虑</span><span v-if="postDetail.asState==1">在职-考虑机会</span><span v-if="postDetail.asState==2">在职-月内到岗</span><span v-if="postDetail.asState==3">离职-随时到岗</span>
          </p>
        </div>
      </div>
      <div class="postFlex fontMrgin">
        <p class="expectationPost">期望职位</p>
        <p>
          <span class="postFindMr">{{postDetail.map.userPosts[0].positionDesired}}</span>
          <span class="postFindMr" >{{postDetail.map.userPosts[0].desiredIndustry}}</span>
          <span>{{postDetail.expectMoney[0]}}~{{postDetail.expectMoney[1]}}K</span>
        </p>
      </div>
      <div style="display: flex" class="fontMrgin" >
        <p style="width: 120px;font-weight: bold">工作经历</p>
        <div style="width: 89%" >
          <div v-for="(item1,index1) in postDetail.map.userExperiences" :key="index1" style="margin-bottom: 12px">
            <div class="findFlex">
              <p class="">
                <span class="postFindMr">{{item1.companyName}}</span>
                <span>{{item1.position}}</span>
              </p>
              <span>{{item1.hiredate}}--{{ item1.dimission }}</span>
            </div>
            <p style="margin-top: 6px">内容:{{item1.workContent}}</p>
          </div>
        </div>
      </div>
      <div style="display: flex" class="fontMrgin">
        <p style="width: 120px;font-weight: bold">教育经历</p>
        <div style="width: 89%">
          <div v-for="(item1,index1) in postDetail.map.userSchools" :key="index1" class="findFlex" style="margin-bottom: 12px">
            <p>
              <span class="postFindMr">{{item1.schoolName}}</span>
              <span class="postFindMr">{{item1.majorName}}</span>
              <span>{{item1.education}}</span>
            </p>
            <p>{{item1.schoolStartTime}}--{{item1.schoolStopTime}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props:{
    postDetail:{}
  },
  data(){
    return{

    }
  },
  methods:{

  },
  mounted() {

  }
}
</script>
<style scoped>
@import "../../../../public/css/enterpriseIndex.css";
</style>
