<template>
  <div>
    <div v-if="projectShow">
      <div class="superiority">
        <div class="inviteInfoResumeFlex" style="justify-content: space-between;width: 100%">
          <div class="superiorityDiv">
            <span></span>
            <span>项目经验</span>
          </div>
          <div class="compile" @click="addPRoject()">
            <i class="el-icon-circle-plus-outline"></i>
            <span @click="addWorkProject">添加</span>
          </div>
        </div>
      </div>
      <div v-for="(item,index) in userProjects" :key="index" @mousemove="enterExperience(index)" @mouseout="leaveExperience" class="experienceMb">
        <div class="expectedFlex">
          <div>{{item.projectName}} </div>
          <div class="expectedFlex">
            <span style="font-size: 14px" v-show="item.isShow==false">{{item.startTime}}-{{item.stopTime}}</span>
            <div v-show="item.isShow==true" >
              <div style="display: flex">
                <p class="expectedFlex compile expectedMr" @click="editProject(item)">
                  <i class="el-icon-edit"></i>
                  <span>编辑</span>
                </p>
                <p class="expectedFlex compile expectedMr" @click="deleteProject(item)">
                  <i class="el-icon-delete"></i>
                  <span>删除</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="expectedFlex expectedDepartment" >
          <span>{{item.projectRoles}}</span>
        </div>
        <div>内容：{{item.description}}</div>
      </div>
    </div>
    <div v-if="projectShow==false">
      <p style="font-size: 18px;margin: 30px 0">添加项目经验</p>
      <div>
        <el-form :inline="true" :model="projecrEx"  ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="项目名称" >
            <el-input v-model="projecrEx.projectName"></el-input>
          </el-form-item>
          <el-form-item label="项目角色" >
            <el-input v-model="projecrEx.projectRoles"></el-input>
          </el-form-item>
          <el-form-item label="项目时间" style="width: 60%">
            <el-date-picker
                value-format="yyyy-MM"
                v-model="projecrEx.projectTime"
                type="monthrange"
                range-separator="至"
                start-placeholder="开始月份"
                end-placeholder="结束月份">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="项目描述" style="width: 89%">
            <el-input
                type="textarea"
                :rows="6"
                placeholder="请输入内容"
                v-model="projecrEx.description"
                style="width: 522px">
            </el-input>
          </el-form-item>
          <el-form-item style="display: flex;justify-content: flex-end;">
            <el-button type="primary" @click="clearProject">取消</el-button>
            <el-button @click="publishProject">完成</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div >
  </div>
</template>
<script>
import {updateUserPost} from "@/api";

export default {
  props: {
    //
    userProjects: {
      required: true,
      default() {
        return []
      },
    },
  },
  components:{
  },
  data(){
    return{
      userInfo:{},
      compileShow:false,
      projectShow:true,
      prohectTextarea:'',
      projecrEx: {
        projectName: '',
        projectRoles: '',
        description: '',
        projectTime:[],
      },
      projectId:''
    }
  },
  methods:{
    addWorkProject(){

    },
    //鼠标进入
    enterExperience(index){
      this.userProjects.forEach((item,index2)=>{
        if(index==index2){
          item.isShow=true;
        }else{
          item.isShow=false
        }
      })
    },
    //鼠标移除
    leaveExperience(){
      this.userProjects.forEach(item=>{
        item.isShow=false
      })
    },
    //完成
    publishProject(){
      this.projectShow=true
      this.getexPriseList()
    },
    //取消
    clearProject(){
      this.projectShow=true
    },
  //  添加
    addPRoject(){
      this.projectShow=false;
      this.projecrEx={}
    },
    //工作经验列表
    getexPriseList(){
      let data={
        project:{
          uId:this.userInfo.id,
          projectName:this.projecrEx.projectName,
          projectRoles:this.projecrEx.projectRoles,
          startTime:this.projecrEx.projectTime[0],
          stopTime:this.projecrEx.projectTime[1],
          description:this.projecrEx.description,
        }
      }
      if(this.compileShow){
        data.project.id=this.projectId
      }
      updateUserPost(data).then(res=>{
        if(res.code==0){
          this.compileUndergo=true;
          this.$emit('getfindUserByuId')
          if(this.compileShow){
            this.$message({
              message: "修改成功",
              type: "success",
            });
          }else{
            this.$message({
              message: "添加成功",
              type: "success",
            });
          }
        }
      })
    },
  //  删除
    deleteProject(item){
      this.$emit('projectId',item.id)
      this.$emit('delectEducationInfo')
    },
  //  编辑
    editProject(item){
      this.compileShow=true
      this.projectShow=false
      this.projectId=item.id
      this.projecrEx.projectName=item.projectName
      this.projecrEx.projectRoles=item.projectRoles
      this.projecrEx.description=item.description
      this.projecrEx.projectTime[0]=item.startTime
      this.projecrEx.projectTime[1]=item.stopTime
    }
  },
  mounted() {
    this.userInfo=JSON.parse(localStorage.getItem("userInfo")) ;
  },
  watch:{
    userProjects(val,oldVal){
      if(val){
        val.forEach((item,index)=>{
          // item.salaryMoneyArr=item.salaryMoney.split(',');
          this.$set(val[index],  "isShow",false);
        })
        this.userProjects=val
      }
    }
  }
}
</script>
<style scoped>
@import "../../../../public/css/inviteInfo.css";
</style>
