<template>
  <div id="province" style=""></div>
</template>
<script>
import * as echarts from "echarts";
import {selectBigDataAll} from "@/api";
export default{
  data(){
    return{
      manNumberData:[],
      proportionData:[]

    }
  },
  methods:{
    //地图
    myMapDataList(){
      let data={
        province:''
      }
      selectBigDataAll(data).then(res=>{
        for (var item of res.data){
          let obj={
            name:item.jobCity,
            value:item.number
          }
          let obj1={
            name:item.jobCity,
            value:item.percentage
          }
          this.manNumberData.push(obj)
          this.proportionData.push(obj1)
        }
        this.myMapData();
      })
    },
    myMapData(){
      var myChart = echarts.init(document.getElementById('province'));
      var option = {
        title : {
          // text: 'HULU全国带宽',
          // subtext: '数据来自HBase',
          x:'center'
        },
        tooltip : {
          trigger: 'item',
          formatter:function(params){
            //定义一个res变量来保存最终返回的字符结果,并且先把地区名称放到里面
            var res=params.name+'<br />';
            //定义一个变量来保存series数据系列
            var myseries=option.series;
            //循环遍历series数据系列
            for(var i=0;i<myseries.length;i++){
              //在内部继续循环series[i],从data中判断：当地区名称等于params.name的时候就将当前数据和名称添加到res中供显示
              for(var k=0;k<myseries[i].data.length;k++){
                //console.log(myseries[i].data[k].name);
                //如果data数据中的name和地区名称一样
                if(myseries[i].data[k].name==params.name){
                  //将series数据系列每一项中的name和数据系列中当前地区的数据添加到res中
                  res+=myseries[i].name+':'+myseries[i].data[k].value+'<br />';
                }
              }
            }
            return res;
          }
        },
        // legend: {
        //   orient: 'vertical',
        //   x:'left',
        //   data:['TV端下载','PC端下载','移动端下载']
        // },
        // dataRange: {
        //   x: 'left',
        //   y: 'bottom',
        //   splitList: [
        //     {start: 1500},
        //     {start: 900, end: 1500},
        //     {start: 310, end: 1000},
        //     {start: 200, end: 300},
        //     {start: 10, end: 200, label: '10 到 200（自定义label）'},
        //     {start: 5, end: 5, label: '5（自定义特殊颜色）', color: 'black'},
        //     {end: 10}
        //   ],
        //   color: ['#E0022B', '#E09107', '#A3E00B']
        // },
        // toolbox: {
        //   show: true,
        //   orient : 'vertical',
        //   x: 'right',
        //   y: 'center',
        //   feature : {
        //     mark : {show: true},
        //     dataView : {show: true, readOnly: false},
        //     restore : {show: true},
        //     saveAsImage : {show: true}
        //   }
        // },
        roamController: {
          show: true,
          x: 'right',
          mapTypeControl: {
            'china': true
          }
        },
        series : [
          {
            name: '人数',
            type: 'map',
            mapType: 'china',
            // 控制地图大小
            mapLocation: {
              x: 'center',
              y: 'center',
              height: '90%'
            },
            roam: false,
            itemStyle:{
              normal:{
                areaColor: 'rgba(128, 128, 128, 0)', //rgba设置透明度0
                borderColor: '#63BEFD',//省份边框颜色
                // borderWidth:1.5,//省份边框
                label:{
                  show:true,
                  textStyle: {
                    color: "rgb(100, 249, 249)"
                  },
                }
              },
              emphasis:{label:{show:true}}
            },
            data:this.manNumberData
          },
          {
            name: '占比',
            type: 'map',
            mapType: 'china',
            // 控制地图大小
            mapLocation: {
              x: '240',
              y: 'center',
              height: '75%'
            },
            roam: false,
            itemStyle:{
              normal:{
                label:{
                  show:true,
                  textStyle: {
                    color: "rgb(249, 249, 249)"
                  },

                }
              },
              emphasis:{label:{show:true}}
            },
            data:this.proportionData
          },
        ]
      };
      myChart.setOption(option);
    }

  },
  mounted() {
    this.myMapDataList();
  }
}
</script>
<style scoped>
@import "../../../public/css/bigNumber.css";
</style>
