<template>
  <div>
    <div v-if="educitonShow">
      <div class="superiority">
        <div class="inviteInfoResumeFlex" style="justify-content: space-between;width: 100%">
          <div class="superiorityDiv">
            <span></span>
            <span>教育经历</span>
          </div>
          <div class="compile" @click="addEducation">
            <i class="el-icon-circle-plus-outline"></i>
            <span>添加</span>
          </div>
        </div>
      </div>
      <div v-for="(item,index) in userSchools" :key="index" @mousemove="enterExperience(index)" @mouseout="leaveExperience">
        <div class="expectedFlex">
          <div>
            <p>{{item.schoolName}}</p>
            <p class="educationExFont" >{{item.education}}.<span v-if="item.fullTime=='1'">全日制</span><span v-if="item.fullTime=='0'">非全日制</span>/{{item.majorName}}</p>
          </div>
          <div class="expectedFlex">
            <span style="font-size: 14px" v-show="item.isShow==false">{{item.schoolStartTime}}-{{item.schoolStopTime}}</span>
            <div v-show="item.isShow==true" >
              <div style="display: flex">
                <p class="expectedFlex compile expectedMr" @click="editEducation(item)">
                  <i class="el-icon-edit"></i>
                  <span>编辑</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="educationExFont">{{item.experienceSchool}}</div>

      </div>
    </div>
    <div v-if="educitonShow==false">
      <p style="font-size: 18px;margin: 30px 0">编辑教育经历</p>
      <div>
        <el-form :inline="true" :model="educationUndergo"  ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="学校名称" >
            <el-input v-model="educationUndergo.schoolName"></el-input>
          </el-form-item>
          <el-form-item label="学制类型">
            <div class="educationWdinput">
              <el-select v-model="educationUndergo.fullTime" placeholder="请选择">
                <el-option
                    v-for="item in systemArr"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="学历">
            <div class="educationWdinput">
              <el-select v-model="educationUndergo.education" placeholder="请选择">
                <el-option
                    v-for="item in fabricateArr"
                    :key="item.value"
                    :label="item.label"
                    :value="item.label">
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="专业" >
            <el-input v-model="educationUndergo.majorName"></el-input>
          </el-form-item>
          <el-form-item label="时间段"  >
            <el-date-picker
                value-format="yyyy-MM"
                v-model="educationTime"
                type="monthrange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="在校经历"  style="width: 89%">
            <el-input
                type="textarea"
                :rows="6"
                placeholder="请输入内容"
                v-model="educationUndergo.experienceSchool"
                style="width: 522px">
            </el-input>
          </el-form-item>
          <el-form-item style="display: flex;justify-content: flex-end;">
            <el-button type="primary" @click="cleareducation">取消</el-button>
            <el-button @click="educationExprience">完成</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import {updateUserPost} from "@/api";

export default {
  props:{
    userSchools: {
      required: true,
      default() {
        return []
      },
    },
  },
  components:{
  },
  data(){
    return{
      userInfo:{},
      compileShow:false,
      educationTime:[],
      educationUndergo: {
        schoolName: '',
        education:'',
        majorName:'',
        experienceSchool:'',
        fullTime:''
      },
      systemArr: [{
        value: '1',
        label: '全日制'
      }, {
        value: '0',
        label: '非全日制'
      },],
      fabricateType:'',
      fabricateArr:[
        {
          value: '选项1',
          label: '初中及以下'
        }, {
          value: '选项2',
          label: '中专'
        }, {
          value: '选项3',
          label: '高中'
        }, {
          value: '选项4',
          label: '大专'
        }, {
          value: '选项5',
          label: '本科'
        }, {
          value: '选项6',
          label: '硕士'
        }, {
          value: '选项7',
          label: '博士'
        }
      ],
      educitonShow:true,
      educationId:''
    }
  },
  methods:{
    //取消
    cleareducation(){
      this.educitonShow=true
    },
    //鼠标进入
    enterExperience(index){
      this.userSchools.forEach((item,index2)=>{
        if(index==index2){
          item.isShow=true;
        }else{
          item.isShow=false
        }
      })
    },
    //鼠标移除
    leaveExperience(){
      this.userSchools.forEach(item=>{
        item.isShow=false
      })
    },
    //编辑
    editEducation(item){
      this.educitonShow=false
      this.compileShow=true
      this.educationId=item.id
      this.educationUndergo.schoolName=item.schoolName
      this.educationUndergo.education=item.education
      this.educationUndergo.majorName=item.majorName
      this.educationUndergo.experienceSchool=item.experienceSchool
      this.educationTime[0]=item.schoolStartTime
      this.educationTime[1]=item.schoolStopTime
      if(item.fullTime==1){
        this.educationUndergo.fullTime='全日制'
      }else{
        this.educationUndergo.fullTime='非全日制'
      }
    },
    //提交
    educationExprience(){
      if(!this.educationUndergo.schoolName){
        this.$message('学校名称不可为空');return
      }
      let data={
        school: {
          uId:this.userInfo.id,
          schoolName: this.educationUndergo.schoolName,
          education:this.educationUndergo.education,
          majorName:this.educationUndergo.majorName,
          experienceSchool:this.educationUndergo.experienceSchool,
          fullTime:this.educationUndergo.fullTime,
          schoolStartTime:this.educationTime[0],
          schoolStopTime:this.educationTime[1]
        }
      }
      if(this.compileShow){
        data.school.id=this.educationId
        if(this.educationUndergo.fullTime=='全日制'){
          data.school.fullTime='1'
        }else{
          data.school.fullTime='0'
        }
      }
      updateUserPost(data).then(res=>{
        if(res.code==0){
          this.educitonShow=true;
          this.$emit('getfindUserByuId')
          if(this.compileShow){
            this.$message({
              message: "修改成功",
              type: "success",
            });
          }else{
            this.$message({
              message: "添加成功",
              type: "success",
            });
          }
        }
      })
    },
    //添加
    addEducation(){
      this.educitonShow=false
      this.educationUndergo={}
      this.educationTime=[]
    }

  },
  mounted() {
    this.userInfo=JSON.parse(localStorage.getItem("userInfo")) ;
  },
  watch:{
    userSchools(val,oldVal){
      if(val){
        val.forEach((item,index)=>{
          this.$set(val[index],  "isShow",false);
        })
        this.userSchools=val
      }
    }
  }
}
</script>
<style scoped>
@import "../../../../public/css/inviteInfo.css";
</style>
