<template>
  <div class="cityNumberBg">
    <div>
      <img style="width: 100%" src="images/cityNumberBg.png">
    </div>
    <div>
      <div style="display: flex;align-items: center;margin: 24px 0">
        <span style="margin-right: 10px">当前位置:</span>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/xzjob' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item><p >城市大数据</p></el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="cityNumberContent">
        <div >
          <div class="cityFlexNUmber" >
            <img src="images/cityNumberIcon.png" >
            <span>省、直辖市及自治区</span>
          </div>
          <div class="provinceFlex" style="justify-content: center;">
            <p class="provinceFlexp" v-for="(item,index) in cityList" :key="index" :class="provinceShow==index?'provinceFlexActive':''" @click="changeProvince(item,index)">{{item.name}}</p>
          </div>
        </div>
<!--        -->
        <div>
          <div style="background: white">
            <div class="provinceFlex" >
              <p class="provinceFlexp" v-for="(item,index) in jobCityArr" :key="index" :class="provinceCityShow==index?'provinceFlexActive':''" @click="changeDistrict(item,index)">{{item.name}}</p>
            </div>
          </div>
<!--          -->
          <div class="cityContentFont">
            <div>
              <span style="margin-right: 20px">住房成本：{{cityDataDetail.liveHouseCost}}</span>
              <span>人均工资：{{cityDataDetail.personAverageSalary}}</span>
            </div>
            <div class="cityNumberMb">
              <p class="cityTitle">城市概况</p>
              <p>{{cityDataDetail.citySituation}}</p>
            </div>
            <div class="cityNumberMb">
              <p class="cityTitle">医疗卫生</p>
              <p>{{cityDataDetail.medicalHealth}}</p>
            </div>
            <div class="cityNumberMb">
              <p class="cityTitle">教育概况</p>
              <p>{{cityDataDetail.eduactionSituation}}</p>
            </div>
            <div class="cityNumberMb">
              <p class="cityTitle">内外交通</p>
              <p>{{cityDataDetail.withinOuterTraffic}}</p>
            </div>
            <div class="cityNumberMb">
              <p class="cityTitle">经济状况</p>
              <p>{{cityDataDetail.economicPosition}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {cityInformation} from "@/api";
import {getCityList} from "@/utils/city";
  export default {
    data(){
      return{
        provinceShow:14,
        provinceArr:[
            '山东省','河南省','山东省','河南省','山东省','河南省','山东省','河南省',
        ],
        cityList:[],
        jobCityArr:[],
        provinceCityShow:0,
        cityName:'',
        cityDataDetail:{}
      }
    },
    methods:{
    //  选择省份
      changeProvince(item,index){
        this.jobCityArr=item.city;
        this.provinceShow=index
        this.changeDistrict(item.city[0],0)
      },
    //  选择城市
      changeDistrict(item,index){
        this.cityName=item.name
        this.provinceCityShow=index
        this.getcityInformation()
      },
    //  获取数据
      getcityInformation(){
        let data={
          city:this.cityName
        }
        cityInformation(data).then(res=>{
          this.cityDataDetail=res.data
        })
      }
    },
    mounted() {
      let cityArr=getCityList();
      this.cityList=cityArr;
      this.jobCityArr=cityArr[14].city;
      this.cityName=cityArr[14].city[0].name
      this.getcityInformation()
    }
  }
</script>
<style scoped>
@import "../../../../public/css/cityNumber.css";
</style>
