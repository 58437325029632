<template>
  <div>
    <p style="font-size: 18px">公司基本信息</p>
    <p class="companyIns">丰富的公司介绍，能获得更多求职者的青睐，为你的职位带来更多查看与沟通</p>
    <el-form ref="form" :model="companyform" label-width="80px">
      <el-form-item label="公司Logo">
        <custom-upload v-model="companyform.companyLogoUrl" bizType='headPortrait' aliVideoOssData="0"></custom-upload>
      </el-form-item>
      <el-form-item label="公司简称">
        <el-input v-model="companyform.companyName"></el-input>
      </el-form-item>
      <el-form-item label="公司全称">
        <el-input v-model="companyform.companyTitle"></el-input>
      </el-form-item>
      <el-form-item label="所属行业">
        <el-input v-model="companyform.companyType" @focus="changeIndustry"></el-input>
      </el-form-item>
      <el-form-item label="人数规模">
        <el-select v-model="companyform.companyScale" placeholder="请选择活动区域">
          <el-option label="0-20人" value="0-20人"></el-option>
          <el-option label="20-99人" value="20-99人"></el-option>
          <el-option label="100-499人" value="100-499人"></el-option>
          <el-option label="499-1000人" value="499-1000人"></el-option>
          <el-option label="1000人以上" value="1000人以上"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="营业执照">
        <custom-upload v-model="companyform.companyLicenseUrl" bizType='headPortrait' aliVideoOssData="0"></custom-upload>
      </el-form-item>
      <el-form-item>
        <div class="companyBtn" style="display: flex;justify-content: flex-end" v-if="companyInfo&&!Object.keys(companyInfo).length">
          <el-button type="primary" round @click="afrrimCommit">确定</el-button>
        </div>
        <div class="companyBtn" style="display: flex;justify-content: flex-end" v-if="companyInfo&&Object.keys(companyInfo).length">
          <el-button type="primary" round @click="updateCommit">修改</el-button>
        </div>
      </el-form-item>
    </el-form>
    <el-dialog :visible.sync="industryWindows" :show-close="true">
      <div>
        <el-tabs :tab-position="tabPosition" >
          <el-tab-pane v-for="(item,index) in industryLists" :key="index" :label="item.name" >
            <div class="postListArrFelx">
              <p class="postListArrFelxp" :class="industryShow==index1?'postActive':''" v-for="(item1,index1) in item.children" :key="index1" @click="chooseIndustry(item1,index1)">{{item1.name}}</p>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import CustomUpload from "@/components/custom-upload";
import {industryList,addCompanyInfo,selectCompanyInfo,updateCompanyInfo} from "@/api";
export default {
  components: {
    CustomUpload,

  },
  data(){
    return{
      companyform: {
        companyName: '',
        companyType: '',
        companyScale:'',
        companyLogoUrl:'',
        companyTitle:'',
        companyLicenseUrl:''
      },
      industryWindows:false,
      tabPosition: 'left',
      industryLists:[],
      industryShow:'-1',
      userInfo:{},
      companyInfo:{}
    }
  },
  methods:{
    //修改公司信息
    updateCommit(){
      if(!this.companyform.companyName){
        this.$message('公司简称不可为空');return
      }
      let data={
        id:this.companyInfo.id,
        companyName:this.companyform.companyName,
        companyType:this.companyform.companyType,
        companyScale:this.companyform.companyScale,
        companyLogoUrl:this.companyform.companyLogoUrl,
        companyTitle:this.companyform.companyTitle,
        companyLicenseUrl:this.companyform.companyLicenseUrl,
      }
      updateCompanyInfo(data).then(res=>{
        if(res.code==0){
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.getComayInfo()
        }
      })
    },
    //查看公司信息
    getComayInfo() {
      let data = {
        bindJobUserId:this.userInfo.id
      }
      selectCompanyInfo(data).then(res => {
        if(res.code==0&&res.data.length){
          this.companyInfo=res.data[0]
          this.companyform=this.companyInfo;
          this.userInfo.bindJobUserId=res.data[0].bindJobUserId
          this.userInfo.companyId=res.data[0].id
          localStorage.setItem("userInfo", JSON.stringify(this.userInfo));
        }

      })
    },
    //提交
    afrrimCommit(){
      if(!this.companyform.companyName){
        this.$message('公司简称不可为空');return
      }
      let data={
        bindJobUserId:this.userInfo.id,
        companyName:this.companyform.companyName,
        companyType:this.companyform.companyType,
        companyScale:this.companyform.companyScale,
        companyLogoUrl:this.companyform.companyLogoUrl,
        companyTitle:this.companyform.companyTitle,
        companyLicenseUrl:this.companyform.companyLicenseUrl,
      }
      addCompanyInfo(data).then(res=>{
        if(res.code==0){
          this.$message({
            message: "添加成功",
            type: "success",
          });
          this.getComayInfo()
        }
      })

    },
    //  获取行业领域
    getindustryList(){
      industryList().then(res=>{
        this.industryLists=res.data;
      })
    },
    //  行业
    changeIndustry(){
      this.industryWindows=true
    },
    //选择行业
    chooseIndustry(item,index){
      this.industryWindows=false;
      this.companyform.companyType=item.name;
      this.industryShow=index
    },

  },
  mounted() {
    this.getindustryList();
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.getComayInfo()
  }
}
</script>
<style scoped>
@import "../../../../public/css/enterpriseIndex.css";
</style>



