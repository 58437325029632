<template>
  <div>
    <div class="jobStatistics1">
      <div class="tjfx">
        <p>经验/薪资</p>
        <div class="numberRadius">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <p style="font-size: 12px;color: white;margin-left: 12px">薪资：千元</p>
      <div>
        <div>
          <div id="mainwd" style="width: 90%;height:250px;"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import * as echarts from "echarts";
import {getExperience} from "@/api";
export default{
  props:{
    cityValue:''
  },
  data(){
    return{
      experienceArr:[],
      experienceMoneyArr:[],
      province:''
    }
  },
  methods:{
    //  折线图
    experienceChart(){
      let data={
        province:this.province
      }
      getExperience(data).then(res=>{
        if(res.code==0){
          for (var item of res.data){
            this.experienceArr.push(item.name)
            this.experienceMoneyArr.push(item.number)
          }
          this.disposeData()
        }else{
          this.$message.error(res.msg)
        }
      })
    },
    //数据
    disposeData(){
      var chartDom = document.getElementById('mainwd');
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        // toolbox: {
        //   feature: {
        //     dataView: { show: true, readOnly: false },
        //     magicType: { show: true, type: ['line', 'bar'] },
        //     restore: { show: true },
        //     saveAsImage: { show: true }
        //   }
        // },
        // legend: {
        //   data: ['Evaporation', 'Precipitation', 'Temperature']
        // },
        grid:{
          left: '3%',
          right: '4%',
          bottom: '5%',
          containLabel: true,
          height:180,
          y2: 140
        },
        xAxis: [
          {
            type: 'category',
            data: this.experienceArr,
            axisPointer: {
              type: 'shadow'
            },
            boundaryGap: true,
            axisLine: {  //这是x轴文字颜色
              lineStyle: {
                color: "#fff",
              }
            },
            axisLabel:{
              interval:0,//横轴信息全部显示
              rotate:-30,//-30度角倾斜显示
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            // name: 'Precipitation',
            min: 0,
            max: 15,
            interval: 50,
            axisLabel: {
              formatter: '{value} K'
            },
            axisLine: {  //这是Y轴文字颜色
              lineStyle: {
                color: "#fff",
              }
            }
          },
          // {
          //   type: 'value',
          //   // name: 'Temperature',
          //   min: 0,
          //   max: 25,
          //   interval: 5,
          //   axisLabel: {
          //     // formatter: '{value} °C'
          //   }
          // }
        ],
        series: [
          {
            // name: 'Evaporation',
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' ml';
              }
            },
            itemStyle: {
              normal: {
                //这里是重点
                color: '#1BA7F7',

              }
            },
            data:this.experienceMoneyArr
          },

          // {
          //   // name: 'Temperature',
          //   type: 'line',
          //   yAxisIndex: 1,
          //   tooltip: {
          //     valueFormatter: function (value) {
          //       // return value + ' °C';
          //     }
          //   },
          //   itemStyle: {
          //     normal: {
          //       //这里是重点
          //       color: '#49CE7A',
          //
          //     }
          //   },
          //   data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2]
          // }
        ]
      };
      option && myChart.setOption(option);
    }
  },
  watch:{
    cityValue(val,oldVal){
      if(val){
        this.experienceArr=[];
        this.experienceMoneyArr=[];
        if(val!=='全国'){
          this.province=val;
          this.experienceChart();
        }else{
          this.province='';
          this.experienceChart();
        }
      }
    }
  },
  mounted() {
    this.experienceChart();
  }
}
</script>
<style scoped>
@import "../../../public/css/bigNumber.css";
</style>
