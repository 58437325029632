<template>
  <div>
    <div class="jobInvite">
      <div>
        <el-tabs v-model="activeName" @tab-click="changeNews()">
          <el-tab-pane label="职场指南" name="1"></el-tab-pane>
          <el-tab-pane label="自我提升" name="2"></el-tab-pane>
        </el-tabs>
        <div class="jobMarketFlex">
          <img :src="pictureUrl"/>
          <div class="jobMarketFlexDiv" >
            <div>
              <p v-for="(item,index) in newsList" :key="index">{{item.headline}}</p>
            </div>
            <p @click="moreNewList()" style="cursor: pointer"> 更多>></p>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import {
  jobNewsList
} from "@/api";

export default {

  data(){
    return{
      activeName: '1',
      newsList:[],
      pictureUrl:''


    }
  },
  methods:{
    //获取新闻列表
    getjobNewsList(){
      let data={
        categoryType:'guide'
      }
      if(this.activeName==2){
        data.categoryType='lift'
      }
      jobNewsList(data).then(res=>{
        this.newsList=res.data.records;
        this.pictureUrl=res.data.records[0].pictureUrl
      })
    },
    //切换新闻列表
    changeNews(){
      this.getjobNewsList()
    },
    moreNewList(){
      this.$router.push({path:'/xzJobnewsList',query:{index:this.activeName}})
    }
  },
  mounted() {
    this.getjobNewsList()
  }
}
</script>
<style scoped>
@import "../../../../public/css/xzJob.css";
</style>
