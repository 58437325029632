<template>
  <div class="inviteDataBg">
<!--    人才-->
    <div>
      <p style="font-weight: bold">人才职位</p>
      <div class="inviteDataresume">
        <div >
          <p>待查看人才</p>
          <p style="font-size: 12px;margin: 8px 0">（已经投递的简历等待查看）</p>
          <p>
            <span style="font-size: 20px">{{talentsMap.notSee}}</span>
            位
          </p>
        </div>
        <div >
          <p>收到的简历</p>
          <p style="font-size: 12px;margin: 8px 0">企业收到人才投递的简历</p>
          <p>
            <span style="font-size: 20px">{{talentsMap.deliver}}</span>
            位
          </p>
        </div>
        <div >
          <p>在线职位</p>
          <p style="font-size: 12px;margin: 8px 0">（企业上线的职位）</p>
          <p>
            <span style="font-size: 20px">{{talentsMap.jobNum}}</span>
            位
          </p>
        </div>
      </div>
    </div>
    <!--    今日数据-->
    <div>
      <p style="font-weight: bold">今日数据</p>
      <div class="inviteDataresume">
        <div >
          <p>浏览过的人才</p>
          <p style="font-size: 12px;margin: 8px 0">
            <span >较昨日</span>
            <span style="font-size: 20px;color: #00C4AF;margin-left: 6px" v-if="todayMap.ZtodayBrowse>=0">+{{todayMap.ZtodayBrowse}}</span>
            <span style="font-size: 20px;color: #00C4AF;margin-left: 6px" v-if="todayMap.ZtodayBrowse<0">{{todayMap.ZtodayBrowse}}</span>
          </p>
          <p>
            <span style="font-size: 20px">{{todayMap.todayBrowse}}</span>
          </p>
        </div>
        <div >
          <p>沟通过的人才</p>
          <p style="font-size: 12px;margin: 8px 0">
            <span >较昨日</span>
            <span style="font-size: 20px;color: #00C4AF;margin-left: 6px" v-if="todayMap.ZtodayCommunication>=0" >+{{todayMap.ZtodayCommunication}}</span>
            <span style="font-size: 20px;color: #00C4AF;margin-left: 6px" v-if="todayMap.ZtodayCommunication<0">{{todayMap.ZtodayCommunication}}</span>
          </p>
          <p>
            <span style="font-size: 20px">{{todayMap.todayCommunication}}</span>
          </p>
        </div>
        <div >
          <p>收获简历数量</p>
          <p style="font-size: 12px;margin: 8px 0">
            <span >较昨日</span>
            <span style="font-size: 20px;color: #00C4AF;margin-left: 6px" v-if="todayMap.ZtodayDeliver>=0" >+{{todayMap.ZtodayDeliver}}</span>
            <span style="font-size: 20px;color: #00C4AF;margin-left: 6px" v-if="todayMap.ZtodayDeliver<0">{{todayMap.ZtodayDeliver}}</span>
          </p>
          <p>
            <span style="font-size: 20px">{{todayMap.todayDeliver}}</span>
          </p>
        </div>
      </div>
    </div>
    <!--    近期优势-->
    <div>
      <p style="font-weight: bold">近期优势</p>
      <div class="advantageBorder">
        <div class="advantage">
          <div >
            <p>浏览过的人才</p>
            <p>{{advantageMap.browseNum}}</p>
          </div>
          <div >
            <p>沟通过的人才</p>
            <p>{{advantageMap.communicationNum}}</p>
          </div>
          <div >
            <p>收获简历数量</p>
            <p>{{advantageMap.deliverNum}}</p>
          </div>
        </div>
        <div id="main" style="width: 100%;height: 600px">6+66666</div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import {inviteData} from "@/api";
export default {
  data(){
    return{
      userInfo:{},
      todayMap:{},
      talentsMap:{},
      advantageMap:{},
      dataList:[],
      browse:[],
      communication:[],
      deliver:[]
    }
  },
  methods:{
    //echarts
    getData(){
      var chartDom = document.getElementById('main');
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        color: ['#80FFA5', '#00DDFF', '#37A2FF', ],
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          data: []
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: this.dataList
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '浏览量',
            type: 'line',
            stack: 'Total',
            smooth: true,
            lineStyle: {
              width: 0
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgb(128, 255, 165)'
                },
                {
                  offset: 1,
                  color: 'rgb(1, 191, 236)'
                }
              ])
            },
            emphasis: {
              focus: 'series'
            },
            data: this.browse
          },
          {
            name: '沟通量',
            type: 'line',
            stack: 'Total',
            smooth: true,
            lineStyle: {
              width: 0
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgb(0, 221, 255)'
                },
                {
                  offset: 1,
                  color: 'rgb(77, 119, 255)'
                }
              ])
            },
            emphasis: {
              focus: 'series'
            },
            data:this.communication
          },
          {
            name: '投递量',
            type: 'line',
            stack: 'Total',
            smooth: true,
            lineStyle: {
              width: 0
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgb(55, 162, 255)'
                },
                {
                  offset: 1,
                  color: 'rgb(116, 21, 219)'
                }
              ])
            },
            emphasis: {
              focus: 'series'
            },
            data:this.deliver
          },
        ]
      };

      option && myChart.setOption(option);
    },
    //获取数据
    getInviteData(){
      let data={
        uId:this.userInfo.id
      }
      inviteData(data).then(res=>{
        if(res.code==0){
          this.todayMap=res.data.todayMap
          this.talentsMap=res.data.talentsMap
          this.advantageMap=res.data.advantageMap
          let arr=[]
          let browse=[]
          let communication=[]
          let deliver=[]
          res.data.advantageMap.list.forEach(item=>{
            arr.push(item.date)
            this.dataList=arr
            browse.push(item.browse)
            this.browse=browse
            communication.push(item.communication)
            this.communication=communication
            deliver.push(item.deliver)
            this.deliver=deliver
          })
          this.getData()
        }
      })
    },
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.getInviteData()

  }
}
</script>
<style scoped>
@import "../../../../public/css/enterpriseIndex.css";
</style>
