<template>
  <div class="jobDetailBg">
    <div class="jobTitleBg">
      <div>
        <div class="jobDetailContent">
          <div style="display: flex;align-items: center;margin-bottom: 16px">
            <span style="margin-right: 10px">当前位置:</span>
            <el-breadcrumb separator="/">
              <el-breadcrumb-item :to="{ path: '/xzjob' }">首页</el-breadcrumb-item>
              <el-breadcrumb-item><a @click="goJobList">小智job</a></el-breadcrumb-item>
              <el-breadcrumb-item><p >岗位详情</p></el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <p>招聘中</p>
          <p>
            <span>{{JobPostDetail.jobTitle}}</span>
            <span>{{JobPostDetail.jobMinimumSalary}}-{{JobPostDetail.jobMaximumSalary}}K</span>
          </p>
          <p>{{JobPostDetail.jobCity}}丨{{JobPostDetail.jobExperience}} 丨 {{JobPostDetail.jobSchool}} </p>
          <p v-if="JobPostDetail.companyVo">
            <span v-if="JobPostDetail.companyVo.annual==1">带薪年假</span>
            <span v-if="JobPostDetail.companyVo.social==1">五险一金</span>
            <span v-if="JobPostDetail.companyVo.tour==1">员工旅游</span>
          </p>
        </div>
        <div>
          <div style="display:flex;margin-bottom: 12px;justify-content: space-between">
            <el-button type="primary" v-if="!JobPostDetail.communication" @click="openchitchatWindow(JobPostDetail)">立即沟通</el-button>
            <el-button type="primary" v-if="JobPostDetail.communication" @click="openchitchatWindow(JobPostDetail)">继续沟通</el-button>
          </div>
          <div class="consummateResume">
            <p @click="compileResume">
              <img src="images/jobIcon1.png">
              <span class="perfectResume">完善在线简历</span>
            </p>
<!--            <p>-->
<!--              <img src="images/jobIcon2.png">-->
<!--              <span style="color: #409EFF;margin-left: 4px;">新增附件简历</span>-->
<!--            </p>-->
          </div>
        </div>
      </div>
    </div>
<!--    -->
    <div class="jobContentDetail">
      <div>
        <template >
          <div class="JobNavbarFlex" v-if="JobPostDetail.jobUser&&Object.keys(JobPostDetail.jobUser).length">
            <img :src="JobPostDetail.jobUser.headPortraitUrl">
            <div>
              <p>{{JobPostDetail.jobUser.name}}</p>
<!--              <p>人事经理</p>-->
            </div>
          </div>
  <!--        职位描述-->
          <div>
            <div class="jobTitleColor">
              <span></span>
              <span>职位描述</span>
            </div>
            <p class="workResponsibility">
              {{JobPostDetail.jobMessageDetailed}}
            </p>
          </div>
  <!--        公司介绍-->
          <div v-if="JobPostDetail.companyVo">
            <div class="jobTitleColor">
              <span></span>
              <span>公司介绍</span>
            </div>
            <p class="workResponsibility">
              {{JobPostDetail.companyVo.introduce}}
            </p>
          </div>
  <!--        工作地址-->
          <div>
            <div class="jobTitleColor">
              <span></span>
              <span>工作地址</span>
            </div>
            <p class="workResponsibility">
              地址：{{JobPostDetail.jobArea}}
            </p>
          </div>
        </template>
<!--        推荐职位-->
        <div>
          <div class="jobTitleColor">
            <span></span>
            <span>推荐职位</span>
          </div>
          <div class="jobRecommendPosition">
            <div v-for="(item,index) in postList" :key="index" @click="goPOstDetail(item)">
              <p class="positionFont">
                <span>{{item.jobTitle}}</span>
                <span >{{item.jobMinimumSalary}}-{{ item.jobMaximumSalary }}k</span>
              </p>
              <p class="copypositionName" v-if="item.companyVo&&item.companyVo.companyName">{{item.companyVo.companyName}}</p>
            </div>
          </div>
        </div>
      </div>
      <div @click="goComyDetail">
        <companyInfo :companyVo="JobPostDetail.companyVo"></companyInfo>
      </div>
    </div>
    <el-dialog :visible.sync="chitchatWindow" :show-close="true" width="1200px">
      <chitchat :postId="postId" :friendList="friendList" :JobPostDetail="JobPostDetail" @getFriendList="getFriendList"></chitchat>
    </el-dialog>
  </div>
</template>
<script>
import companyInfo from './companyInfo.vue'
import {browseTalents, findfriendList, findPosition} from "@/api";
import chitchat from "@/views/xzJob/components/chitchat";
  export  default {
    components:{
      companyInfo,
      chitchat
    },
    data(){
      return{
        JobPostDetail:{},
        postList:[],
        chitchatWindow:false,
        friendList:[],
        userInfo:{},
        postId:''
      }
    },
    methods:{
      //  编辑简历
      compileResume(){
        this.$router.push({path:'/inviteInfo'})
      },
      //用户查看职位
      addPostNumber(){
        let data={
          uId:this.userInfo.id,
          type:'QY',
          jobId:this.JobPostDetail.id
        }
        browseTalents(data).then(res=>{

        })
      },
      //获取好友列表
      getFriendList(){
        let data={
          uId:this.userInfo.id
        }
        findfriendList(data).then(res=>{
          this.friendList=res.data
        })
      },
      //打开立即沟通聊天页面
      openchitchatWindow(item){
        this.getFriendList()
        this.chitchatWindow=true
        this.postId=item.id
      },
      //  获取职位推荐列表
      getfindPosition(){
        let data={
          findType:'recommend'
        }
        findPosition(data).then(res=>{
          this.postList=res.data.records
        })
      },
      goJobList(){
        this.$router.push('/recommendJobList')
      },
      //去职位详情
      goPOstDetail(item){
        sessionStorage.setItem('JobPostDetail',JSON.stringify(item))
        let routeUrl = this.$router.resolve({
          path: "/jobDetail",
        });
        window.open(routeUrl.href);
      },
    //  去公司详情
      goComyDetail(){
        sessionStorage.setItem('compayDetail',JSON.stringify(this.JobPostDetail))
        let routeUrl = this.$router.resolve({
          path: "/companyDetail",
          query:{compayId:this.JobPostDetail.id}
        });
        window.open(routeUrl.href);
      }
    },
    mounted() {
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
      this.JobPostDetail=JSON.parse(sessionStorage.getItem('JobPostDetail'))
      this.getfindPosition()
      this.getFriendList()
      this.addPostNumber()
    }
  }
</script>
<style scoped>
@import "../../../../public/css/recommendJobList.css";
</style>
