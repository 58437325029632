<template>
  <div>
    <!--            建设背景-->
    <template>
      <div class="bigNumberTitle">
        <span></span>
        <span></span>
        <span>建设背景</span>
        <span></span>
        <span></span>
      </div>
      <div class="backdrop">
        <div>
          <p style="margin-top: 64px;">随着互联网在中国的迅猛发展，大数据、云计算
            等应运而生，刺激了电子商务兴起和发展。对这
            些数据的统计、分析、挖掘和应用将会产生极富
            价值的教育情报，给职业教育的变革发展带来新
            机遇。智捷电商为此规划建设以数据技术为支撑
            的产教融合大数据中心。</p>
        </div>
        <div>
          <p>
            2014国务院《关于加快发展现代化职业教育的决定》
            <br>
            调整完善职业院校区域布局，科学合理设置专业，健全专业随产业发展动态调整的机制
            <br>
            2015教育部《关于深化职业教育教学改革全面提高人才培养质量的若干意见》
            <br>
            建立专业设置动态调整机制，及时发布专业设置预警信息
            <br>
            2017国务院《关于深化产教融合的若干意见》
            <br>
            加快推进教育“方管服”改革，健全需求导向的人才培养结构调整机制，注重发挥市场机制配置非基本公共教育资源
            <br>
            作用，强化就业市场对人才供给的有效调节
            <br>
            2019国务院《国家职业教育改革实施方案》
            <br>
            健全专业设置定期评估机制，强化地方引导本区域职业院校优化专业设置的职责，原则上每5年修订1次职业院校专业
            <br>
            目录，学校依据目录灵活自主设置专业，每年调整一次专业
            <br>
            2019中共中央 国务院《中国教育现代化2035》
            <br>
            推动职业教育与产业发展有机衔接、深度融合，集中力量建成一批中国特色高水平职业院校和专业
            <br>
            2021中共中央办公厅 国务院办公厅《关于推动现代化职业教育高质量发展的意见》
            <br>
            坚持面向市场、促进就业，推动学校布局、专业设置、人才培养与市场需求相对接。......紧密对接产业升级和技术变革
            <br>
            趋势，......形成紧密对接产业链、创新链的专业体系，分级分类编制发布产业结构动态调整报告、行业人才就业状况和
            <br>
            需求预测报告
          </p>
        </div>
      </div>
    </template>
    <!--            建设内容-->
    <template>
      <div class="bigNumberTitle">
        <span></span>
        <span></span>
        <span>建设内容</span>
        <span></span>
        <span></span>
      </div>
      <div class="platformConstruction">
        <template>
          <p class="bigNumberFontweight">1.平台构建</p>
          <div class="platformConstructionImg">
            <img src="images/numberConstruction.png">
          </div>
          <div class="constructionContent">
            <p>政策数据库</p>
            <p>
              依据&内容：
              <br>
              国家/地方政府网站
              <br>
              产业类政策、教育类政策/国家政策、省厅政策
              <br>
              意义：
              <br>
              宏观反映国家层面职业教育发展要求和省委省政府区域经济发展战略，指导深化专业内涵、优化专业方向、细化专业内容
            </p>
          </div>
          <div class="constructionContent">
            <p>招聘数据库</p>
            <p>
              依据&内容：
              <br>
              招聘网站
              <br>
              岗位名称、需求量、工作内容、专业要求、学历要求、能力要求、地区分布、行业分布等
              <br>
              意义：
              <br>
              动态展示行业企业人才需求，提炼职业能力标准，构建岗位画像 ，解析中高本一体化人才培养面向及技能结构，
              形成与岗位群对应的专业(群)
            </p>
          </div>
          <div class="constructionContent">
            <p>职教数据库</p>
            <p>
              依据&内容：
              <br>
              教育部/教育厅/行指委...提供
              <br>
              院校类数据、专业类数据、标准类数据、课程类数据等
              <br>
              意义：
              <br>
              涵盖职教领域相关数据，提炼专业教学标准，形成专业知识图谱，支撑职业教育发展质量评估与优化，为谱系图提供教育端数据支撑

            </p>
          </div>
          <div class="constructionContent">
            <p>专业数据库</p>
            <p>
              依据&内容：
              <br>
              学校数据平台
              <br>
              专业基础数据、专业课程数据、专业教师数据、专业学生数据
              <br>
              意义：
              <br>
              从专业-课程-教师-学生等维度客观展示专业建设进程，形成专业画像，支撑专业建设质量评估与自我诊改
            </p>
          </div>
        </template>
        <template>
          <div>
            <p class="bigNumberFontweight">2.整体框架</p>
            <div style="display: flex;margin-top: 30px;justify-content: center;">
              <div style="margin-right: 20px">
                <p style="height: 142px;line-height: 142px;">数据应用层</p>
                <p style="height: 86px;line-height: 86px;">数据分析层</p>
                <p style="height: 110px;line-height: 110px;">数据集成层</p>
              </div>
              <img src="images/frame.svg">
            </div>
          </div>
        </template>
        <template>
          <div>
            <p class="bigNumberFontweight">3.多元分析结论</p>
            <div style="display: flex;justify-content: center;">
              <img src="images/conclusion.png" style="width: 600px;height: 234px">
            </div>
          </div>
        </template>
      </div>
    </template>
    <!--            建设意义-->
    <template>
      <div class="bigNumberTitle">
        <span></span>
        <span></span>
        <span>建设意义</span>
        <span></span>
        <span></span>
      </div>
      <div style="width: 884px;margin: 0 auto">
        <div class="accurate" v-for="(item,index) in accurateArr" :key="index">
          <div></div>
          <div>
            <p>{{item.name}}</p>
            <p>{{item.content}}</p>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  data(){
    return{
      accurateArr:[
      {id:1,name:'精准对接产业',content:'精准对接产业链，形成本地化、特色化数据自产积累'},
      {id:2,name:'精准培养人才',content:'专业教学体系动态完善，实现精准化人才培养，保障人才培养的前沿、先进'},
      {id:3,name:'有效评价人才',content:'构建专业（群）教学过程数据地图，教学结果分析报告，实现人才培养有效评价'},
      {id:4,name:'动态调整机制构建',content:'形成市场导向的专业（群）动态调整机制，服务专业（群）可持续发展'},
      {id:5,name:'数字治理水平提升',content:'打通专业群内部数字化管理的通道，提升学院数字治理水平'},
      ]
    }
  },
  methods:{

  }
}
</script>
<style scoped>
@import "../../../public/css/bigNumber.css";
</style>
