<template>
  <div>
    <div class="recommendationTitle">
      <span></span>
      <span>职位推荐</span>
      <span></span>
    </div>
    <div >
      <div class="recommendBackground" style="height: 40px">
        <el-tabs v-model="activeName" @tab-click="getfindPosition" >
          <el-tab-pane label="推荐职位" name="1"></el-tab-pane>
          <el-tab-pane label="最新职位" name="2"></el-tab-pane>
          <el-tab-pane label="热招职位" name="3"></el-tab-pane>
          <el-tab-pane label="校园实习" name="4"></el-tab-pane>
        </el-tabs>
      </div>
      <div >
        <div class="jobIndexFlex jobAlingrCenter" style="flex-wrap: wrap">
          <div class="recommendBackground positionJobtuijian" style="margin-top: 20px" v-for="(item,index) in postList.slice(0, 6)" :key="index" @click="goJobDetail(item)">
            <div >
              <p class="positionFlex">
                <span>{{item.jobTitle}}</span>
                <span>{{item.jobMinimumSalary}}-{{item.jobMaximumSalary}}k</span>
              </p>
              <p>经验{{item.jobExperience}}年 / {{item.jobSchool}}</p>
            </div>
            <div class="jobIndexFlex jobIndexFlexJuct jobAlingrCenter">
              <div class="jobIndexFlex  jobAlingrCenter">
                <img src="images/exiaozhiLogo.png" style="width: 26px;margin-right: 8px">
                <span>{{item.companyTitle}}</span>
              </div>
              <div>
                <i class="el-icon-location-outline"></i>
                <span>{{item.jobArea}}</span>
              </div>
            </div>
          </div>
          <div v-if="!postList.length" style="width: 100%">
            <el-empty :image-size="200"></el-empty>
          </div>
        </div>
      </div>
    </div>
    <div class="recommendMore" v-if="postList.length">
      <el-button type="primary" style="width: 386px" @click="selectMore">查看更多</el-button>
    </div>

  </div>
</template>
<script>
import {findPosition} from "@/api";
export default {
  data(){
    return{
      activeName: '1',
      postList:[]
    }
  },
  methods:{
    //去职位详情
    goJobDetail(item){
      sessionStorage.setItem('JobPostDetail',JSON.stringify(item))
      this.$router.push('jobDetail')
    },
    selectMore(){
      let routeUrl = this.$router.resolve({
        path: "/recommendJobList",
      });
      window.open(routeUrl.href);
    },
  //  获取职位推荐列表
    getfindPosition(){
      let data={
        findType:'recommend'
      }
      if(this.activeName==1){
        data.findType='recommend'
      }else if(this.activeName==2){
        data.findType='newest'
      }else if(this.activeName==3){
        data.findType='hot'
      }else if(this.activeName==4){
        data.findType='school'
      }
      findPosition(data).then(res=>{
        this.postList=res.data.records
      })
    },

  },
  mounted() {
    this.getfindPosition()
  }
}
</script>
<style scoped>
@import "../../../../public/css/xzJob.css";
</style>
