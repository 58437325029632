import { render, staticRenderFns } from "./companyRecommend.vue?vue&type=template&id=21ffe4c4&scoped=true"
import script from "./companyRecommend.vue?vue&type=script&lang=js"
export * from "./companyRecommend.vue?vue&type=script&lang=js"
import style0 from "./companyRecommend.vue?vue&type=style&index=0&id=21ffe4c4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21ffe4c4",
  null
  
)

export default component.exports