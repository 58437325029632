<template>
  <div>
    <div class="companyinformation" v-if="companyVo">
      <p >公司基本信息</p>
      <div class="copyImg" >
        <img :src="companyVo.companyLogoUrl">
        <p>{{companyVo.companyTitle}}</p>
      </div>
<!--      <p>-->
<!--        <img src="images/jobIcon3.png">-->
<!--        <span style="font-size: 14px;margin-left: 10px">不需要融资</span>-->
<!--      </p>-->
      <p>
        <img src="images/jobIcon4.png">
        <span style="font-size: 14px;margin-left: 10px">{{companyVo.companyScale}}</span>
      </p>
      <p>
        <img src="images/jobIcon5.png">
        <span style="font-size: 14px;margin-left: 10px">{{companyVo.companyType}}</span>
      </p>
<!--      <p style="font-size: 14px;margin-left: 10px;color: #999999">更新于 2021-02-28</p>-->
<!--      <p style="font-size: 14px;margin-left: 10px">公司视频</p>-->
<!--      <div>-->
<!--        <img src="images/zanwuImgs.png">-->
<!--      </div>-->
      <p style="font-size: 14px;margin-left: 10px">公司照片</p>
      <div>
        <img style="width: 256px;object-fit: cover" :src="companyVo.photoAlbumUrl">
      </div>
    </div>
  </div>
</template>
<script>
export  default {
  props:{
    companyVo:{}
  },
  data(){
    return{

    }
  },
  watch:{
    companyVo(val,oldVal){
      if(val){
        this.companyVo=val
      }
    }
  }
}
</script>
<style scoped>
@import "../../../../public/css/recommendJobList.css";
</style>
