<template>
  <div>
    <div >
      <div class="inviteInfoAvartareFlex inviteUserInfo " v-show="userresumeInfo" @mouseout="mouseLeceAvatar" @mousemove="mouseoverAvatar">
        <div class="inviteInfoResumeFlex " >
          <div>
            <img :src="jobUser.headPortraitUrl" class="inviteAvartar">
          </div>
          <div class="inviteName">
            <p style="font-size: 22px;color: #333333">{{jobUser.name}}</p>
            <p>山东智捷电子商务有限公司 丨 UI设计师</p>
            <p>{{jobUser.workTimeSlice}}年 丨 本科 丨{{jobUser.birthdayTime}}岁 </p>
            <div class="inviteInfoResumeFlex inviteSpanFlex" style="width: 550px">
              <div >
                <img src="images/numberIcons1.png">
                <span>{{jobUser.workTime}}</span>
              </div>
              <div>
                <img src="images/numberIcons2.png">
                <span>{{jobUser.telNumber}}</span>
              </div>
              <div>
                <img src="images/numberIcons3.png">
                <span>{{jobUser.weixinNumber}}</span>
              </div>
              <div>
                <img src="images/numberIcons4.png">
                <span>{{jobUser.mailNumber}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="compile" @click="changeAvartar()" id="compileBack" style="display:none" >
          <i class="el-icon-edit"></i>
          <span>编辑</span>
        </div>
      </div>
      <div  v-show="!userresumeInfo">
        <p style="margin-bottom: 18px">编辑个人信息</p>
        <el-form :inline="true" :model="ruleForm"  ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="姓名" prop="name">
            <el-input v-model="ruleForm.name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="性别" prop="resource">
            <el-radio-group v-model="ruleForm.gender">
              <el-radio label="男"></el-radio>
              <el-radio label="女"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="生日" >
            <div class="userDate userBirthday">
              <el-date-picker
                  value-format="yyyy-MM-dd"
                  v-model="ruleForm.birthday"
                  type="date"
                  placeholder="选择日期">
              </el-date-picker>
            </div>
          </el-form-item>
          <el-form-item label="所在城市" prop="name">
            <el-input v-model="ruleForm.cityName" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="手机号码" prop="name">
            <el-input v-model="ruleForm.telNumber"></el-input>
          </el-form-item>
          <el-form-item label="联系邮箱" prop="name">
            <el-input v-model="ruleForm.mailNumber"></el-input>
          </el-form-item>
          <el-form-item label="微信号" prop="name">
            <el-input v-model="ruleForm.weixinNumber"></el-input>
          </el-form-item>
          <el-form-item label="工作时间" >
            <div class="userDate userBirthday">
              <el-date-picker
                  value-format="yyyy-MM-dd"
                  v-model="ruleForm.workTime"
                  type="date"
                  placeholder="选择日期">
              </el-date-picker>
            </div>
          </el-form-item>
          <el-form-item label="求职状态" >
            <el-select v-model="ruleForm.asState" placeholder="请选择" style="width: 93%">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <div style="display: flex;justify-content: flex-end;width: 624px">
              <el-button @click="clearInfo">取消</el-button>
              <el-button type="primary" @click="accomplishAvartarInfo()">完成</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import {updateUserById} from "@/api";
export default {
  props: {
    //用户信息
    jobUser: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
  },
  components:{
  },
  data(){
    return{
      userresumeInfo:true,
      ruleForm: {
        name: '',
        gender: '男',
        birthday: '',
        telNumber: '',
        mailNumber: '',
        weixinNumber: '',
        workTime: '',
        cityName:'',
        asState: '0',
      },
      options: [{
        value: '0',
        label: '在职-暂不考虑'
      }, {
        value: '1',
        label: '在职-考虑机会'
      }, {
        value: '2',
        label: '在职-月内到岗'
      }, {
        value: '3',
        label: '离职-随时到岗'
      },],

    }
  },
  methods:{
    changeAvartar(){
      console.log(this.jobUser)
      this.userresumeInfo=false;
      this.ruleForm=this.jobUser
      let gender=''
      if(this.jobUser.gender=='female'){
        gender='女'
      }else{
        gender='男'
      }
      this.ruleForm.gender=gender;
    },
    //取消
    clearInfo(){
      this.userresumeInfo=true;
    },
    //  修改个人简历
    accomplishAvartarInfo(){

      if(!this.ruleForm.name){
        this.$message('姓名不可为空');return
      }
      if (!/^[1]([0-9])[0-9]{9}$/.test(this.ruleForm.telNumber)) {
        this.$message.error("手机格式不对");
        return;
      }
      let data={
        name:this.ruleForm.name,
        birthday:this.ruleForm.birthday,
        telNumber:this.ruleForm.telNumber,
        weixinNumber:this.ruleForm.weixinNumber,
        asState:this.ruleForm.asState,
        cityName:this.ruleForm.cityName,
        mailNumber:this.ruleForm.mailNumber,
        workTime:this.ruleForm.workTime,
        id:this.jobUser.id,
        uId:this.jobUser.uId
      }
      if(this.ruleForm.gender=='男'){
        data.gender='male'
      }else{
        data.gender='female'
      }
      updateUserById(data).then(res=>{
        if(res.code==0){
          this.$emit('getfindUserByuId')
          this.userresumeInfo=true;
          this.$message({
            message: "修改成功",
            type: "success",
          });
          let userInfo = JSON.parse(localStorage.getItem('userInfo'))
          userInfo.name =res.data.name
          userInfo.telNumber =res.data.telNumber;
          userInfo.gender=res.data.gender
          this.$store.commit("setUserName", res.data.name);
          this.$store.commit("telNumber", res.data.telNumber);
          localStorage.setItem('userInfo',JSON.stringify(userInfo));
        }
      })
    },
  //  头像进入
    mouseoverAvatar(){
      var compileBack = document.getElementById('compileBack');
      compileBack.style.display = "block";
    },
  // 离开
    mouseLeceAvatar(){
      var compileBack =document.getElementById('compileBack');
      compileBack.style.display = "none";
    },

  },
  mounted() {

  }
}
</script>
<style scoped>
@import "../../../../public/css/inviteInfo.css";
</style>
