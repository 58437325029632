<template>
  <div class="xzJobBg">
    <div>
      <img width="100%" src="images/jobBanner.png">
    </div>
    <div class="xzJobContent">
      <div>
        <div class="xzJobSearch">
        <div style="padding-left: 20px">
          <input class="jobSearchInput" v-model="searchjobPost"></input>
        </div>
        <p @click="searPOst">搜索</p>
      </div>
<!--        <div class="jobIndexFlex hotJobSearch">-->
<!--          <p>热门搜索：</p>-->
<!--          <p>产品  运营  涨薪  电商  设计</p>-->
<!--        </div>-->
      </div>
<!--      职场指南-->
      <div style="display: flex;justify-content: space-between;">
        <inviteJob></inviteJob>
        <!--      用户登录-->
        <userLogin @chitChatshows="chitChatshows"></userLogin>
      </div>

<!--      职位推荐-->
      <recommendJob></recommendJob>
<!--      热门企业-->
      <hotEnterprise></hotEnterprise>
<!--      城市大数据-->
      <cityInformation></cityInformation>
    </div>
<!--    <div class="positionXzjobInfo" @click="openChitchat">-->
<!--      <p>消息</p>-->
<!--    </div>-->
    <div class="chitchatBg">
      <el-dialog :visible.sync="chitchatWindow" :show-close="true" width="1200px" @open="openChiat" @close="closeWindow">
        <div>
          <chitchat  :friendList="friendList" :windowStage="windowStage"></chitchat>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
  import inviteJob from './user/inviteJob.vue'
  import recommendJob from './user/recommendJob.vue'
  import hotEnterprise from './user/hotEnterprise.vue'
  import cityInformation from  './user/cityInformation.vue'
  import chitchat from "@/views/xzJob/components/chitchat";
  import userLogin from '@/views/xzJob/components/userLogin.vue'
  import {findfriendList} from "@/api";
  export default {
    components:{
      inviteJob,
      recommendJob,
      hotEnterprise,
      cityInformation,
      chitchat,
      userLogin
    },
    data(){
      return{
        chitchatWindow:false,
        friendList:[],
        userInfo:{},
        searchjobPost:'',
        windowStage:false
      }
    },
    methods:{
      chitChatshows(val){
        this.getFriendList(this.userInfo)
        this.chitchatWindow=val
      },
      closeWindow(){
        this.windowStage=true
      },
      //去搜索页面
      searPOst(){
        let routeUrl = this.$router.resolve({
          path: "/recommendJobList",
          query:{searchjobPost:this.searchjobPost}
        });
        window.open(routeUrl.href);
        this.searchjobPost=''
      },

      //获取好友列表
      getFriendList(item){
        let data={
          uId:item.id
        }
        findfriendList(data).then(res=>{
          this.friendList=res.data
        })
      },
    //  打开聊天弹窗
      openChiat(){
        this.windowStage=false
      }
    },
    mounted() {
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'))

    },
    watch:{
      windowStage:{
        handler: function (val, oldVal) {
        },
        deep: true
      },
    }
  }
</script>
<style scoped>
@import "../../../public/css/xzJob.css";
</style>
