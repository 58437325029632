<template>
  <div>
    <div class="bigNumberBg">
    <div>
      <img v-if="activeName=='0'" src="images/bigNumberBanner.png" style="width: 100%">
      <img v-if="activeName=='1'" src="images/bigNumber3.png" style="width: 100%">
      <img v-if="activeName=='2'" src="images/bigNumber2.png" style="width: 100%">
    </div>
    <div>
      <div>
        <div style="width: 100%;border-bottom: 2px solid #409EFF;padding-bottom: 15px">
          <div class="numberTabINdex">
            <p class="numberTabINdexp" v-for="(item,index) in numberTab" :key="index" @click="changeTab(index)" :class="activeName==index?'numberactiveColor':''">{{item.name}}</p>
          </div>
        </div>
<!--        内容-->
        <div>
<!--          专业建设大数据中心-->
          <numberCenter v-if="activeName==0"></numberCenter>
<!--          专业咨询报告服务-->
          <numberServe v-if="activeName==1"></numberServe>
<!--          真实应用驱动案例-->
          <numberCase  v-if="activeName==2"></numberCase>
        </div>
      </div>
    </div>
  </div>
    <div >
      <Footer></Footer>
    </div>
  </div>
</template>
<script>
import numberCenter from './numberCenter.vue'
import numberServe from './numberServe.vue'
import numberCase from './numberCase.vue'
import Footer from '../footer.vue'
export default {
  components:{
    numberCenter,
    numberServe,
    numberCase,
    Footer
  },
  data(){
    return{
      activeName: '0',
      numberTab:[
        {id:1,name:'专业建设大数据中心'},
        {id:2,name:'专业咨询报告服务'},
        {id:3,name:'真实案例'},
      ]
    }
  },
  methods:{
  //标签页
    changeTab(index){
      this.activeName=index
    }
  }
}
</script>
<style scoped>
  .numberTabINdex{
    display: flex;
    width: 50%;
    margin: 0 auto;
    justify-content: space-around;
    font-size: 18px;
  }
  .numberTabINdexp{
    cursor: pointer;
  }
  .numberactiveColor{
    color: #409EFF;
  }
</style>
