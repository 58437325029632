<template>
  <div class="companyUserInfoBg">
    <div class="companyTab">
      <p v-for="(item,index) in companyInfoTab" :key="index" @click="changeCompany(index)" :class="companyTabShow==index?'companyTabACtive':''">{{item.name}}</p>
    </div>
    <div>
      <div v-if="companyTabShow==0">
        <div class="basicFlex">
          <p>基本信息</p>
        </div>
        <div class="companyuserFlex userNameFkex">
          <div>
            <div class="companyuserFlex" style="margin-bottom: 16px;align-items: center">
              <span style="display: inline-block;width: 120px">姓名：</span>
              <el-input v-model="userMessage.name"></el-input>
            </div>
            <div class="companyuserFlex" style="margin-bottom: 16px;align-items: center">
              <span style="display: inline-block;width: 120px">职务：</span>
              <el-input v-model="userMessage.careerName"></el-input>
            </div>
            <div class="companyuserFlex" style="margin-bottom: 16px;align-items: center">
              <span style="display: inline-block;width: 120px">微信：</span>
              <el-input v-model="userMessage.weixinNumber"></el-input>
            </div>
            <div class="companyuserFlex" style="margin-bottom: 16px;align-items: center">
              <span style="display: inline-block;width: 120px">常用邮箱：</span>
              <el-input v-model="userMessage.mailNumber"></el-input>
            </div>
          </div>
          <custom-upload  v-model="userMessage.headPortraitUrl" bizType='headPortrait' aliVideoOssData="0"></custom-upload>
        </div>
        <div style="display: flex;justify-content: flex-end">
          <p class="preserveInfo" @click="submitUserInfo">保存</p>
        </div>
      </div>
      <div v-if="companyTabShow==1">
        <div class="basicFlex">
          <p>账号安全</p>
        </div>
        <div>
          <p>密码</p>
          <p class="setuserInfoPassword">
            <span>已设置</span>
            <span @click="updatePassword">修改</span>
          </p>
        </div>
<!--        <div style="display: flex;justify-content: flex-end;margin-top: 40px">-->
<!--          <p class="preserveInfo" @click="updateUserInfo">保存</p>-->
<!--        </div>-->
      </div>
      <div v-if="companyTabShow==2">
        <div class="basicFlex">
          <p>账号安全</p>
        </div>
        <div>
          <p class="addAdress">添加地址</p>
          <div>
            <el-table
                :data="tableData"
                style="width: 100%">
              <el-table-column
                  prop="date"
                  label="地址"
                  width="180">
              </el-table-column>
              <el-table-column
                  prop="name"
                  label="区域">
              </el-table-column>
              <el-table-column
                  label="操作">
                  <template slot-scope="scope">
                    <el-button @click="compileAdress(scope.row)" type="text" size="small">编辑</el-button>
                    <el-button type="text" size="small">删除</el-button>
                  </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="amendPasswordWindow" :show-close="true" title="修改密码">
      <div class="enterprisePassword">
        <div>
          <!--          <p>原密码:</p>-->
          <el-input show-password v-model="form.oldPassword" placeholder="请输入旧密码"></el-input>
        </div>
        <div>
          <!--          <p>新密码:</p>-->
          <el-input show-password placeholder="设置6~16位新密码" v-model="form.newPassword"></el-input>
        </div>
        <div>
          <!--          <p>确认密码:</p>-->
          <el-input show-password v-model="form.affrimPassword" placeholder="请确认密码"></el-input>
        </div>
        <div style="display: flex;justify-content: flex-end;margin-top: 20px">
          <el-button type="primary" @click="submitAmendPassword">修改密码</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {findCompanyUserById, studentUpPassword, updateUserById,} from "@/api";
import CustomUpload from "@/components/custom-upload";
  export default {
    components: {
      CustomUpload,
    },
    data(){
      return {
        amendPasswordWindow:false,
        companyInfoTab:[
          {id:1,name:'基本信息'},
          {id:2,name:'账号安全'},
          {id:3,name:'地区管理'},
        ],
        userMessage:{
          name:'',
          careerName:'',
          weixinNumber:'',
          mailNumber:'',
          headPortraitUrl:''
        },
        userInfo:{},
        companyObj:{},
        companyTabShow:0,
        form:{
          oldPassword:'',
          newPassword:'',
          affrimPassword:''
        },
        tableData: [{
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        }, {
          date: '2016-05-04',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1517 弄'
        }, {
          date: '2016-05-01',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1519 弄'
        }, {
          date: '2016-05-03',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1516 弄'
        }]

      }
    },
    methods:{
      //编辑地址
      compileAdress(){

      },
      //显示修改密码弹窗
      updatePassword(){
        this.amendPasswordWindow=true
      },
      //修改密码
      updateUserInfo(){

      },
      //点击tab
      changeCompany(index){
          this.companyTabShow=index
        },
        //获取
        getUerInfo(){
          let data={
          uId:this.userInfo.id
        }
        findCompanyUserById(data).then(res=>{
          this.companyObj=res.data
          this.userMessage=res.data
        })
      },
      //修改密码
      submitAmendPassword(){
        if (!this.form.oldPassword) {
          this.$message.error("旧密码不可为空");
          return;
        }
        if (!/^[a-zA-Z0-9]{6,16}$/.test(this.form.newPassword)) {
          this.$message.error("新密码格式不对");
          return;
        }
        if(this.form.newPassword!==this.form.affrimPassword){
          this.$message.error("两次输入的密码不一致");
          return;
        }
        let data={
          oldPassword:this.form.oldPassword,
          newPassword:this.form.affrimPassword,
          terminalType:'WEB',
          telNumber:this.userInfo.telNumber,
          enterprisePersonal:0
        }
        studentUpPassword(data).then(res=>{
          if(res.code==0){
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.$router.push('/xzjobLogin')
          }
        })
      },
      //保存
      submitUserInfo(){
        if(!this.userMessage.name){
          this.$message('姓名不可为空');return;
        }
        let data={
          id:this.userMessage.id,
          uId:this.userMessage.uId,
          name:this.userMessage.name,
          careerName:this.userMessage.careerName,
          weixinNumber:this.userMessage.weixinNumber,
          mailNumber:this.userMessage.mailNumber,
          headPortraitUrl:this.userMessage.headPortraitUrl
        }
        updateUserById(data).then(res=>{
          if(res.code==0){
            this.$message({
              message: "保存成功",
              type: "success",
            });
            this.getUerInfo()
            this.userInfo.name=res.data.name,
            this.userInfo.headPortraitUrl=res.data.headPortraitUrl
            localStorage.setItem('userInfo',JSON.stringify(this.userInfo));
          }
        })
      }
    },
    mounted() {
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
      this.getUerInfo()
    }
  }
</script>
<style scoped>
@import "../../../../public/css/enterpriseIndex.css";
</style>
