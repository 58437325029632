<template>
  <div class="jobStatistics1" style="height: 280px">
    <div class="tjfx">
      <p>岗位数据统计分析</p>
      <div class="numberRadius">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <div  class="statisticsSurface">
      <div>
        <span style="color: #1BA7F7">{{cityValue}}</span>
        --总需求量（万人）</div>
      <div id="maind" style="width: 100%;height: 100%;bottom: 58px;"></div>
    </div>
  </div>
</template>
<script>
  import * as echarts from "echarts";
  import {getJobDataNubmer} from "@/api";

  export default{
    props:{
      cityValue:''
    },
    data(){
      return{
        category:[],
        barData:[],
        province:''
      }
    },
    methods:{
      //数据接口
      getData(){
        let data={
          province:this.province
        }
        getJobDataNubmer(data).then(res=>{
          if(res.code==0){
            for (var item of res.data){
              this.category.push(item.name)
              this.barData.push(item.number)
            }
            this.Jobstatistics();
          }else{
            this.$message.error(res.msg)
          }

        })
      },
      //岗位数据分析
      Jobstatistics(){
        //初始化数据
        // var category = [];
        // var barData = [3100, 2142, 1218, 581, 431, 383, 163];

        var myChart = echarts.init(document.getElementById('maind'));
        var option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            left: '3%',
            right: '30%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'value',
            axisLine: {
              show: false
            },
            splitLine:{
              show:false
            },
            axisTick: {
              show: false
            },
            axisLabel:{
              color: 'transparent'
            }
          },
          yAxis: {
            type: 'category',
            data: this.category,
            splitLine:{
              show:false
            },
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            offset: 10,
            nameTextStyle: {
              fontSize: 15
            },
            axisLabel: {
              color:'white'
            },
          },
          series: [
            {
              name: '数量',
              type: 'bar',
              data: this.barData,
              barWidth: 14,
              barGap: 10,
              smooth: true,
              label: {
                normal: {
                  show: true,
                  position: 'right',
                  offset: [5, -2],
                  textStyle: {
                    color: 'white',
                    fontSize: 13
                  }
                }
              },
              itemStyle: {
                emphasis: {
                  barBorderRadius: 7
                },
                normal: {
                  barBorderRadius: 7,
                  color: new echarts.graphic.LinearGradient(
                      0, 0, 1, 0,
                      [
                        {offset: 0, color: '#0078FF'},
                        {offset: 1, color: '#7BE16E'}

                      ]
                  )
                }
              }
            }
          ]
        };
        myChart.setOption(option);
      },
    },
    mounted() {
      this.getData()
      console.log(this.cityValue)
    },
    watch:{
      cityValue(val,oldVal){
        if(val){
          this.category=[];
          this.barData=[]
          if(val!=='全国'){
            this.province=val;
            this.getData();
          }else{
            this.province='';
            this.getData();
          }
        }
      }
    }
  }
</script>
<style scoped>
@import "../../../public/css/bigNumber.css";
</style>
