<template>
  <div>
    <p style="font-size: 18px">公司相册</p>
    <p class="companyIns">公司照片建议选择近景前台或其他办公环境，远景门口或大楼。</p>
    <div>
      <custom-upload  v-model="headPortraitUrl" bizType='headPortrait' aliVideoOssData="0"></custom-upload>
    </div>
    <div style="display: flex;justify-content: flex-end">
      <el-button type="primary" @click="uploadFile" >保存</el-button>
<!--      <el-button type="primary" @click="uploadFile" v-if="this.companyInfo&&this.companyInfo.photoAlbumUrl">修改</el-button>-->
    </div>
  </div>
</template>
<script>
import {updateCompany} from "@/api";
import { uploadUserAvatar} from "@/api/user";
import CustomUpload from "@/components/custom-upload";
export default {
  props:{
    companyInfo:{}
  },
  components: {
    CustomUpload,
  },
  data(){
    return{
      userInfo:{},
      headPortraitUrl:''
    }
  },
  methods:{
    uploadFile(){
      updateCompany({id:this.userInfo.companyId,photoAlbumUrl:this.headPortraitUrl}).then(res=>{
        if(res.code=="0"){
          // this.companyData.headPortraitUrl = avatarUrl;
          this.$message({
            message: '操作成功',
            type: 'success'
          });
        }else{
          this.$message.error('失败，请重试');
        }
      })

    },
  },
  mounted(){
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))

  },
  watch:{
    companyInfo(val,oldVal){
      this.companyInfo=val
      this.headPortraitUrl=val.photoAlbumUrl
    }
  }
}
</script>
<style scoped>
@import "../../../../public/css/enterpriseIndex.css";
</style>



