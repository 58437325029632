<template>
  <div style="width: 276px;height: 298px;background: white">
    <div v-if="jobLoginshow">
      <div style="padding: 24px">
        <div class="jobIndexFlex jobMrb">
          <el-button type="primary" plain>个人登录</el-button>
          <el-button>企业登录</el-button>
        </div>
        <el-input placeholder="请输入账号" class="jobMrb"></el-input>
        <el-input placeholder="请输入密码" show-password class="jobMrb"></el-input>
        <div class="jobMrb">
          <img src="images/wx.png" style="width: 22px;cursor: pointer"/>
        </div>

      </div>
    </div>
    <div>
      <div style="padding: 24px">
        <p class="compilejobUser" @click="compileResume">编辑</p>
        <div class="jobIndexFlex jobAlingrCenter jobMrb" >
          <img src="images/tacitlyAvatar.png" class="welcomejobUser"/>
          <div v-if="userLoginInfo&&userLoginInfo.enterprisePersonal==1">
            <p>Hi，{{userInfo.name}} </p>
            <p style="font-size: 14px;margin-top: 6px">欢迎回来</p>
          </div>
          <div class="jobIndexFlex " v-if="userLoginInfo&&userLoginInfo.login==0">
            <p class="jobLoginFont" @click="goJobUserlogin()">登录</p>
            <p class="jobLoginFont">注册</p>
          </div>

        </div>
        <div class="jobMrb" style="margin-top: 24px">
          <el-select v-model="jobState" placeholder="请选择" disabled>
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
            </el-option>
          </el-select>
        </div>
        <div class="jobIndexFlex jobIndexFlexJuct">
          <div class="jobUserText" @click="compileResume">
            <p>{{userLoginInfo.resumes}}</p>
            <p>我的简历</p>
          </div>
          <div class="jobUserText" @click="goChitchat">
            <p>{{ userLoginInfo.communications }}</p>
            <p>沟通过</p>
          </div>
          <div class="jobUserText">
            <p>{{userLoginInfo.delivers}}</p>
            <p>我的投递</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {findLoginType} from "@/api";
export default {
  data(){
    return{
      jobLoginshow:false,
      options: [{
        value: '0',
        label: '在职-暂不考虑'
      }, {
        value: '1',
        label: '在职-考虑机会'
      }, {
        value: '2',
        label: '在职-月内到岗'
      }, {
        value: '3',
        label: '离职-随时到岗'
      },],
      jobState: '0',
      userInfo:{},
      userLoginInfo:{}
    }
  },
  methods:{
    //去沟通
    goChitchat(){
      this.$emit('chitChatshows',true)
    },
    getfindLoginType(){
      findLoginType().then(res=>{
        this.userLoginInfo=res.data;
        this.options.forEach(item=>{
          item.value==res.data.asState
        })
      })
    },
    goJobUserlogin(){
      this.$router.push({path:'/xzjobLogin',})
    },
  //  编辑简历
    compileResume(){
      this.$router.push({path:'/inviteInfo'})
    },

  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.getfindLoginType()
  }
}
</script>
<style scoped>
@import "../../../../public/css/xzJob.css";
</style>
