<template>
  <div class="chitChatFlex">
    <div class="chitchatAuto">
      <div  v-for="(item,index) in friendList" :key="index" @click="changeUserId(item)">
        <div @click="changeChat(item.uId,index)" class="chitChatFlexAvatar" :class="friendShow==index?'chitchatActive':''">
          <img v-if="item.user.headPortraitUrl" :src="item.user.headPortraitUrl">
          <img v-if="!item.user.headPortraitUrl" src="images/tacitlyAvatar.png">
          <div >
  <!--          企业-->
            <p class="chitChatNameFlex" v-if="userInfo&&userInfo.enterprisePersonal==0">
              <span>{{item.user.name}}</span>
              <span v-if="item.chatMsg&&item.chatMsg!==null">{{item.chatMsg.time}}</span>
            </p>
  <!--          求职者-->
            <p class="chitChatNameFlex" v-if="userInfo&&userInfo.enterprisePersonal==1">
              <span>{{item.user.name}}</span>
              <span v-if="item.chatMsg&&item.chatMsg!==null">{{item.chatMsg.time}}</span>
            </p>
            <div class="chitChatInfoCred">
              <span class="msgNubmer" v-if="item.chatMsg&&item.chatMsg!==null">{{item.chatMsg.msg}}</span>
              <p>
                <span class="msgNubmerbg" v-if="item.chatMsg&&item.chatMsg!==null&&item.msgNumber!==0" >{{item.msgNumber}}</span>
              </p>

            </div>
          </div>
        </div>
      </div>
    </div>
<!--    聊天区域-->
    <div style="width: 72%">
      <div class="chitChatTabFlex">
        <p class="chitChatTabFlexp" :class="chiatTabShow==1?'chitChatTabFlexpActive':''" @click="changechatTab(1)">聊天</p>
        <p class="chitChatTabFlexp" :class="chiatTabShow==2?'chitChatTabFlexpActive':''" @click="changechatTab(2)">在线简历</p>
      </div>
      <template v-if="chiatTabShow==1">
<!--        <div class="chitchatintroduce">-->
<!--          <span>王子服</span>-->
<!--          <span>智捷电商 丨 HRBP主管</span>-->
<!--        </div>-->
  <!--      聊天记录-->
        <div class="chitChatrecords" ref="chitchatBg" >
          <div id="receiveMsg"></div>
        </div>
  <!--      发送消息-->
        <div class="sendinfo">
          <div>
            <i v-if="userInfo&&userInfo.enterprisePersonal==1" slot="trigger" class="el-icon-picture" style="font-size: 25px;cursor: pointer" @click="openAccessoryWindow"></i>
          </div>
          <div style="display: flex;justify-content: space-between;align-items: flex-end">
            <el-input
                id="msg"
                type="textarea"
                placeholder="请输入内容"
                v-model="sendMsgContent"
                show-word-limit
                :autosize="{ minRows: 4 }"
            >
            </el-input>
            <div style="margin-left: 10px">
              <el-button type="primary" onclick="CHAT.chat()" >发送</el-button>
            </div>

          </div>
<!--          发送消息：<input type="text" id="msg" v-model="sendMsgContent" />-->
<!--          <button type="button" value=""  onclick="CHAT.chat()" /><br />-->
<!--          <hr />-->
<!--          接收消息：-->
<!--          <div id="receiveMsg"></div>-->
        </div>
      </template>
      <div v-if="chiatTabShow==2" class="resumeContent">
        <resumeDetail :postDetail="postDetail"></resumeDetail>
      </div>
    </div>
    <el-dialog
        :title="'请选择需要投递的附件简历'"
        :visible.sync="accessoryWhindow"
        :show-close="true"
        :append-to-body="true"
        :destroy-on-close="true">
      <div >
        <div>
          <p class="accessoryClass" :class="accessoryShow==index?'accessoryAcitve':''" v-for="(item,index) in userJobResume.jobResources" :key="index" @click="changeSourceRawName(item,index)"><img style="margin-right: 6px" src="images/numberIcon8.png"/>{{item.sourceRawName}}</p>
          <div style="display: flex;justify-content: flex-end">
            <el-button @click="affrimjobResume()" type="primary">确定</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import resumeDetail from "@/views/xzJob/components/resumeDetail";
import {findfriendList, findUserByuId} from '@/api'
export default {
  props:{
    JobPostDetail:{},
    postDetail:{},
    friendList: {
      required: true,
      default() {
        return []
      },
    },
    postId:'',
    windowStage:{
      type: Boolean,
      default: false
    }

  },
  components:{
    resumeDetail
  },
  data(){
    return{
      chiatTabShow:1,
      userInfo:{},
      sendMsgContent:'',
      friendShow:-1,
      accessoryWhindow:false,
      userJobResume:{},
      accessoryId:'',
      sourceFileReadUrl:'',
      accessoryShow:'-1',

    }
  },
  methods:{
    changeSourceRawName(item,index){
      this.accessoryId=item.id
      this.sourceFileReadUrl=item.sourceFileReadUrl
      this.accessoryShow=index
    },
    //发动附件
    affrimjobResume(){
      this.accessoryWhindow=false
      CHAT.chat()
    },
    //  获取个人简历信息
    getfindUserByuId(){
      let data={
        uId:this.userInfo.id
      }
      findUserByuId(data).then(res=>{
        this.userJobResume=res.data;
      })
    },
    //显示附件列表
    openAccessoryWindow(){
      this.getfindUserByuId()
      this.accessoryWhindow=true
    },
    //点击好友列表
    changeChat(id,index){
      this.friendShow=index
      this.$emit('getFriendList')
    },
    changechatTab(index){
      this.chiatTabShow=index
    },

  //  选择和谁聊天
    changeUserId(item){
      var receiveMsg = document.getElementById("receiveMsg");
      receiveMsg.innerHTML=[]
      this.getMsg(item)
      // this.setScrollHeight()
    },
    /**
     * 设置滚动条高度
     */
    setScrollHeight(){
      let that=this
      setTimeout(()=>{
        that.$refs.chitchatBg.scrollTop = that.$refs.chitchatBg.scrollHeight
      },0)
    },
    // ChatMsg: function(senderId,receiverId,msg,msgId,type,sourceFileReadUrl,postId){
    //   console.log('senderId'+senderId);
    //
    //   this.senderId = senderId;
    //   this.receiverId = receiverId;
    //   this.msg = msg;
    //   this.msgId = msgId;
    //   this.type = type;
    //   this.sourceFileReadUrl = sourceFileReadUrl;
    //   this.postId = postId;
    //   return senderId
    //   return receiverId
    // },
    ChatMsg: function(msgObj){
      return msgObj
    },
    /**构建消息DataContent模型对象
     * @param {Object} action(1.创建连接2.发送消息3.签收消息4.断开连接)
     * @param {Object} chatMsg
     * @param {Object} extand
     */
    DataContent: function(contentObj){
      return contentObj
    },
    getDate(data) {
      var date = new Date(data)
      var Y = date.getFullYear() + '-'
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
      var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
      var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
      var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
      var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
      return Y + M + D + h + m + s
    },
    getMsg(item){
      let that=this
      var data = {};
      window.CHAT = {
        socket:null,
        init:function(){
          //判断是否支持webSocket
          if(window.WebSocket){
            //创建websocket
						CHAT.socket = new WebSocket("ws:exiaozhi.sdzjds.com/wss");
            // CHAT.socket = new WebSocket("ws:192.168.1.128:8888/ws");
            CHAT.socket.onopen = function(){
              let msgObj={
                senderId:that.userInfo.id,
                receiverId:item.chatId
              }
              var chatMsg = that.ChatMsg(msgObj);
              let contentObj={
                action:'1',
                chatMsg:chatMsg,
              }
              var dataContent = that.DataContent(contentObj);
              CHAT.socket.send(JSON.stringify(dataContent));
              that.setScrollHeight()
            },
                CHAT.socket.onclose = function(){
                  var receiveMsg = document.getElementById("receiveMsg");
                  if(receiveMsg){
                    receiveMsg.innerHTML=[]
                  }
                  CHAT.init()
                },
                CHAT.socket.onerror = function(e){
                },
                CHAT.socket.onmessage = function(e){
                  var receiveMsg = document.getElementById("receiveMsg");
                  //嵌入新的内容
                  var da = eval('(' + e.data + ')');
                  var msgId = new Array();
                  if(da.chatMsg!=null){
                    if(da.chatMsg.senderId==item.chatId){
                      var html = receiveMsg.innerHTML;//获取原有内容
                      if(da.chatMsg.type!="img"){
                        receiveMsg.innerHTML =html +'<div style="display: flex;align-items: center;flex-wrap: wrap;justify-content: start;margin-bottom: 20px;">'+'<img style="width: 50px;margin-right: 10px" src="'+item.user.headPortraitUrl+'" />'+'<span style="width: 260px;border-radius: 5px;background: white;padding: 8px;margin-right: 4px; box-shadow: 0px 1px 6px 0px rgba(145, 145, 145, 0.2);">'+da.chatMsg.msg+'</span>'+'<span>'+that.getDate(new Date().getTime())+'</span>' +"&nbsp;&nbsp;"+'<br/>'+'</div>' ;
                      }else{
                        receiveMsg.innerHTML =html +'<div style="display: flex;align-items: center;flex-wrap: wrap;justify-content: start;margin-bottom: 20px;">'+'<img style="width: 50px;margin-right: 10px" src="'+item.user.headPortraitUrl+'" />'+'<span style="width: 260px;border-radius: 5px;background: white;padding: 8px;margin-right: 4px; box-shadow: 0px 1px 6px 0px rgba(145, 145, 145, 0.2);">'+'<a style="color: red" target="_blank" href="'+da.chatMsg.sourceFileReadUrl+'" >查看简历</a>'+'</span>'+'<span>'+that.getDate(new Date().getTime())+'</span>' +"&nbsp;&nbsp;"+'<br/>'+'</div>' ;
                      }
                      msgId[0] = da.chatMsg.msgId;
                      let msgObj={
                        senderId:that.userInfo.id,
                      }
                      var chatMsg = that.ChatMsg(msgObj);
                      let contentObj={
                        action:'3',
                        chatMsg:chatMsg,
                        msgId:JSON.stringify(msgId)
                      }
                      var dataContent =that.DataContent(contentObj);
                      CHAT.socket.send(JSON.stringify(dataContent));
                      that.setScrollHeight()
                    }
                  }else{
                    var index = 0;
                    for(let i=0;i<da.length;i++){
                      if(da[i].signFlag === "NO"){
                        msgId[index] = da[i].id;
                        index++;
                      }
                      data.msgId = da[i].id
                      data.userDel = (that.userInfo.id== da[i].sendUserId)?'S':'R';
                      var html = receiveMsg.innerHTML;//获取原有内容
                      if(da[i].type!="img"){
                        if(that.userInfo.id!== da[i].sendUserId){
                          receiveMsg.innerHTML =html +'<div style="display: flex;align-items: center;flex-wrap: wrap;justify-content: start;margin-bottom: 20px;">'+'<img style="width: 50px;margin-right: 10px" src="'+item.user.headPortraitUrl+'" />'+'<span style="width: 260px;border-radius: 5px;background: white;padding: 8px;margin-right: 4px; box-shadow: 0px 1px 6px 0px rgba(145, 145, 145, 0.2);">'+da[i].msg+'</span>'+'<span>'+that.getDate(da[i].createTime)+'</span>' +"&nbsp;&nbsp;"+'<br/>'+'</div>' ;
                        }else{
                          receiveMsg.innerHTML =html +'<div style="display: flex;align-items: center;flex-wrap: wrap;justify-content: flex-end;margin-bottom: 20px;">'+'<span>'+that.getDate(da[i].createTime)+'</span>' +'<span style="width: 260px;border-radius: 5px;background: white;padding: 8px;margin-left: 4px; box-shadow: 0px 1px 6px 0px rgba(145, 145, 145, 0.2);">'+da[i].msg+'</span>'+"&nbsp;&nbsp;"+'<img style="width: 50px;margin-left: 10px" src="'+that.userInfo.headPortraitUrl+'" />'+'<br/>'+'</div>' ;
                        }
                      }else{
                        if(that.userInfo.id!== da[i].sendUserId){
                          receiveMsg.innerHTML =html +'<div style="display: flex;align-items: center;flex-wrap: wrap;justify-content: start;margin-bottom: 20px;">'+'<img style="width: 50px;margin-right: 10px" src="'+item.user.headPortraitUrl+'" />'+'<span style="width: 260px;border-radius: 5px;background: white;padding: 8px;margin-right: 4px; box-shadow: 0px 1px 6px 0px rgba(145, 145, 145, 0.2);">'+'<a style="color: red" target="_blank" href="'+da[i].sourceFileReadUrl+'" >查看简历</a>'+'</span>'+'<span>'+that.getDate(da[i].createTime)+'</span>' +"&nbsp;&nbsp;"+'<br/>'+'</div>' ;
                        }else{
                          receiveMsg.innerHTML =html +'<div style="display: flex;align-items: center;flex-wrap: wrap;justify-content: flex-end;margin-bottom: 20px;">'+'<span>'+that.getDate(da[i].createTime)+'</span>' +'<span style="width: 260px;border-radius: 5px;background: white;padding: 8px;margin-left: 4px; box-shadow: 0px 1px 6px 0px rgba(145, 145, 145, 0.2);">'+'<a style="color: red" target="_blank" href="'+da[i].sourceFileReadUrl+'" >查看简历</a>'+'</span>'+"&nbsp;&nbsp;"+'<img style="width: 50px;margin-left: 10px" src="'+that.userInfo.headPortraitUrl+'" />'+'<br/>'+'</div>' ;
                        }
                      }
                    }
                    // if(msgId.length>0){
                    //   alert("您有"+msgId.length+"条新消息未读")
                    // }
                    let msgObj={
                      senderId:that.userInfo.id,
                    }
                    var chatMsg = that.ChatMsg(msgObj);
                    let contentObj={
                      action:'3',
                      chatMsg:chatMsg,
                      msgId:JSON.stringify(msgId)
                    }
                    var dataContent = that.DataContent(contentObj);
                    CHAT.socket.send(JSON.stringify(dataContent));
                  }
                  that.setScrollHeight()
                  that.$emit('getFriendList')
                }
          }else{
            console.log("您的浏览器不支持");
          }
        },
        //发送
        chat:function(){
            //获取发送消息框内容
            // var msg = document.getElementById("msg").value;
            //将文本内容发送
            let msgObj={
              senderId:that.userInfo.id,
              receiverId:item.chatId,
              msg:that.sendMsgContent,
              // postId:''
            }
            if(that.userInfo&&that.userInfo.enterprisePersonal==1&&that.postId){
              msgObj.postId=that.postId
            }
            if(that.accessoryId){
              msgObj.type='img'
              msgObj.resourceId=that.accessoryId
            }
            let chatMsg = that.ChatMsg(msgObj);
            let contentObj={
              action:'2',
              chatMsg:chatMsg,
              extand:null
            }
            var dataContent = that.DataContent(contentObj);
            var receiveMsg = document.getElementById("receiveMsg");
            var html = receiveMsg.innerHTML;
            if(that.accessoryId){
              receiveMsg.innerHTML = html + '<div style="display: flex;align-items: center;flex-wrap: wrap;justify-content: flex-end;margin-bottom: 20px;">'+'<span>'+that.getDate(new Date().getTime())+'</span>' +'<span style="width: 260px;border-radius: 5px;background: white;padding: 8px;margin-left: 4px; box-shadow: 0px 1px 6px 0px rgba(145, 145, 145, 0.2);">'+'<a style="color: red" target="_blank" href="'+that.sourceFileReadUrl+'" >查看简历</a>'+'</span>'+"&nbsp;&nbsp;"+'<img style="width: 50px;margin-left: 10px" src="'+that.userInfo.headPortraitUrl+'" />'+'<br/>'+'</div>';
            }else{
              receiveMsg.innerHTML = html + '<div style="display: flex;align-items: center;flex-wrap: wrap;justify-content: flex-end;margin-bottom: 20px;">'+'<span>'+that.getDate(new Date().getTime())+'</span>' +'<span style="width: 260px;border-radius: 5px;background: white;padding: 8px;margin-left: 4px; box-shadow: 0px 1px 6px 0px rgba(145, 145, 145, 0.2);">'+that.sendMsgContent+'</span>'+"&nbsp;&nbsp;"+'<img style="width: 50px;margin-left: 10px" src="'+that.userInfo.headPortraitUrl+'" />'+'<br/>'+'</div>';
            }

//					var wsWebview = plus.webview.getWebviewById("bird-chatlist.html");
//						wsWebview.evalJS("CHAT.chat('"+JSON.stringify(dataContent)+"')");
            CHAT.socket.send(JSON.stringify(dataContent));
            that.sendMsgContent = "";
            that.setScrollHeight()
            that.$emit('getFriendList')

        },
        //断开
        break:function (){
          let contentObj={
            action:'4',
          }
          var dataContent = that.DataContent(contentObj);
          CHAT.socket.send(JSON.stringify(dataContent));
        }
      };
      CHAT.init();
    }
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    var receiveMsg = document.getElementById("receiveMsg");
    if(receiveMsg){
      receiveMsg.innerHTML=[]
    }
    if(this.JobPostDetail){
      this.friendList.forEach((item,index)=>{
        if(item.chatId==this.JobPostDetail.jobPublishPersonId){
          this.friendShow=index
          let obj={
            chatId:this.JobPostDetail.jobPublishPersonId,
            user:{
              headPortraitUrl:this.postDetail.headPortraitUrl?this.postDetail.headPortraitUrl:this.postDetail.jobUser.headPortraitUrl
            }
          }
          this.getMsg(obj)
        }
      })
    }
    if(this.postDetail){
      this.friendList.forEach((item,index)=>{
        if(item.chatId==this.postDetail.uId?this.postDetail.uId:this.postDetail.jobPublishPersonId){
          this.friendShow=index
          let obj={
            chatId:this.postDetail.uId?this.postDetail.uId:this.postDetail.jobPublishPersonId,
            user:{
              headPortraitUrl:this.postDetail.headPortraitUrl?this.postDetail.headPortraitUrl:this.postDetail.jobUser.headPortraitUrl
            }
          }
          this.getMsg(obj)
        }
      })
    }
  },
  computed:{

  },
  watch:{
    windowStage(val,oldVal){
      let that=this
      if(val&&val==true){
        // that.CHAT.break();
      }
    },
    postId(val,oldVal){
      if(val){
        this.postId=val
      }
    },

    postDetail(val,oldVal){
      if(val){
        this.friendList.forEach((item,index)=>{
          if(item.chatId==this.postDetail.uId?this.postDetail.uId:this.postDetail.jobPublishPersonId){
            this.friendShow=index
            let obj={
              chatId:this.postDetail.uId?this.postDetail.uId:this.postDetail.jobPublishPersonId,
              user:{
                headPortraitUrl:this.postDetail.headPortraitUrl
              }
            }
            this.getMsg(obj)
          }
        })
      }
    },
    JobPostDetail(val,oldVal){
      if(val&&this.friendList&&this.friendList.length){
        this.friendList.forEach((item,index)=>{
          if(item.chatId==val.jobPublishPersonId){
            this.friendShow=index
            let obj={
              chatId:val.jobPublishPersonId,
              user:{
                headPortraitUrl:val.jobUser.headPortraitUrl
              }
            }
            this.getMsg(obj)
          }
        })
      }
    }
  }
}
</script>
<style scoped>
@import "../../../../public/css/xzJob.css";
</style>
