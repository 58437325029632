<template>
  <div>
    <div class="accessoryResume">
      <p>附件简历</p>
      <div class="inviteInfoResumeFlex accessoryResumeContent" v-for="(item,index) in jobResources" :key="index">
        <div style="display: flex;align-items: center">
          <img src="images/numberIcon8.png">
          <span class="sourceRawNameWd">{{item.sourceRawName}}</span>
        </div>
        <i class="el-icon-delete" style="cursor: pointer" @click="deleteAdjunctresume(item)"></i>
      </div>
      <el-upload
          class="upload-demo"
          :http-request="uploadFile"
          action="/frontApi/jobUser/other/create"
      >
        <el-button type="primary">上传简历</el-button>
      </el-upload>
    </div>
  </div>
</template>
<script>
import {uploadAccessory, delectAdjunct} from '@/api'
export default {
  components:{
  },
  props: {
    //信息
    jobResources: {
      required: true,
      default() {
        return []
      },
    },
  },
  data(){
    return{
      userInfo:{}
    }
  },
  methods:{
    //删除附件简历
    deleteAdjunctresume(item){
      let data={
        id:item.id
      }
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delectAdjunct(data).then(res=>{
          if(res.code=="0"){
            this.$emit('getfindUserByuId');
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          }else{
            this.$message.error('失败，请重试');
          }
        })

      }).catch(() => {

      });
    },
    //上传附件
    uploadFile(res) {
      var forms = new FormData()
      forms.append('file',res.file)
      forms.append('uId',this.userInfo.id)
      uploadAccessory(forms).then(res=>{
        if(res.code==0){
          this.$message({
            message: '上传成功',
            type: 'success'
          });
          this.$emit('getfindUserByuId')
        }
      })
    },
  },
  mounted() {
    this.userInfo=JSON.parse(localStorage.getItem("userInfo")) ;
  }
}
</script>
<style scoped>
@import "../../../../public/css/inviteInfo.css";
</style>
