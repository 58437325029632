<template>
  <div class="enterPriseIndexBg">
    <div class="enterHeaderPo" >
      <enterpriseHeader @myUserInfo="myUserInfo"></enterpriseHeader>
    </div>
    <div class="enterContent">
      <div class="enterFiexd">
        <p>企业版</p>
        <div class="enterLogo">
          <img :src="userInfo.headPortraitUrl?userInfo.headPortraitUrl:'images/tacitlyAvatar.png'">
          <p>{{userInfo.name}}</p>
        </div>
        <div >
          <p :class="enterTabshow==index?'enterTabActive':''" class="enterTabFont" v-for="(item,index) in enterTabArr" :key="index" @click="changeEnter(index)">{{item.name}}</p>
          <div v-show="enterMore" class="enterSuspension">
            <div  v-for="(item,index) in enterIcon" :key="index" @click="changeEnterIcon(index)" >
              <img :src="item.img">
              <span class="enterSuspensionSpan" :class="entershow==index?'enterInconActive':''">{{item.name}}</span>
            </div>
          </div>
        </div>
      </div>
<!--      中心-->
      <div class="enterContentCenter">
        <postList v-if="enterTabshow==0"></postList>
<!--        发现人才-->
        <findTalents v-if="enterTabshow==1"></findTalents>
<!--        互动沟通-->
        <chitchat v-if="enterTabshow==2" :friendList="friendList" @getFriendList="getFriendList"></chitchat>
<!--        更多工具(公司简介)-->
        <companyIntroduce v-if="enterTabshow==4"></companyIntroduce>
<!--        招聘数据-->
        <inviteData v-if="enterTabshow==5"></inviteData>
<!--        企业用户信息-->
        <companyUserInfo v-if="enterTabshow==6"></companyUserInfo>
      </div>
    </div>
  </div>
</template>
<script>
import enterpriseHeader from './enterprise/header.vue'
import postList from './enterprise/postList.vue'
import findTalents from './enterprise/findTalents.vue'
import companyIntroduce from './enterprise/companyIntroduce.vue'
import companyUserInfo from './enterprise/companyUserInfo.vue'
import inviteData from './enterprise/inviteData.vue'
import chitchat from "@/views/xzJob/components/chitchat";
import {findfriendList} from "@/api";

export default {
    components:{
      enterpriseHeader,
      postList,
      findTalents,
      companyIntroduce,
      chitchat,
      inviteData,
      companyUserInfo
    },
    data(){
      return{
        enterTabArr:[
          {id:1,name:'职位管理'},
          {id:1,name:'发现人才'},
          {id:1,name:'互动沟通'},
          {id:1,name:'企业内训'},
          {id:1,name:'更多工具'},
        ],
        enterTabshow:0,
        enterMore:false,
        enterIcon:[
          {id:1,name:"公司主页",img:'images/enterIcon2.png'},
          {id:2,name:"招聘数据",img:'images/enterIcon1.png'},
          // {id:3,name:"企业管理",img:'images/enterIcon3.png'},
        ],
        friendList:[],
        userInfo:{},
        entershow:0,


      }
    },
    methods:{
    //  获取头部个人信息参数
      myUserInfo(item){
        this.enterTabshow=item
      },
    //  选择更多工具
      changeEnterIcon(index){
        if(index==0){
          this.enterTabshow=4
        }else{
          this.enterTabshow=5
        }
        this.entershow=index
        this.enterMore=false
      },
    //  左侧边栏
      changeEnter(index){
        this.enterTabshow=index;
        if(index==4){
          this.enterMore=!this.enterMore
          this.entershow=0
        }else{
          this.enterMore=false
        }
        if(index==2){
          this.getFriendList()
        }
      },
      //获取好友列表
      getFriendList(){
        let data={
          uId:this.userInfo.id
        }
        findfriendList(data).then(res=>{
          this.friendList=res.data
        })
      },
    },
    mounted() {
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if(this.$route.query.myUserInfo){
        this.enterTabshow=this.$route.query.myUserInfo
      }
      this.myUserInfo()
      this.enterTabshow=0
    },
    watch:{
      enterTabshow(val,oldVal){
        if(val&&val==6){
          this.enterTabshow=6
        }
      }
    }
}
</script>
<style scoped>
@import "../../../public/css/enterpriseIndex.css";
</style>
