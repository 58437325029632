<template>
  <div >
    <div class="enterPriseHeader">
      <div class="enterPriseLogo">
        <img src="images/eLogoSvg.png">
<!--        <span style="cursor: pointer" @click="inviteData">招聘数据</span>-->
      </div>
      <div class="enterWkIMgPostion">
<!--        <div class="enterHeaderSpan">-->
<!--          <span>帮助</span>-->
<!--          <span>面试管理</span>-->
<!--          <span>招聘数据</span>-->
<!--        </div>-->
        <div class="enterWkIMg">
<!--          <img src="images/courseListIcon.png">-->
          <span>{{userInfo.name}}</span>
          <img :src="userInfo.headPortraitUrl">
        </div>
        <div class="userDropDown">
          <div class="userHeaderFlex">
            <img src="images/tacitlyAvatar.png">
            <div>
              <p>企业001号</p>
              <p>山东智捷电子商务有限公司</p>
            </div>
          </div>
          <div class="userInfoLoginFlex" @click="goUserInfo()">
            <i class="el-icon-user"></i>
            <p>个人信息</p>
          </div>
          <div class="userInfoLoginFlex" @click="outLogin">
            <i class="el-icon-user"></i>
            <p>退出登录</p>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  data(){
    return{
      userInfo:{}
    }
  },
  methods:{
  //  去个人信息
    goUserInfo(){
      this.$emit('myUserInfo','6')
      // this.$router.push({path: '/enterpriseIndex', query: {myUserInfo: '6'}})
    },
  //  退出登录
    outLogin(){
      this.$cookie.remove("userToken");
      this.$cookie.remove("studyFrontToken");
      sessionStorage.clear();
      localStorage.clear();
      this.$router.push({path: '/xzjobLogin'})
    }
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
  },
  watch:{
    userInfo(val,oldVal){
      if(val){
        this.userInfo=val
      }
    }
  }
}
</script>
<style scoped>
@import "../../../../public/css/enterpriseIndex.css";
</style>
