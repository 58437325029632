<template>
  <div class="recommendBg">
    <div class="recommendContent">
      <div>
        <div style="display: flex;align-items: center;margin-bottom: 16px">
          <span style="margin-right: 10px">当前位置:</span>
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/xzjob' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item><p>小智job</p></el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div>
          <el-input placeholder="请输入内容" v-model="recommendSearch" class="input-with-select" @change="getfindPosition">
            <el-button slot="append" icon="el-icon-search" @click="getfindPosition"></el-button>
          </el-input>
        </div>
<!--        筛选-->
        <div class="screenRequire">
          <div>
            <span class="selectCityJob">{{cityName}}</span>
            <span style="cursor: pointer" @click="allCity()">全部城市></span>
          </div>
          <div style="display: flex;">
            <span style="cursor: pointer;margin-right: 26px;width: 38px" @click="unlimited">不限</span>
            <p style="cursor: pointer;" >
              <span style="margin-right: 14px" v-for="(item,index) in districtArr" :key="index" :class="districtShow==index?'districtActive':''" @click="getDistrict(item,index)">{{item}}</span>
            </p>
          </div>
          <div class="choiceCondition">
            <div>
              <el-dropdown @command="handleCommand" >
                <span class="el-dropdown-link">
                  薪资要求<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="(item,index) in compensation" :key="index" :command="item.name" >{{item.name}}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div>
              <el-dropdown @command="handleexperience">
                <span class="el-dropdown-link">
                  工作经验<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="(item,index) in experience" :key="index" :command="item.name">{{item.name}}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div>
              <el-dropdown @command="handleeducation">
                <span class="el-dropdown-link">
                  学历要求<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="(item,index) in education" :key="index" :command="item.name">{{item.name}}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
<!--            <div>-->
<!--              <el-dropdown>-->
<!--                <span class="el-dropdown-link">-->
<!--                  职位类型<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
<!--                </span>-->
<!--                <el-dropdown-menu slot="dropdown">-->
<!--                  <el-dropdown-item>黄金糕</el-dropdown-item>-->
<!--                  <el-dropdown-item>狮子头</el-dropdown-item>-->
<!--                </el-dropdown-menu>-->
<!--              </el-dropdown>-->
<!--            </div>-->
<!--            <div>-->
<!--              <el-dropdown>-->
<!--                <span class="el-dropdown-link">-->
<!--                  公司规模<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
<!--                </span>-->
<!--                <el-dropdown-menu slot="dropdown">-->
<!--                  <el-dropdown-item>黄金糕</el-dropdown-item>-->
<!--                </el-dropdown-menu>-->
<!--              </el-dropdown>-->
<!--            </div>-->
          </div>
        </div>
<!--        列表-->
        <div>
          <div class="recommendationList">
            <el-tabs v-model="activeName" @tab-click="cutfindPosition">
              <el-tab-pane label="推荐职位" name="1"></el-tab-pane>
              <el-tab-pane label="最新职位" name="2"></el-tab-pane>
              <el-tab-pane label="热招职位" name="3"></el-tab-pane>
              <el-tab-pane label="校园实习" name="4"></el-tab-pane>
            </el-tabs>
          </div>
          <div class="recommendFelxJob" v-for="(item,index) in postList" :key="index" @click="goJobDetail(item)">
            <div class="recommendEducation">
              <p>{{item.jobTitle}}[{{item.jobCity}}]</p>
              <p>
                <span>{{item.jobMinimumSalary}}-{{item.jobMaximumSalary}}k</span>
                <span>{{item.jobSchool}}</span>
              </p>
            </div>
            <div class="recommendNUmber">
              <p>{{item.companyTitle}}</p>
              <p v-if="item.companyVo">{{item.companyVo.companyType}}   丨{{item.companyVo.companyScale}}人</p>
            </div>
            <div>
              <img v-if="item.companyVo" :src="item.companyVo.companyLogoUrl">
            </div>
          </div>
          <div v-if="!postList.length" style="width: 100%">
            <el-empty :image-size="200"></el-empty>
          </div>
        </div>
<!--        分页-->
        <div style="margin: 30px 0">
          <el-pagination
              class="text_center"
              background
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage"
              :page-size="pagesize"
              layout="total,  prev, pager, next, jumper"
              :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <div>
        <userLogin></userLogin>
<!--        <div class="resumeaccessory">-->
<!--          <div>-->
<!--            <p>附件简历</p>-->
<!--            <div v-for="i in 3" class="sample">-->
<!--              <span>作品集.pdf</span>-->
<!--              <i class="el-icon-delete" style="cursor: pointer"></i>-->
<!--            </div>-->
<!--            <el-button type="primary" style="width: 100%">上传简历</el-button>-->
<!--          </div>-->
<!--          <p>编辑简历</p>-->
<!--        </div>-->
        <accessoryResume :jobResources="userJobResume.jobResources"></accessoryResume>
      </div>
    </div>
    <el-dialog :visible.sync="cityWindowShow" :show-close="true" width="1000px">
      <div>
        <p class="cityBorder">直辖市、省及自治区</p>
        <div style="display: flex;align-items: baseline;" >
          <div class="citySheng" >
            <div v-for="(item,index) in cityList" :key="index">
              <p class="cityShengp" @click="changeCity(item,index)" :class="cityShow==index?'cityActive':''">{{item.name}}</p>
            </div>
          </div>
          <div class="cityShi" >
            <p class="cityShip" :class="cityquShow==index?'cityquShowActive':''" v-for="(item,index) in jobCityArr" :key="index" @click="changeDistrict(item,index)">{{item.name}}</p>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import userLogin from '../components/userLogin.vue'
import {findPosition, findUserByuId} from "@/api";
import {getCityList} from "@/utils/city";
import accessoryResume from "@/views/xzJob/user/accessoryResume";
export default {
  components:{
    userLogin,
    accessoryResume
  },
  data(){
    return{
      recommendSearch:'',
      activeName:'1',
      postList:[],
      currentPage:1,
      pagesize:10,
      total:0,
      compensation:[
        {id:0,name:'不限'},
        {id:1,name:'1K以下'},
        {id:2,name:'1-3'},
        {id:3,name:'3-5'},
        {id:4,name:'5-8'},
        {id:5,name:'8-10'},
        {id:6,name:'10-20'},
      ],
      experience:[
        {id:0,name:'经验不限'},
        {id:1,name:'1年以内'},
        {id:2,name:'1-3年'},
        {id:3,name:'3-5年'},
        {id:4,name:'10年以上'},
      ],
      education:[
        {id:0,name:'学历不限'},
        {id:1,name:'初中及以下'},
        {id:2,name:'中专/中技'},
        {id:3,name:'高中'},
        {id:4,name:'大专'},
        {id:5,name:'本科'},
        {id:6,name:'硕士'},
        {id:7,name:'博士'},
      ],
      compensationArr:[],
      experienceVlaue:'',
      educationVlaue:'',
      cityList:[],
      cityWindowShow:false,
      jobCityArr:[],
      districtArr:[],
      cityName:'济南',
      cityShow:14,
      cityquShow:0,
      districtShow:'-1',
      userJobResume:{},
      userInfo:{}
    }
  },
  methods:{
    //  获取个人简历信息
    getfindUserByuId(){
      let data={
        uId:this.userInfo.id
      }
      findUserByuId(data).then(res=>{
        this.userJobResume=res.data;
      })
    },
    //不限区域
    unlimited(){
      this.districtShow='-1';
      this.getfindPosition()
    },
    cutfindPosition(){
      this.getfindPosition()
    },
    //点击区域,切换区域
    getDistrict(item,index){
      this.getfindPosition(item)
      this.districtShow=index
    },
    //点击城市,显示区
    changeDistrict(item,index){
      this.cityName=item.name
      this.districtArr=item.area;
      this.cityWindowShow=false;
      this.cityquShow=index
      this.getfindPosition()
    },
    //点击省份,显示城市
    changeCity(item,index){
      this.jobCityArr=item.city;
      this.cityShow=index;
      this.cityquShow=0
    },
    //显示全部城市
    allCity(){
      this.cityWindowShow=true;
    },
    //去职位详情
    goJobDetail(item){
      sessionStorage.setItem('JobPostDetail',JSON.stringify(item))
      this.$router.push('jobDetail')
    },
    //薪资要求
    handleCommand(command){
      if(command!=='不限'&&command!=='1K以下'){
        this.compensationArr=command.split('-')
      }
      if(command=='不限'){
        this.compensationArr=[]
      }
      if(command=='1K以下'){
        this.compensationArr=['0','1']
      }
      this.getfindPosition()
    },
    //工作经验
    handleexperience(command){
      if(command!=='经验不限'){
        this.experienceVlaue=command
      }else{
        this.experienceVlaue=''
      }
      this.getfindPosition()
    },
    //学历要求
    handleeducation(command){
      if(command!=='学历不限'){
        this.educationVlaue=command
      }else{
        this.educationVlaue=''
      }
      this.getfindPosition()
    },
    // 分页
    handleCurrentChange: function (currentPage) {
      this.currentPage = currentPage;
      this.getfindPosition();
    },
    //  获取职位推荐列表
    getfindPosition(item){
      let data={
        findType:'recommend',
        page:this.currentPage,
        size:this.pagesize
      }
      if(this.activeName==1){
        data.findType='recommend'
      }else if(this.activeName==2){
        data.findType='newest'
      }else if(this.activeName==3){
        data.findType='hot'
      }else if(this.activeName==4){
        data.findType='school'
      }
      if(this.recommendSearch){
        data.str=this.recommendSearch
      }
      if(this.compensationArr.length){
        data.jobMinimumSalary=this.compensationArr[0];
        data.jobMaximumSalary=this.compensationArr[1];
      }
      if(this.experienceVlaue){
        data.jobExperience=this.experienceVlaue
      }
      if(this.educationVlaue){
        data.jobSchool=this.educationVlaue
      }
      if(item&&this.districtShow!=='-1'){
        data.jobArea=item
      }
      if(this.cityName){
        data.jobCity=this.cityName
      }
      findPosition(data).then(res=>{
        this.postList=res.data.records;
        this.total=JSON.parse(res.data.total);
      })
    },
  },
  mounted() {
    this.recommendSearch=this.$route.query.searchjobPost
    this.userInfo=JSON.parse(localStorage.getItem('userInfo'))
    this.getfindPosition();
    let cityArr=getCityList();
    this.cityList=cityArr;
    this.jobCityArr=cityArr[14].city;
    this.districtArr=cityArr[14].city[0].area
    this.cityName=cityArr[14].city[0].name
    this.getfindUserByuId()

  }
}
</script>
<style scoped>
@import "../../../../public/css/recommendJobList.css";
</style>
