<template>
  <div>
    <div class="recommendationTitle">
      <span></span>
      <span>热门企业</span>
      <span></span>
    </div>
    <div class="hotEnterPriseContentFlex">
      <div class="hotEnterPriseContent" v-for="(item,index) in companyList" :key="index" @click="goCompayDetail(item)">
        <img :src="item.companyLogoUrl" class="jobMrb">
        <p class="jobMrb">{{item.companyTitle}}</p>
        <p class="jobMrb">
          <span style="color: #999999;font-size: 14px">{{item.companyScale}}   丨   {{item.companyType}}</span>
        </p>
        <p>
          <span>{{item.positionCount}}</span>
          <span>个热招职位</span>
        </p>
      </div>
    </div>
    <div class="recommendMore">
      <el-button type="primary" plain style="width: 386px" @click="goHotEnerprise">查看更多</el-button>
    </div>
  </div>
</template>
<script>
import {findCompany} from "@/api";
export default {
  data(){
    return{
      companyList:[]
    }
  },
  methods:{
    //去公司详情
    goCompayDetail(item){
      let routeUrl = this.$router.resolve({
        path: "/companyDetail",
        query:{compayId:item.id}
      });
      sessionStorage.setItem('compayDetail',JSON.stringify(item))
      window.open(routeUrl.href);
    },
    goHotEnerprise(){
      this.$router.push('/hotEnterpriseList')
    },
  //  热门企业列表
    getfindCompany(){
      let data={}
      findCompany(data).then(res=>{
        this.companyList=res.data.records
      })
    }
  },
  mounted() {
    this.getfindCompany()

  }
}
</script>
<style scoped>
@import "../../../../public/css/xzJob.css";
</style>
