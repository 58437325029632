<template>
  <div class="cityInformationBg">
    <div>
      <img src="images/jobInforMation.png"/>
      <div class="cityInformationFont">
        <p>城市大数据</p>
        <p style="margin-bottom: 30px">高校毕业生数量变化· 高校毕业生数量变化 · 应届毕业生行业选择 · 薪资分布 · 求职终端 · 工作地点 · 职位晋升 · 就业市场信息 大数 拷贝
        </p>
        <el-button type="primary" plain @click="goCityNumber">查看详情</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return{

    }
  },
  methods:{
    goCityNumber(){
      this.$router.push('cityNumber')
    }
  }
}
</script>
<style scoped>
@import "../../../../public/css/xzJob.css";
</style>
