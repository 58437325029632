<template>
  <div>
    <div class="numberServeContent">
      <p class="numberServecenter">专业人才培养方案修（制）订项目</p>
      <div class="numberServeFont">
        <p>
          围绕客户在专业人才培养方案修（制）订过程中的相关需求，重点聚焦人才培养面向、职业能力体系及课程体系，提供大数据调研与分析相关服务，
          指导教育链、人才链与产业链、创新链有机衔接。<br>
          大数据技术采集分析全国及客户所在省份行业人才需求情况，实现以区域产业发展需求为导向的人才精准培养；<br>
          人工智能NLP技术对其中岗位职责文本内容进行处理、提取、分析，以职业能力体系推导课程体系、以职业活动构建教学单元；<br>
          大数据技术对接海量课程教材数据，形成结构化专业知识图谱，为专业课程体系建设提供必要参考；<br>
          提供以行业专家、院校专家为支撑的专业人才培养方案修（制）订服务。
        </p>
      </div>
<!--      专业人才-->
      <div class="majorContent">
        <div>
          <div class="majorTalentsFlex">
            <div class="majorTalents">
              <p>专业人才培养方案修(制)订项目</p>
              <p></p>
            </div>
          </div>
          <div class="halvingOutline">
            <p></p>
          </div>
          <div class="mindMap">
            <p></p>
            <p></p>
            <p></p>
            <p></p>
          </div>
          <div class="mindMapContent">
            <div class="majorTalents1" style="right: 89px">
              <p>行业人才需求调研服务</p>
              <p></p>
            </div>
            <div class="majorTalents1" style="right: 45px">
              <p class="color1">专业对应职业岗位能力调研分析服务</p>
              <p class="color1"></p>
            </div>
            <div class="majorTalents1" style="left: 112px">
              <p class="color2">专业课程教材调研分析服务</p>
              <p class="color2"></p>
            </div>
            <div class="majorTalents1" style="left: 162px">
              <p class="color3">专业人才培养方案修(制)订服务</p>
              <p class="color3"></p>
            </div>
          </div>
        </div>
      </div>
      <p class="numberServecenter"> 专业（群）人才培养质量分析项目 </p>
      <div class="numberServeFont">
        <p>
          围绕客户在专业（群）人才培养质量分析过程中的相关需求，以地方产业（链）发展对专业（群）人才的需求及能力要求为评价标准，重点分析产教
          融合大背景下院校的人才培养情况。<br>
          技术形成千万数量级产业大数据资源库，提炼分析人才需求及人才属性信息，创新市场驱动的人才培养质量评价模式；<br>
          大数据技术实现专业（群）在校生职业能力水平检测与评估，实现以“文化素质+职业技能”相融合的人才培养质量评价，有效挖掘专业（群）核心
          竞争力及比较优势；<br>
          持续跟踪调研近三年毕业生样本数据，以毕业生发展评价反哺和持续优化专业（群）建设；<br>
          匹配分析在校生整体职业能力匹配度及毕业生发展情况，展示以区域产业发展需求为导向的专业（群）人才培养质量分析结论。
        </p>
      </div>
      <div class="majorContent">
        <div>
          <div class="majorTalentsFlex">
            <div class="majorTalents">
              <p>专业(群)人才培养质量分析项目部门</p>
              <p></p>
            </div>
          </div>
          <div class="halvingOutline">
            <p></p>
          </div>
          <div class="mindMap">
            <p></p>
            <p></p>
            <p></p>
            <p></p>
          </div>
          <div class="mindMapContent">
            <div class="majorTalents1" style="right: 89px">
              <p>行业人才需求调研服务</p>
              <p></p>
            </div>
            <div class="majorTalents1" style="right: 45px">
              <p class="color1">专业对应职业岗位能力调研服务</p>
              <p class="color1"></p>
            </div>
            <div class="majorTalents1" style="left: 112px">
              <p class="color2">毕业生跟踪调研服务</p>
              <p class="color2"></p>
            </div>
            <div class="majorTalents1" style="left: 162px">
              <p class="color3">专业(群)人才培养质量分析服务</p>
              <p class="color3"></p>
            </div>
          </div>
        </div>
      </div>
      <p class="numberServecenter"> 专业人才培养方案修（制）订项目 </p>
      <div class="numberServeFont">
        <p>
          围绕客户专业（群）建设中的必要需求，以产业链与教育链衔接关系平衡为核心要素，提供相关大数据调研与分析支撑，如：新专业开设调研论证、
          专业（群）建设运行支撑、专业（群）建设质量评价等。<br><br>

          应用大数据分析技术智能采集千万数量级产业数据、企业数据、人才需求数据，构建海量样本数据分析库；<br>
          通过人工智能技术厘清产业（链）-岗位（群）-专业（群）供需匹配对应关系，深度提炼人才能力标准，有效提升专业群建设与产业链发展的契合度，
          精准定位专业群整体发展与人才培养方向；<br>
          依托数据平台开展专业（群）在校生及近三年毕业生数据采集工作，智能分析专业（群）整体人才职业能力水平及毕业生发展现状，客观评价专业
          （群）建设定位与建设成效，指导专业（群）动态优化调整与可持续发展。
        </p>
      </div>
      <div class="majorContent">
        <div>
          <div class="majorTalentsFlex">
            <div class="majorTalents">
              <p>专业(群)人才培养质量分析项目部门</p>
              <p></p>
            </div>
          </div>
          <div class="halvingOutline">
            <p></p>
          </div>
          <div class="mindMap1">
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
          </div>
          <div class="mindMapContent">
            <div class="majorTalents2" style="right: 64px">
              <p>行业人才需求调研服务</p>
              <p></p>
            </div>
            <div class="majorTalents2" style="right: 45px">
              <p class="color1">专业(群)对应职业岗位(群)能力调研分析服务</p>
              <p class="color1"></p>
            </div>
            <div class="majorTalents2" style="right: 28px">
              <p class="color2">专业调研分析服务</p>
              <p class="color2"></p>
            </div>
            <div class="majorTalents2" style="left:24px">
              <p class="color3">新专业开设调研论证服务</p>
              <p class="color3"></p>
            </div>
            <div class="majorTalents2" style="left: 42px">
              <p class="color4">专业职业能力分析服务</p>
              <p class="color4"></p>
            </div>
            <div class="majorTalents2" style="left: 60px">
              <p class="color5">毕业生跟踪调研服务</p>
              <p class="color5"></p>
            </div>
            <div class="majorTalents2" style="left: 78px">
              <p class="color6">专业(群)动态发展评价服务</p>
              <p class="color6"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default{
    data(){
      return{

      }
    },
    methods:{

    }
  }
</script>
<style scoped>
@import "../../../public/css/bigNumber.css";
</style>
