<template>
  <div class="companyWalfareBg">
    <div>
      <p style="font-size: 18px">公司福利</p>
      <p class="companyIns">标准时间和公司福利仅为求职者参考，不代表公司所有职位状况</p>
      <el-form ref="form" label-width="80px">
        <el-form-item label="工作时间">
          <el-time-select
              placeholder="起始时间"
              v-model="startTime"
              :picker-options="{
            start: '08:00',
            step: '00:15',
            end: '21:30'
          }">
          </el-time-select>
          <el-time-select
              style="margin-left: 12px"
              placeholder="结束时间"
              v-model="stopTime"
              :picker-options="{
            start: '08:00',
            step: '00:15',
            end: '21:30',
            minTime: startTime
          }">
          </el-time-select>
        </el-form-item>
        <el-form-item label="加班情况">
          <el-radio v-model="extraWork" label="不加班" border>不加班</el-radio>
          <el-radio v-model="extraWork" label="偶尔加班" border>偶尔加班</el-radio>
          <el-radio v-model="extraWork" label="经常加班" border>经常加班</el-radio>
<!--          <div style="display: flex">-->
<!--            <p class="dutyBtn" v-for="(item,index) in companyaOvertime" :key="index">{{item.name}}</p>-->
<!--          </div>-->
        </el-form-item>
        <el-form-item label="休息时间">
          <el-radio v-model="outTime" label="单休" border>单休</el-radio>
          <el-radio v-model="outTime" label="双休" border>双休</el-radio>
          <el-radio v-model="outTime" label="大小周" border>大小周</el-radio>
          <el-radio v-model="outTime" label="排班轮休" border>排班轮休</el-radio>
<!--          <div style="display: flex">-->
<!--            <p class="dutyBtn" v-for="(item,index) in companyaResttime" :key="index">{{item.name}}</p>-->
<!--          </div>-->
        </el-form-item>
        <el-form-item>
          <div class="enterpriseFlex" >
            <el-checkbox-group v-model="welfar" style="display: flex;flex-wrap: wrap">
              <el-checkbox v-for="(item,index) in welfareArr" :key="item.name" :label="item.name" class="welfarFlex">
                <div style="display: flex;align-items: center">
                  <div style="margin-right: 10px;height: 30px">
                    <img :src="item.img">
                  </div>
                  <div>
                    <p style="height: 26px;line-height: 26px">{{item.name}}</p>
                    <p class="welfareFont">{{item.content}}</p>
                  </div>
                </div>
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </el-form-item>
        <el-form-item>
          <div style="display: flex;justify-content: flex-end">
            <el-button type="primary" @click="submit">保存</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import {updateCompany} from "@/api";

export default {
  props:{
    companyInfo:{}
  },
  components: {
  },
  data(){
    return{
      userInfo:{},
      welfar:[],
      extraWork:'不加班',
      outTime:'双休',
      startTime: '',
      stopTime: '',
      // companyaOvertime:[
      //   {id:1,name:'不加班'},
      //   {id:2,name:'偶尔加班'},
      //   {id:3,name:'弹性工作'},
      // ],
      // companyaResttime:[
      //   {id:1,name:'双休'},
      //   {id:2,name:'单休'},
      //   {id:3,name:'大小周'},
      //   {id:4,name:'排班轮休'},
      // ],
      welfareArr:[
        {id:1,name:'五险一金',content:'缴纳养老保险、医疗保险、失业保险、工伤保险、生育保险和住房公积金 。',img:'images/welfareIcon1.png',},
        {id:2,name:'带薪年假',content:'为员工提供带薪年休假',img:'images/welfareIcon2.png',},
        {id:3,name:'员工旅游',content:'组织员工旅游活动',img:'images/welfareIcon3.png',},
      ]

    }
  },
  methods:{
  //  保存
    submit(){
      let data={
        id:this.userInfo.companyId,
        startTime:this.startTime,
        stopTime:this.stopTime,
        extraWork:this.extraWork,
        outTime:this.outTime
      }
      let annual=this.welfar.includes('带薪年假')
      let tour=this.welfar.includes('员工旅游')
      let social=this.welfar.includes('五险一金')
      if(social==true){
        data.social='1'
      }else{
        data.social='0'
      }
      if(annual==true){
        data.annual='1'
      }else{
        data.annual='0'
      }
      if(tour==true){
        data.tour='1'
      }else{
        data.tour='0'
      }
      updateCompany(data).then(res=>{
        if(res.code=="0"){
          this.$message({
            message: '操作成功',
            type: 'success'
          });
        }else{
          this.$message.error('失败，请重试');
        }
      })
    }

  },
  mounted(){
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))

  },
  watch:{
    companyInfo(val,oldVal){
      this.companyInfo=val
      this.startTime=val.startTime
      this.stopTime=val.stopTime
      this.extraWork=val.extraWork
      this.outTime=val.outTime
      if(val.annual==1){
        this.welfar.push('带薪年假')
      }
      if(val.social==1){
        this.welfar.push('五险一金')
      }
      if(val.tour==1){
        this.welfar.push('员工旅游')
      }
    }
  }
}
</script>
<style scoped>
@import "../../../../public/css/enterpriseIndex.css";
</style>



