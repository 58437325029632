<template>
  <div>
    <div class="companyBG">
      <div>
        <p style="margin-bottom: 12px">编辑公司信息</p>
<!--        <p>公司完善状态：60%</p>-->
        <div class="companyIncduct">
          <p class="companyIncductp" v-for="(item,index) in companyArr" :key="index" :class="companyShow==index?'companyActive':''" @click="changeTab(index)">{{item.name}}</p>
        </div>
      </div>
      <div >
<!--        公司基本信息-->
        <companyInfo v-if="companyShow==0" ></companyInfo>
<!--        公司介绍-->
        <companyRecommend v-if="companyShow==1" :companyInfo="companyInfo"></companyRecommend>
<!--        公司福利-->
        <comanyWelfare v-if="companyShow==2" :companyInfo="companyInfo"></comanyWelfare>
<!--        公司相册-->
        <companyPhoto v-if="companyShow==3" :companyInfo="companyInfo"></companyPhoto>
<!--        公司视频-->
        <companyVideo v-if="companyShow==4" :companyInfo="companyInfo"></companyVideo>
      </div>
    </div>
  </div>
</template>
<script>
import companyInfo from './companyInfo.vue'
import  companyRecommend from './companyRecommend.vue'
import  comanyWelfare from './comanyWelfare.vue'
import  companyPhoto from './companyPhoto.vue'
import  companyVideo from './companyVideo.vue'
import {selectCompanyInfo} from "@/api";
export default {
  components: {
    companyInfo,
    companyRecommend,
    comanyWelfare,
    companyPhoto,
    companyVideo
  },
  data(){
    return{
      companyArr:[
        {id:1,name:'公司基本信息'},
        {id:2,name:'公司介绍'},
        {id:3,name:'公司福利'},
        {id:4,name:'公司相册'},
        {id:5,name:'公司视频'},
      ],
      companyShow:0,
      userInfo:{},
      companyInfo:{}
    }
  },
  methods:{
    //切换tab
    changeTab(index){
      this.companyShow=index
      this.getComayInfo()
    },
    //查看公司信息
    getComayInfo() {
      let data = {
        bindJobUserId:this.userInfo.id
      }
      selectCompanyInfo(data).then(res => {
        if(res.code==0&&res.data.length){
          this.companyInfo=res.data[0]
        }

      })
    },
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))

  }
}
</script>
<style scoped>
@import "../../../../public/css/enterpriseIndex.css";
</style>
