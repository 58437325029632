<template>
  <div>
    <div class="infoDetail">
      <img src="images/xzjobBanner.png" style="width: 100%">
      <div class="infoDetailContent">
        <div style="margin-top: 20px">
          <el-card class="infoHtml">
            <div class="messageTitle">
              <h1>{{newsDetail.headline}}</h1>
              <p>
                <span style="margin-right: 30px">发布日期:{{newsDetail.createTime}}</span>
              </p>
            </div>
            <div style="margin-top: 20px">
              <p v-html="newsDetail.article"></p>
            </div>
          </el-card>
          <div style="width: 342px">
            <el-card>
              <!--      用户登录-->
              <userLogin></userLogin>
            </el-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import userLogin from '../components/userLogin.vue'
  export default {
    components:{
      userLogin
    },
    data(){
      return{
        newsDetail:{}
      }
    },
    methods:{

    },
    mounted() {
      this.newsDetail=JSON.parse(sessionStorage.getItem('xzJobNews'))
    }
  }
</script>
<style>
.infoDetail{
  padding-bottom: 100px;
  background: #F7F7F7;
}
.infoDetailContent{
  width: 1200px;
  margin: 0 auto;
}
.infoHtml{
  width: 71%;
  margin-right: 10px;
}
.infoDetailContent>div{
  display: flex;
}
.messageTitle p{
  color: #999999;
  font-size: 12px;
}
.messageTitle h1{
  font-size: 22px;
  margin-bottom: 20px;
  white-space: normal;
}
</style>
