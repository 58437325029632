<template>
  <div class="superiorityBg">
    <template v-if="advantageShow">
      <div class="superiority">
        <div class="inviteInfoResumeFlex" style="justify-content: space-between;width: 100%">
          <div class="superiorityDiv">
            <span></span>
            <span>个人优势</span>
          </div>
        </div>
      </div>
      <div style="display: flex;justify-content: space-between;height: 50px" @mouseout="mouseLeceAvatar" @mousemove="mouseoverAvatar">
        <div style="width: 90%"  v-html="jobUser.personalAdvantage"></div>
        <div class="compile" id="advantageBack" style="display:none" @click="changeAdvantage">
          <i class="el-icon-edit"></i>
          <span>编辑</span>
        </div>
      </div>
    </template>
<!--    富文本内容-->
    <div v-show="advantageShow==false" style="margin-top: 20px">
      <div id="wangeditor">
        <div ref="editorElem"></div>
      </div>
      <div style="display: flex;justify-content: flex-end;margin-top: 10px">
        <el-button @click="cancelAdvantage()">取消</el-button>
        <el-button type="primary" @click="publishiAdvantage()">完成</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import E from "wangeditor";
import {updateUserById} from "@/api";

export default {
  props: {
    //用户信息
    jobUser: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
  },
  components:{
  },
  data(){
    return{
      editorContent:'',
      advantageShow:true
    }
  },
  methods:{
    //  进入
    mouseoverAvatar(){
      var advantageBack = document.getElementById('advantageBack');
      advantageBack.style.display = "block";
    },
    // 离开
    mouseLeceAvatar(){
      var advantageBack =document.getElementById('advantageBack');
      advantageBack.style.display = "none";
    },
  //  完成
    publishiAdvantage(){
      let data={
        id:this.jobUser.id,
        uId:this.jobUser.uId,
        personalAdvantage:this.editorContent,
      }
      updateUserById(data).then(res=>{
        if(res.code==0){
          this.$emit('getfindUserByuId')
          this.advantageShow=true;
          this.$message({
            message: "修改成功",
            type: "success",
          });
        }
      })
    },
    //取消
    cancelAdvantage(){
      this.advantageShow=true
    },
    //显示富文本
    changeAdvantage(){
      this.advantageShow=false
    }
  },
  mounted() {
    let editor = new E(this.$refs.editorElem);//获取组件并构造编辑器
    editor.customConfig = editor.customConfig ? editor.customConfig : editor.config
    editor.customConfig.onchange = (html) => {
      this.editorContent = html
    }
    editor.create()
  }
}
</script>
<style scoped>
@import "../../../../public/css/inviteInfo.css";
</style>
