<template>
  <div>
    <div>
      <div class="askWork">
        <div class="tjfx">
          <p> 专业培养能力要求</p>
          <div class="numberRadius">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div style="">
          <div id="main" style="width: 100%;height:310px;display: flex;justify-content: center"></div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
export default{
  data(){
    return{
    }
  },
  methods:{
   getOcupation(){
     var chartDom = document.getElementById('main');
     var myChart = echarts.init(chartDom);
     var option;

     option = {
       title: {
         text: ''
       },
       legend: {
         // data: ['Allocated Budget', 'Actual Spending'],
         // itemWidth: 20,
         // // 图例标记的图形宽度。[ default: 25 ]
         // itemHeight: 20,
       },
       radar: {
         // shape: 'circle',
         center: ['50%', '50%'],
         indicator: [
           { name: '主动学习', max: 6500 },
           { name: '契约精神', max: 16000 },
           { name: '沟通合作', max: 30000 },
           { name: '担当意识', max: 38000 },
           { name: '创新意识', max: 52000 },
           { name: '准守规范', max: 25000 }
         ],
         radius:100,
         name: {
           textStyle: {
             color: 'white' // 文字颜色
           }
         },
       },
       series: [
         {
           name: '',
           type: 'radar',
           data: [
             {
               value: [4200, 3000, 20000, 35000, 50000, 18000],
               name: ''
             },
             {
               value: [5000, 14000, 28000, 26000, 42000, 21000],
               name: ''
             }
           ],


         }
       ]
     };

     option && myChart.setOption(option);

   }
  },
  mounted() {
    this.getOcupation()
  },
  computed: {
  },
}
</script>
<style scoped>
@import "../../../public/css/bigNumber.css";
</style>
