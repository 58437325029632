<template>
  <div>
    <p style="font-size: 18px">公司视频</p>
    <p class="companyIns">您可以上传公司相关视频，内容可以是企业宣传片、环境介绍、产品介绍、活动记录等等，支持内容时长限制为最长10分钟</p>
    <div>
<!--      <custom-upload  v-model="headPortraitUrl" bizType='companyVideo' aliVideoOssData="0">-->
<!--        -->
<!--      </custom-upload>-->
<!--      <el-button icon="el-icon-upload2" type="primary" @click="uploadFile">点击上传</el-button>-->
      <video width="320" height="240" controls v-if="videoUrl">
        <source :src="videoUrl" type="video/mp4">
      </video>
      <el-upload
          v-if="!videoUrl"
          class="upload-demo"
          :http-request="uploadFile"
          action="/frontApi/baseFile/upload"
      >
        <el-button icon="el-icon-upload2" type="primary" size="small">点击上传</el-button>
      </el-upload>

    </div>
    <div style="display: flex;justify-content: flex-end">
      <el-button type="primary" v-if="videoUrl" @click="updateCompany">修改</el-button>
      <el-button type="primary" @click="submitCompany" v-if="!videoUrl">保存</el-button>
    </div>
  </div>
</template>
<script>
import {editUserBaseInfo, uploadUserAvatar} from "@/api/user";
import CustomUpload from "@/components/custom-upload";
import {updateCompany} from "@/api";

export default {
  components: {
    CustomUpload,
  },
  props:{
    companyInfo:{}
  },
  data(){
    return{
      videoUrl:'',
      userInfo:{}
    }
  },
  methods:{
    uploadFile(fileInfo){
      let form = new FormData();
      form.append("file", fileInfo.file);
      form.append("bizType", "companyVideo");
      //aliVideoOssData
      form.append("aliVideoOssData", "0");
      uploadUserAvatar(form).then(res=>{
        if(res.code=="0"&&res.data){
          this.videoUrl = res.data.url
        }
      })
    },
  //  保存
    submitCompany(){
      updateCompany({id:this.userInfo.companyId,videoUrl:this.videoUrl}).then(res=>{
        if(res.code=="0"){
          this.$message({
            message: '保存成功',
            type: 'success'
          });
        }else{
          this.$message.error('失败，请重试');
        }
      })
    },
    //修改
    updateCompany(){
      this.videoUrl=''
    }

  },
  mounted(){
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))

  },
  watch:{
    companyInfo(val,oldVal){
      this.companyInfo=val
      this.videoUrl=val.videoUrl
    }
  }
}
</script>
<style scoped>
@import "../../../../public/css/enterpriseIndex.css";
</style>



