<template>
  <div>
    <template v-if="positionShow">
      <div class="superiority">
        <div class="inviteInfoResumeFlex" style="justify-content: space-between;width: 100%">
          <div class="superiorityDiv">
            <span></span>
            <span>期望职位</span>
          </div>
          <div class="compile" @click="addPOsition">
            <i class="el-icon-circle-plus-outline"></i>
            <span>添加</span>
          </div>
        </div>
      </div>
      <div v-for="(item,i) in userPosts.slice(0, 3)" :key="i" class="inviteInfoResumeFlex expectedContent" @mousemove="changePosition(i)" @mouseout="mouseLeavePost">
        <div class="inviteInfoResumeFlex">
          <img src="images/numberIcon3.png">
          <p style="margin-left: 12px" v-if="item.salaryMoneyArr&&item.salaryMoneyArr.length">{{item.positionDesired}} | {{item.workCity}} | {{item.salaryMoneyArr[0]}}K-{{item.salaryMoneyArr[1]}}K</p>
        </div>
        <div class="expectedFlex" v-show="item.isShow==true">
          <p class="expectedFlex compile expectedMr" @click="compilePost(item)">
            <i class="el-icon-edit"></i>
            <span>编辑</span>
          </p>
          <p class="expectedFlex compile expectedMr" @click="deletecompilePost(item)">
            <i class="el-icon-delete" ></i>
            <span>删除</span>
          </p>
        </div>
      </div>
    </template>
    <div class="applyType" v-if="positionShow==false">
      <p style="font-size: 18px;margin: 20px 0">添加期望职位</p>
      <el-form :inline="true" :model="formInline"   label-width="100px" class="demo-ruleForm">
        <el-form-item label="求职类型" >
          <el-radio-group v-model="formInline.type">
            <el-radio label="1">全职</el-radio>
            <el-radio label="0">兼职</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="工作城市" >
          <el-input v-model="formInline.cityName" @focus="allCity"></el-input>
        </el-form-item>
        <el-form-item label="期望职位" >
          <el-input v-model="formInline.postName" @focus="changePost"></el-input>
        </el-form-item>
        <el-form-item label="期望行业" >
          <el-input v-model="formInline.industryName" @focus="changeIndustry"></el-input>
        </el-form-item>
        <el-form-item label="薪资要求" >

          <div class="compensationWd">
            <el-input v-model="formInline.jobMinimumSalary" type="number" oninput="if(value){value=value.replace(/[^\d]/g,'')} if(value<=0){value=''} if(value>20){value=20}"></el-input><span style="margin-left: 10px">K</span>
              <span style="display: inline-block;margin: 0 14px">-</span>
            <el-input v-model="formInline.jobMaximumSalary" type="number" oninput="if(value){value=value.replace(/[^\d]/g,'')} if(value<=0){value=''} if(value>20){value=20}"></el-input><span style="margin-left: 10px">K</span>
          </div>
        </el-form-item>
        <el-form-item>
          <div style="margin-left:172px;width: 100%">
            <el-button @click="clearPOst">取消</el-button>
            <el-button type="primary" @click="addPublishPositon">完成</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
<!--    期望职位弹窗-->
    <el-dialog :visible.sync="postWindows" :show-close="true" >
      <div style="min-height: 300px">
        <el-tabs :tab-position="tabPosition" >
          <el-tab-pane v-for="(item,index) in postListArr" :key="index" :label="item.name">
            <div class="postListArrFelx">
              <p class="postListArrFelxp" :class="postShow==index1?'postActive':''" v-for="(item1,index1) in item.children" :key="index1" @click="choosePost(item1,index1)">{{item1.name}}</p>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-dialog>
<!--    期望行业弹窗-->
    <el-dialog :visible.sync="industryWindows" :show-close="true">
      <div>
        <el-tabs :tab-position="tabPosition" >
          <el-tab-pane v-for="(item,index) in industryLists" :key="index" :label="item.name" >
            <div class="postListArrFelx">
              <p class="postListArrFelxp" :class="industryShow==index1?'postActive':''" v-for="(item1,index1) in item.children" :key="index1" @click="chooseIndustry(item1,index1)">{{item1.name}}</p>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-dialog>
<!--    所有城市-->
    <el-dialog :visible.sync="cityWindowShow" :show-close="true" width="1000px">
      <div>
        <p class="cityBorder">直辖市、省及自治区</p>
        <div style="display: flex;align-items: baseline;" >
          <div class="citySheng" >
            <div v-for="(item,index) in cityList" :key="index">
              <p class="cityShengp" @click="changeCity(item,index)" :class="cityShow==index?'cityActive':''">{{item.name}}</p>
            </div>
          </div>
          <div class="cityShi" >
            <p class="cityShip" :class="cityquShow==index?'cityquShowActive':''" v-for="(item,index) in jobCityArr" :key="index" @click="changeDistrict(item,index)">{{item.name}}</p>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {industryList, postList,updateUserPost} from "@/api";
import {getCityList} from "@/utils/city";
export default {
  props: {
    //用户信息
    userPosts: {
      required: true,
      default() {
        return []
      },
    },
  },
  components:{
  },
  data(){
    return{
      userInfo:{},
      cityquShow:0,
      jobCityArr:[],
      cityShow:0,
      cityWindowShow:false,
      cityList:[],
      industryShow:'-1',
      industryLists:[],
      postShow:'-1',
      postListArr:[],
      tabPosition: 'left',
      postWindows:false,
      industryWindows:false,
      positionShow:true,
      formInline: {
        type: '',
        cityName:'',
        postName:'',
        industryName:'',
        jobMinimumSalary:'',
        jobMaximumSalary:''
      },
      compileShow:false,
      compileId:''
    }
  },
  methods:{
    //删除职位
    deletecompilePost(item){
      this.$emit('postId',item.id)
      this.$emit('delectEducationInfo')
    },
    //编辑职位
    compilePost(item){
      this.getPOstList();
      this.getindustryList();
      this.compileId=item.id
      this.compileShow=true
      this.positionShow=false;
      this.formInline.postName=item.positionDesired;
      this.formInline.cityName=item.workCity
      this.formInline.type=item.postType.toString()
      this.formInline.industryName=item.desiredIndustry
      this.formInline.jobMinimumSalary=item.salaryMoneyArr[0]
      this.formInline.jobMaximumSalary=item.salaryMoneyArr[1]
    },
    //点击城市,显示区
    changeDistrict(item,index){
      this.formInline.cityName=item.name
      this.districtArr=item.area;
      this.cityWindowShow=false;
      this.cityquShow=index
    },
    //点击省份,显示城市
    changeCity(item,index){
      this.jobCityArr=item.city;
      this.cityShow=index;
      this.cityquShow=0
    },
    //显示全部城市
    allCity(){
      this.cityWindowShow=true;
    },
    //选择行业
    chooseIndustry(item,index){
      this.industryWindows=false;
      this.formInline.industryName=item.name;
      this.industryShow=index
    },
    //选择职位
    choosePost(item,index){
      this.postWindows=false;
      this.formInline.postName=item.name;
      this.postShow=index
    },
    //进入
    changePosition(index){
      this.userPosts.forEach((item,index2)=>{
        if(index==index2){
          item.isShow=true
        }else{
          item.isShow=false
        }
      })
    },
  //  离开
    mouseLeavePost(){
      this.userPosts.forEach(item=>{
        item.isShow=false
      })
    },
  //  添加
    addPOsition(){
      this.positionShow=false;
      this.getPOstList();
      this.getindustryList();
      this.formInline={}
    },
    //获取职位列表
    getPOstList(){
      postList().then(res=>{
        this.postListArr=res.data
      })
    },
    //  获取行业领域
    getindustryList(){
      industryList().then(res=>{
        this.industryLists=res.data;
      })
    },
    //取消
    clearPOst(){
      this.positionShow=true;
    },
    //完成编辑
    addPublishPositon(){
      let data={
        post:{
          uId:this.userInfo.id,
          workCity:this.formInline.cityName,
          positionDesired:this.formInline.postName,
          desiredIndustry:this.formInline.industryName,
          salaryMoney:''
        }
      }
      let imumSalary=[]
      imumSalary[0]=this.formInline.jobMinimumSalary
      imumSalary[1]=this.formInline.jobMaximumSalary
      data.post.salaryMoney=imumSalary.join(',')
      data.post.postType=this.formInline.type
      if(this.compileShow){
        data.post.id=this.compileId
      }
      updateUserPost(data).then(res=>{
        if(res.code==0){
          this.positionShow=true;
          this.$emit('getfindUserByuId')
          if(this.compileShow){
            this.$message({
              message: "修改成功",
              type: "success",
            });
          }else{
            this.$message({
              message: "添加成功",
              type: "success",
            });
          }
        }
      })
    },
    //期望职位
    changePost(){
      this.postWindows=true
    },
  //  期望行业
    changeIndustry(){
      this.industryWindows=true
    }
  },
  mounted() {
    let cityArr=getCityList();
    this.cityList=cityArr;
    this.jobCityArr=cityArr[0].city;
    this.districtArr=cityArr[0].city[0].area
    this.cityName=cityArr[0].city[0].name;
    this.userInfo=JSON.parse(localStorage.getItem("userInfo")) ;
  },
  watch:{
    userPosts(val,oldVal){
      if(val){
        val.forEach((item,index)=>{
          item.salaryMoneyArr=item.salaryMoney.split(',');
          this.$set(val[index],  "isShow",false);
        })
        this.userPosts=val
      }
    }
  }

}
</script>
<style scoped>
@import "../../../../public/css/inviteInfo.css";
</style>
