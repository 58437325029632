<template>
  <div class="bigNumberBackground">
    <div>
<!--      <img src="images/bigNumberBg.png">-->
      <div class="bumberStatFlex">
        <el-row :gutter="20">
  <!--        岗位分析-->
          <el-col :span="6">
            <div class="grid-content bg-purple">
  <!--            岗位数据统计分析-->
              <jobData :cityValue="cityValue"></jobData>
  <!--            行业/薪资-->
              <profession :cityValue="cityValue"></profession>
  <!--            经验/薪资-->
              <experience :cityValue="cityValue"></experience>
            </div>
          </el-col>
  <!--        地图-->
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <div class="mapChartsBorder">
                <div>
                  <el-select v-model="cityValue" placeholder="请选择"  >
                    <el-option
                        v-for="item in cityOptions"
                        :key="item.label"
                        :label="item.label"
                        :value="item.label">
                    </el-option>
                  </el-select>

                </div>
<!--                地图-->
                <mapData></mapData>
              </div>
              <div style="display: flex;justify-content: space-between;margin-top: 20px">
                <occupationPost class="capacityWidth"></occupationPost>
                <occupation class="capacityWidth"></occupation>
              </div>
            </div>
          </el-col>
  <!--        时间-->
          <el-col :span="6">
            <div class="grid-content bg-purple">
  <!--            时间-->
              <div  class="bigNumberTime">
                <p>{{nowTime}}</p>
                <div>
                  <p>{{nowDate}}</p>
                  <p style="font-size: 16px">{{nowWeek}}</p>
                </div>
              </div>
  <!--            柱状图能力素质-->
              <quality :cityValue="cityValue"></quality>
  <!--            学历要求饼图-->
              <education :cityValue="cityValue"></education>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import profession from './profession.vue'
import jobData from './jobData.vue'
import mapData from './mapData.vue'
import occupation from './occupation.vue'
import experience from './experience.vue'
import quality from './quality.vue'
import education from './education.vue'
import occupationPost from './occupationPost.vue'
export default{
  data(){
    return{
      timer: null,
      nowWeek: '',
      nowDate: '',
      nowTime: '',
      cityValue:'全国',
      cityOptions:[
      {
        label: '全国'
      }, {
        label: '山东'
      }, {
        label: '北京'
      }, {
        label: '新疆'
      }, {
        label: '黑龙江'
      }, {
        label: '内蒙古'
      },{
          label: '河北'
      }, {
          label: '山西'
      }, {
          label: '辽宁'
      }, {
          label: '吉林'
      }, {
          label: '江苏'
      },{
          label: '浙江'
      }, {
          label: '安徽'
      },{
          label: '台湾'
      }, {
          label: '江西'
      },{
          label: '河南'
      }, {
          label: '湖北'
      }, {
          label: '湖南'
     }, {
          label: '广东'
        }, {
          label: '海南'
        },{
          label: '四川'
        }, {
          label: '贵州'
        }, {
          label: '云南'
        }, {
          label: '陕西'
        }, {
          label: '甘肃'
        },{
          label: '青海'
        }, {
          label: '天津'
        }, {
          label: '重庆'
        }, {
          label: '上海'
        }, {
          label: '广西'
        },{
          label: '西藏'
        },
      ]
    }
  },
  components:{
    profession,
    jobData,
    mapData,
    occupation,
    experience,
    quality,
    education,
    occupationPost
  },
  methods:{
    //时间
    setNowTimes () {
      //获取当前时间
      let myDate = new Date()
      let wk = myDate.getDay()
      let yy = String(myDate.getFullYear())
      let mm = myDate.getMonth() + 1
      let dd = String(myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate())
      let hou = String(myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours())
      let min = String(myDate.getMinutes() < 10 ? '0' + myDate.getMinutes() : myDate.getMinutes())
      let sec = String(myDate.getSeconds() < 10 ? '0' + myDate.getSeconds() : myDate.getSeconds())
      let weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
      let week = weeks[wk]
      this.nowDate = yy + '-' + mm + '-' + dd
      this.nowTime = hou + ':' + min + ':' + sec
      this.nowWeek = week
    },
  },
  mounted() {
    this.timer = setInterval(() => {
      this.setNowTimes()
    }, 1000)
  },
  watch:{
  }
}
</script>
<style scoped>
@import "../../../public/css/bigNumber.css";
</style>
