<template>
  <div class="jobStatistics1">
    <div class="tjfx">
      <p>行业/薪资</p>
      <div class="numberRadius">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <div>
      <div class="compensation">
        <span>热门行业</span>
        <span>平均薪资</span>
      </div>
      <vue-seamless-scroll :data="hotIndustry" id="moocBox" :class-option="classOption">
        <ul >
          <li v-for="(item,index) in hotIndustry" :key="index" style="padding: 0 20px">
            <span >{{item.name}}</span>
            <span>{{item.number}}</span>
          </li>
        </ul>
      </vue-seamless-scroll>
    </div>
  </div>
</template>
<script>
import {getHotIndustry} from "@/api";
import vueSeamlessScroll from 'vue-seamless-scroll'
export default{
  components: { //组件
    vueSeamlessScroll
  },
  props:{
    cityValue:''
  },
  data(){
    return{
      hotIndustry:[],
      province:''

    }
  },
  methods:{
  },
  mounted() {
  },
  watch:{
    cityValue(val,oldVal){
      if(val){
        this.hotIndustry=[];
        if(val!=='全国'){
          this.province=val;
          // this.classOption();
        }else{
          this.province='';
          // this.classOption();
        }
      }
    }
  },
  computed:{
    classOption () {
      let data={
        province:this.province
      }
      getHotIndustry(data).then(res=>{
        if(res.code==0){
          this.hotIndustry=res.data
        }else{
          this.$message.error(res.msg)
        }
      })
      return {
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    }
  }
}
</script>
<style scoped>
@import "../../../public/css/bigNumber.css";
.seamless-warp{
  width: 100%;
  height: 200px;
  overflow: hidden;
}
</style>
