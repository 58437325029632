<template>
  <div>
    <div style="margin-bottom: 20px">
      <el-select v-model="findValue" placeholder="请选择" clearable  @change="getfindPostUser">
        <el-option
            v-for="item in postList"
            :key="item.id"
            :label="item.jobTitle"
            :value="item.jobTitle">
        </el-option>
      </el-select>
    </div>
    <div>
      <div class="findTalentsBg" v-for="(item,index) in talentsList" :key="index">
        <div class="postFlex findBorderB" >
          <div class="postFlex findUser">
            <img :src="item.headPortraitUrl">
            <p>{{item.name}}</p>
            <p>{{ item.ageYear }}岁   |   {{item.workYear}}年   |   {{item.jobSchool}}     <span v-if="item.asState==0">在职-暂不考虑</span><span v-if="item.asState==1">在职-考虑机会</span><span v-if="item.asState==2">在职-月内到岗</span><span v-if="item.asState==3">离职-随时到岗</span></p>
          </div>
          <el-button v-if="!item.linkUp" type="primary" round style="background: #00C4AF;border-color: #00C4AF" @click="greetUser(item)">打招呼</el-button>
          <el-button v-if="item.linkUp" type="primary" round style="background: #00C4AF;border-color: #00C4AF" @click="continuecommunicate(item)">继续沟通</el-button>
        </div>
        <div class="postFlex "style="justify-content: space-between;font-size: 14px" @click="changeTalents(item)" >
          <div>
            <p>期望薪资：{{item.expectMoney[0]}}~{{item.expectMoney[1]}}k</p>
            <div style="display: flex;align-items: baseline;">
              <span style="display: inline-block;width: 70px;">个人优势：</span>
              <p class="finderInctruct" v-html="item.personalAdvantage"></p>
            </div>
          </div>
          <div>
            <div class="postFlex" v-for="(item1,index1) in item.map.userExperiences" :key="index1">
              <div class="findBorder">
                <p style="margin-bottom: 10px">{{item1.companyName}}</p>
              </div>
              <div class="findBorder" style="color: #747474">
                <p style="margin-bottom: 10px">{{item1.hiredate}}-{{ item1.dimission }}</p>
              </div>
              <div >
                <p style="margin-bottom: 10px">{{ item1.position }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="margin: 30px 0">
        <el-pagination
            class="text_center"
            background
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="pagesize"
            layout="total,  prev, pager, next, jumper"
            :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
        title="简历详情"
        :visible.sync="postUsershow"
        :show-close="true"
        :append-to-body="true"
        :destroy-on-close="true"
    width="70%">
      <div>
        <resumeDetail :postDetail="postDetail"></resumeDetail>
      </div>
    </el-dialog>
    <el-dialog
        @close="closeChitChat"
        title=""
        :visible.sync="greetWindowShow"
        :show-close="true"
        :append-to-body="true"
        :destroy-on-close="true"
        width="70%">
      <div>
        <chitchat :postDetail="postDetail" :friendList="friendList" :windowStage="windowStage" @getFriendList="getFriendList"></chitchat>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import resumeDetail from "@/views/xzJob/components/resumeDetail";
import chitchat from "@/views/xzJob/components/chitchat";
import {findfriendList, findPostUser, selectCompanyJobByCompanyId,browseTalents} from "@/api";
export default {
  components:{
    resumeDetail,
    chitchat
  },
  data(){
    return{
      currentPage:1,
      pagesize:10,
      total:0,
      findValue: '',
      postUsershow:false,
      greetWindowShow:false,
      talentsList:[],
      postDetail:{},
      userInfo:{},
      postList:[],
      friendList:[],
      windowStage:false
    }
  },
  methods:{
    //详情弹窗
    changeTalents(item){
      this.postUsershow=true
      this.postDetail=item
      let data={
        uId:this.userInfo.id,
        type:'QY',
        talentsId:item.uId
      }
      browseTalents(data).then(res=>{

      })
    },
    //继续沟通
    continuecommunicate(item){
      this.postDetail=item
      this.getFriendList()
      this.greetWindowShow=true;
    },
  //  打招呼
    greetUser(item){
      this.postDetail=item
      let that=this
      if(item.linkUp==false) {
        let obj = {
          user: {
            headPortraitUrl: item.headPortraitUrl,
            name: item.name
          },
          id: item.uId,
        }
        that.friendList.unshift(obj)
      }else{
        this.getFriendList()
      }
      this.greetWindowShow=true;
    },
    // 分页
    handleCurrentChange: function (currentPage) {
      this.currentPage = currentPage;
      this.getfindPostUser();
    },
    //获取职位列表
    getCompanyByJobUserId(){
      let data={
        companyId:this.userInfo.companyId?this.userInfo.companyId:this.userInfo.bindJobUserId,
        pageNumber:1,
        pageSize:100
      }
      selectCompanyJobByCompanyId(data).then(res=>{
        this.postList=res.data.records;
      })
    },
    //关闭聊天记录时的回调
    closeChitChat(){
      this.getFriendList();
      this.windowStage=true
    },
    //获取好友列表
    getFriendList(){
      let data={
        uId:this.userInfo.id
      }
      findfriendList(data).then(res=>{
        this.friendList=res.data
      })
    },
  //  人才列表
    getfindPostUser(){
      let data={
        page:this.currentPage,
        size:this.pagesize,
        jobPublishPersonId:this.userInfo.id
      }
      if(this.findValue){
        data.jobTitle=this.findValue
      }
      findPostUser(data).then(res=>{
        this.total=JSON.parse(res.data.total);
        this.talentsList=res.data.records;
        var date = new Date();
        var year = date.getFullYear();
        this.talentsList.forEach((item,index)=>{
          if(item.birthday){
            let ageYear=year-item.birthday.slice(0,4);
            item.ageYear=ageYear;
          }
          if(item.workTime){
            let workYear=year-item.workTime.slice(0,4);
            item.workYear=workYear;
          }
          if(item.map.userPosts&&item.map.userPosts.length&&item.map.userPosts[0].salaryMoney){
            item.expectMoney=item.map.userPosts[0].salaryMoney.split(',')
          }
        })
      })
    }
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.getCompanyByJobUserId()
    this.getfindPostUser();
    this.getFriendList()
  },
}
</script>
<style scoped>
@import "../../../../public/css/enterpriseIndex.css";
</style>
