<template>
  <div>
    <div class="positionCapacity">
      <div class="tjfx" >
        <p >能力素质</p>
        <div class="numberRadius">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div style="width: 90%;height:400px;top: -60px;" id="mainQuality"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import {getCapacityquality} from "@/api";
export default{
  props:{
    cityValue:''
  },
  data(){
    return{
      capacityArr:[],
      capacityData:[],
      province:''
    }
  },
  methods:{
    getCapacityqualityList(){
      let data={
        province:this.province
      }
      getCapacityquality(data).then(res=>{
        if(res.code==0){
          for (var item of res.data){
            let obj={
              value:''
            }
            this.capacityArr.push(item.name)
            obj.value=item.number
            this.capacityData.push(obj)
          }
          this.getCapacityDiathesis();
        }else{
          this.$message.error(res.msg)
        }
      })
    },
    //柱状图
    getCapacityDiathesis(){
      var chartDom = document.getElementById('mainQuality');
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          // data: ['Profit', 'Expenses', 'Income']
        },
        grid: {
          left: '5%',
          right: '3%',
          // bottom: '15%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'value',
            axisLine: {  //这是x轴文字颜色
              lineStyle: {
                color: "#fff",
              }
            },
            show: false
          },


        ],
        yAxis: [
          {
            type: 'category',
            axisTick: {
              show: false
            },
            axisLine: {  //这是Y轴文字颜色
              lineStyle: {
                color: "#fff",

              },
              show:false

            },
            data: this.capacityArr
          }
        ],
        series: [
          {
            name: '',
            type: 'bar',
            stack: 'Total',
            label: {
              show: true,
              position:'(0,60%)'
            },
            emphasis: {
              focus: 'series'
            },
            itemStyle:{
              normal:{
                color:'#1BA7F7'
              }
            },
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            },
            showBackground: true,
            data: this.capacityData
          },
          // {
          //   name: 'Expenses',
          //   type: 'bar',
          //   stack: 'Total',
          //   label: {
          //     show: true,
          //     position: 'left'
          //   },
          //   emphasis: {
          //     focus: 'series'
          //   },
          //   data: [-120, -132, -101, -134, -190, -230, -210]
          // }
        ]
      };
      option && myChart.setOption(option);
    }
  },
  watch:{
    cityValue(val,oldVal){
      if(val){
        this.capacityArr=[];
        this.capacityData=[];
        if(val!=='全国'){
          this.province=val;
          this.getCapacityqualityList();
        }else{
          this.province='';
          this.getCapacityqualityList();
        }
      }
    }
  },
  mounted() {
    this.getCapacityqualityList()

  }
}
</script>
<style scoped>
@import "../../../public/css/bigNumber.css";
</style>
