<template>
  <div class="xzJobNewsBg">
      <div>
        <img style="width: 100%" src="images/xzjobBanner.png"></img>
      </div>
      <div class="xzjobNewsPadding">
        <el-tabs v-model="activeName" class="xzjobNewTab" @tab-click="changeNews()">
          <el-tab-pane label="职场指南" name="1"></el-tab-pane>
          <el-tab-pane label="自我提升" name="2"></el-tab-pane>
        </el-tabs>
        <div class="xzjobNewsContent">
            <div class="xzjobcontentflex" v-for=" (item,index) in newsList" :key="index" @click="goNewsDetail(item)">
              <img :src="item.pictureUrl">
              <div class="xzjobNewsFont">
                <p>{{item.headline}}</p>
                <p v-html="item.article"></p>
                <p>
                  <span>{{item.createTime}}</span>
                  <span>{{item.articleSource}}</span>
                </p>
              </div>
            </div>
          <div style="margin: 30px 0">
            <el-pagination
                class="text_center"
                background
                @current-change="handleCurrentChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                layout="total,  prev, pager, next, jumper"
                :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
import {jobNewsList} from "@/api";

export default {
  data(){
    return{
      activeName: '1',
      newsList:[],
      currentPage:1,
      pagesize:10,
      total:0,
    }
  },
  methods:{
    //去新闻详情
    goNewsDetail(item){
      this.$router.push('/xzJobNewsDetail');
      sessionStorage.setItem('xzJobNews',JSON.stringify(item))
    },
    //切换tab栏
    changeNews(){
      this.getjobNewsList(this.activeName)
    },
    //获取新闻列表
    getjobNewsList(index){
      let data={
        page:this.currentPage,
        size:this.pagesize,
        categoryType:'guide'
      }
      if(index==1){
        data.categoryType='guide'
      }else{
        data.categoryType='lift'
      }
      jobNewsList(data).then(res=>{
        this.total=JSON.parse(res.data.total);
        this.newsList=res.data.records;
      })
    },
    // 分页
    handleCurrentChange: function (currentPage) {
      this.currentPage = currentPage;
      this.getjobNewsList();
    },

  },
  mounted() {
    this.getjobNewsList(this.$route.query.index);
    if(this.$route.query.index==2){
      this.activeName='2'
    }
  }
}
</script>
<style scoped>
@import "../../../../public/css/xzJob.css";
</style>



