<template>
  <div class="jobDetailBg">
    <div class="jobcomyTitleBg">
      <div>
        <img style="width: 100px" :src="compayDetail.companyVo?compayDetail.companyVo.companyLogoUrl:compayDetail.companyLogoUrl">
        <div class="comyDetailFlex">
          <p>
            <span>{{compayDetail.companyVo?compayDetail.companyVo.companyTitle:compayDetail.companyTitle}}</span>
            <span>{{allPostList.length}}</span>
          </p>
          <p>
            <span> {{compayDetail.companyVo?compayDetail.companyVo.companyScale:compayDetail.companyScale}} 丨 {{compayDetail.companyVo?compayDetail.companyVo.companyType:compayDetail.companyType}}</span>
            <span>在招职位</span>
          </p>
        </div>
      </div>
      <el-tabs v-model="activeName" >
      <el-tab-pane label="公司主页" name="company"></el-tab-pane>
      <el-tab-pane label="招聘职位" name="invite"></el-tab-pane>
    </el-tabs>
    </div>
    <div class="companyDetailContent">
      <template v-if="activeName=='company'">
        <div  style="display: flex;align-items: center">
          <div class="jobComyTitleColor">
            <span></span>
            <span>热招职位</span>
          </div>
          <el-pagination
              class="text_center"
              background
              @current-change="handlepostList"
              :current-page.sync="postPage"
              :page-size="postSize"
              layout="total,  prev, pager, next, jumper"
              :total="postTotal"
          >
          </el-pagination>
        </div>
        <div class="comyJobrecommend">
          <div class="comypostFlex" v-for="(item,index) in hotpostList.slice(0,4)" :key="index" @click="goPostDetail(item)">
            <p>
              <span>{{item.jobTitle}}</span>
              <span>{{item.jobMinimumSalary}}-{{item.jobMaximumSalary}}k</span>
            </p>
            <p>{{item.jobExperience}} · {{item.jobSchool}} · {{item.jobCity}}</p>
          </div>
        </div>
      </template>
      <div class="comyIntroductionInfo" >
        <div v-if="activeName=='company'" class="comyIntroductionInfoDiv">
          <div class="jobComyTitleColor">
            <span></span>
            <span>公司简介</span>
          </div>
          <p style="margin: 20px 0;">
            {{compayDetail.introduce}}
          </p>
<!--          <div class="jobComyTitleColor">-->
<!--            <span></span>-->
<!--            <span>公司地址</span>-->
<!--          </div>-->
<!--          <p style="margin-top: 12px;">地址：山东省济南市历城区北园大街15号正东方向130米，荣盛时代国际广场C座7楼</p>-->
        </div>
        <div v-if="activeName=='invite'" class="comyIntroductionDiv">
<!--          <div style="background: white;padding: 24px">-->
<!--            <el-tabs v-model="applications" >-->
<!--              <el-tab-pane label="用户管理" name="post"></el-tab-pane>-->
<!--              <el-tab-pane label="配置管理" name="second"></el-tab-pane>-->
<!--            </el-tabs>-->
<!--            <div class="filtrateEducation">-->
<!--              <el-select v-model="cityValue" placeholder="城市">-->
<!--                <el-option-->
<!--                    v-for="item in cityOptions"-->
<!--                    :key="item.value"-->
<!--                    :label="item.label"-->
<!--                    :value="item.value">-->
<!--                </el-option>-->
<!--              </el-select>-->
<!--              <el-select v-model="yearValue" placeholder="1-3年">-->
<!--                <el-option-->
<!--                    v-for="item in yearOptions"-->
<!--                    :key="item.value"-->
<!--                    :label="item.label"-->
<!--                    :value="item.value">-->
<!--                </el-option>-->
<!--              </el-select>-->
<!--              <el-select v-model="compensationValue" placeholder="薪资">-->
<!--                <el-option-->
<!--                    v-for="item in compensationOptions"-->
<!--                    :key="item.value"-->
<!--                    :label="item.label"-->
<!--                    :value="item.value">-->
<!--                </el-option>-->
<!--              </el-select>-->
<!--              <el-select v-model="educationValue" placeholder="本科">-->
<!--                <el-option-->
<!--                    v-for="item in educationOptions"-->
<!--                    :key="item.value"-->
<!--                    :label="item.label"-->
<!--                    :value="item.value">-->
<!--                </el-option>-->
<!--              </el-select>-->
<!--            </div>-->
<!--          </div>-->
          <div>
            <div class="educationinviteFlex" v-for="(item,index) in allPostList" :key="index">
              <div>
                <p class="educationinviteFont">
                  <span >{{item.jobTitle}} [{{item.jobCity}}]</span>
                  <span >发布于{{item.createTime}}</span>
                </p>
                <p>
                  <span style="color: #F68157">{{item.jobMinimumSalary}}-{{item.jobMaximumSalary}}K </span>
                  <span>丨{{item.jobMaximumSalary}} 丨 {{item.jobSchool}}</span>
                </p>
              </div>
              <div class="communicationManager" v-if="item.jobUser">
                <div class="tacitlyAvatarImg" style="display: flex;align-items: center">
                  <img :src="item.jobUser.headPortraitUrl">
                  <span>{{item.jobUser.name}}</span>
                </div>
              </div>
              <div class="communication">
                <el-button type="primary" @click="continueCommunicate(item)">立即沟通</el-button>
              </div>
            </div>
<!--            分页-->
            <div style="margin-top: 30px">
              <el-pagination
                  class="text_center"
                  background
                  @current-change="handleTeacherList"
                  :current-page.sync="currentPage"
                  :page-size="size"
                  layout="total,  prev, pager, next, jumper"
                  :total="total"
              >
              </el-pagination>
            </div>
          </div>
        </div>
        <div>
          <companyInfo :companyVo="compayDetail.companyVo?compayDetail.companyVo:compayDetail"></companyInfo>
        </div>
      </div>
    </div>
    <el-dialog
        title=""
        :visible.sync="greetWindowShow"
        :show-close="true"
        :append-to-body="true"
        :destroy-on-close="true"
        width="70%">
      <div>
        <chitchat :postDetail="postDetail" :friendList="friendList" @getFriendList="getFriendList"></chitchat>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import companyInfo from "@/views/xzJob/user/companyInfo";
  import {findfriendList, findPosition} from "@/api";
  import chitchat from "@/views/xzJob/components/chitchat";
  export default {
    components:{
      companyInfo,
      chitchat
    },
    data(){
      return{
        compayDetail:{},
        hotpostList:[],
        allPostList:[],
        postPage:1,
        postSize:4,
        postTotal:0,
        currentPage: 1, //初始页
        size: 10,
        total: 0,
        activeName:'company',
        applications:'post',
        cityValue: '',
        yearValue:'',
        compensationValue:'',
        educationValue:'',
        greetWindowShow:false,
        postDetail:{},
        friendList:[],
        userInfo:{}

      }
    },
    methods:{
      //去职位详情
      goPostDetail(item) {
        sessionStorage.setItem('JobPostDetail',JSON.stringify(item))
        let routeUrl = this.$router.resolve({
          path: "/jobDetail",
        });
        window.open(routeUrl.href);
      },
      //获取好友列表
      getFriendList(){
        let data={
          uId:this.userInfo.id
        }
        findfriendList(data).then(res=>{
          this.friendList=res.data
        })
      },
      //热招职位列表
      handlepostList(postPage){
        this.postPage = postPage;
        this.getfindPosition()
      },
      // 分页
      handleTeacherList: function (currentPage) {
        this.currentPage = currentPage;
        this.getPostList();
      },
      //  获取热招职位列表
      getfindPosition(){
        let data={
          findType:'hot',
          page:this.postPage,
          size:this.postSize
        }
        findPosition(data).then(res=>{
          this.postTotal = JSON.parse(res.data.total) ;
          this.hotpostList=res.data.records
        })
      },
    //  获取全部职位
      getPostList(){
        let data={
          companyId:this.$route.query.compayId,
          page:this.currentPage,
          size:this.size
        }
        findPosition(data).then(res=>{
          this.total = JSON.parse(res.data.total) ;
          this.allPostList=res.data.records
        })
      },
    //  继续沟通
      continueCommunicate(item){
        this.postDetail=item
        this.getFriendList()
        this.greetWindowShow=true;
      }
    },
    mounted() {
      this.getfindPosition();
      this.getPostList()
      this.compayDetail=JSON.parse(sessionStorage.getItem('compayDetail'))
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
      this.getFriendList()
    }
  }
</script>
<style scoped>
@import "../../../../public/css/recommendJobList.css";
</style>
