<template>
  <div class="hotEnterPriseListBg">
    <div>
      <div style="display: flex;align-items: center;margin-bottom: 16px">
        <span style="margin-right: 10px">当前位置:</span>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/xzjob' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item><p>热门企业</p></el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div style="width: 1000px;margin: 0 auto">
        <el-input placeholder="请输入内容" v-model="recommendSearch" class="input-with-select" @change="getfindCompany">
          <el-button slot="append" icon="el-icon-search" @click="getfindCompany" ></el-button>
        </el-input>
      </div>
<!--      筛选-->
      <div class="hotEnterListContent">
        <div class="hotEnterFlex hotEnterFlexSpace">
          <div class="hotEnterFlex ">
            <p class="hotListFontWight hotEnterMr">公司地点:</p>
            <p class="hotCupaior">{{cityName}}</p>
          </div>
          <p class="hotCupaior" @click="allCity()">全部城市></p>
        </div>
<!--        <div class="hotEnterFlex">-->
<!--          <p class="hotListFontWight hotEnterMr">融资阶段:</p>-->
<!--          <p class="hotCupaior">上市</p>-->
<!--        </div>-->
        <div class="hotEnterFlex">
          <p class="hotListFontWight hotEnterMr">公司规模:</p>
          <p class="hotCupaior"  :class="scaleShow==index?'scaleActive':''" v-for="(item,index) in scale" :key="index" @click="companyScale(item,index)">{{item.name}}</p>
        </div>
        <div class="hotEnterFlex hotEnterFlexSpace">
          <div class="hotEnterFlex">
            <p class="hotListFontWight hotEnterMr">行业领域:</p>
            <p class="hotCupaior" :class="industryShow==index?'scaleActive':''" v-for="(item,index) in industryLists" :key="index" @click="chnageindustry(item,index)">{{item.name}}</p>
          </div>
<!--          <p class="hotCupaior">-->
<!--            <span>更多</span>-->
<!--            <i class="el-icon-caret-bottom"></i>-->
<!--          </p>-->
        </div>
      </div>
<!--      排序-->
      <div class="hotsortFlex">
        <p class="hotListFontWight hotEnterMr">排序方式:</p>
        <p>
          <span @click="sortScreen(0,'')">默认排序</span>
          <span @click="sortScreen(1,'positionCountDesc')" v-if="!postNumber">职位数量
            <i class="el-icon-bottom"></i>
          </span>
          <span @click="sortScreen(4,'positionCountAsc')" v-if="postNumber">职位数量
             <i class="el-icon-top"></i>
          </span>
          <span @click="sortScreen(2,'salaryAsc')">最高工资</span>
          <span @click="sortScreen(3,'salaryDesc')">最低工资</span>
        </p>
      </div>
<!--      列表-->
      <div class="hotEnterComyList">
        <div class="jobhotEnterPriseContent" v-for="(item,index) in companyList" :key="index" @click="goCompayDetail(item)">
          <img :src="item.companyLogoUrl" class="jobMrb">
          <p class="jobMrb">{{item.companyTitle}}</p>
          <p class="jobMrb">
            <span style="color: #999999;font-size: 14px">{{item.companyScale}}   丨   {{item.companyType}}</span>
          </p>
          <p>
            <span>{{item.positionCount}}</span>
            <span>个热招职位</span>
          </p>
        </div>
        <div v-if="!companyList.length" style="width: 100%">
          <el-empty :image-size="200"></el-empty>
        </div>
      </div>
<!--     分页-->
      <div style="margin: 30px 0">
        <el-pagination
            class="text_center"
            background
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="pagesize"
            layout="total,  prev, pager, next, jumper"
            :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog :visible.sync="cityWindowShow" :show-close="true" width="1000px">
      <div>
        <p class="cityBorder">直辖市、省及自治区</p>
        <div style="display: flex;align-items: baseline;" >
          <div class="citySheng" >
            <div v-for="(item,index) in cityList" :key="index">
              <p class="cityShengp" @click="changeCity(item,index)" :class="cityShow==index?'cityActive':''">{{item.name}}</p>
            </div>
          </div>
          <div class="cityShi" >
            <p class="cityShip" :class="cityquShow==index?'cityquShowActive':''" v-for="(item,index) in jobCityArr" :key="index" @click="changeDistrict(item,index)">{{item.name}}</p>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {findCompany,industryList} from "@/api";
import {getCityList} from "@/utils/city";
export default {
  data(){
    return{
      recommendSearch:'',
      companyList:[],
      scale:[
        {id:1,name:'不限'},
        {id:2,name:'0-20人'},
        {id:3,name:'20-99人'},
        {id:4,name:'100-499人'},
        {id:5,name:'499-1000人'},
        {id:6,name:'1000人以上'},
      ],
      industryLists:[],
      ScaleValue:'',
      scaleShow:0,
      industryName:'',
      industryShow:0,
      cityWindowShow:false,
      cityList:[],
      jobCityArr:[],
      cityName:'济南',
      cityShow:14,
      cityquShow:0,
      currentPage:1,
      pagesize:10,
      total:0,
      sortValue:'',
      postNumber:false
    }
  },
  methods:{
    //去公司详情
    goCompayDetail(item){
      let routeUrl = this.$router.resolve({
        path: "/companyDetail",
        query:{compayId:item.id}
      });
      sessionStorage.setItem('compayDetail',JSON.stringify(item))
      window.open(routeUrl.href);
    },
    // 分页
    handleCurrentChange: function (currentPage) {
      this.currentPage = currentPage;
      this.getfindCompany();
    },
    //点击城市,显示区
    changeDistrict(item,index){
      this.cityName=item.name
      this.districtArr=item.area;
      this.cityWindowShow=false;
      this.cityquShow=index
      this.getfindCompany()
    },
    //点击省份,显示城市
    changeCity(item,index){
      this.jobCityArr=item.city;
      this.cityShow=index;
      this.cityquShow=0
    },
    //显示全部城市
    allCity(){
      this.cityWindowShow=true;
    },
    //公司规模
    companyScale(item,index){
      if(item.name!=='不限'){
        this.ScaleValue=item.name;
      }else{
        this.ScaleValue=''
      }
      this.getfindCompany();
      this.scaleShow=index
    },
    //排序方式
    sortScreen(index,item){
      this.sortValue=item;
      this.getfindCompany();
      if(index==1){
        this.postNumber=true
      }
      if(index==4){
        this.postNumber=false
      }
    },
    //  热门企业列表
    getfindCompany(){
      let data={
        page: this.currentPage,
        size: this.pagesize,
      }
      if(this.recommendSearch){
        data.str=this.recommendSearch
      }
      if(this.ScaleValue){
        data.companyScale=this.ScaleValue
      }
      if(this.industryName){
        data.companyType=this.industryName
      }
      if(this.sortValue){
        data.sortOrd=this.sortValue
      }
      findCompany(data).then(res=>{
        this.companyList=res.data.records;
        this.total=JSON.parse(res.data.total);
      })
    },
    //切换行业领域
    chnageindustry(item,index){
      if(item.name!=='不限'){
        this.industryName=item.name;
      }else{
        this.industryName=''
      }
      this.getfindCompany();
      this.industryShow=index
    },
  //  获取行业领域
    getindustryList(){
      industryList().then(res=>{
        this.industryLists=res.data;
        this.industryLists[0]={name:'不限'}
      })
    }
  },
  mounted() {
    this.getfindCompany();
    this.getindustryList();
    let cityArr=getCityList();
    this.cityList=cityArr;
    this.jobCityArr=cityArr[14].city;
    this.districtArr=cityArr[14].city[0].area
    this.cityName=cityArr[14].city[0].name
  }
}
</script>
<style scoped>
@import "../../../../public/css/hotEnterpriseList.css";
</style>
