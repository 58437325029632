<template>
  <div class="inviteInfoBg">
    <div class="inviteInfoContent">
      <div style="display: flex;align-items: center;margin-bottom: 16px">
        <span style="margin-right: 10px">当前位置:</span>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/xzjob' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item><p >我的资料</p></el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="inviteInfoFlex">
        <div>
          <p style="margin-bottom: 15px;text-align: center">简历目录</p>
          <div class="inviteFlexTab" v-for="(item,index) in inviteCatalogue" :key="index" @click="changeInvite(index)" :class="tabIndex == index ? 'inviteActiveindex' : ''">
            <img :src="item.img">
            <span class="inviteCatalogueFont">{{item.name}}</span>
          </div>
        </div>
<!--        我的在线简历-->
        <div class="resumeBg right">
          <div style="justify-content: space-between;padding-bottom: 18px;" class="inviteInfoResumeFlex">
            <div class="inviteInfoResumeFlex">
              <p>我的在线简历</p>
<!--              <p>查看信息图谱</p>-->
            </div>
<!--            <div class="inviteInfoResumeFlex">-->
<!--              <i class="el-icon-download"></i>-->
<!--              <p style="color: #409EFF">下载简历</p>-->
<!--            </div>-->
          </div>
<!--          头像-->
            <userInfoAvartar id="page0" :jobUser="userJobResume.jobUser" @getfindUserByuId="getfindUserByuId"></userInfoAvartar>
            <!--          个人优势-->
            <personalSuperiority id="page1" :jobUser="userJobResume.jobUser" @getfindUserByuId="getfindUserByuId"></personalSuperiority>
            <!--          期望职位-->
            <expectedPosition id="page2" :userPosts="userJobResume.userPosts" @delectEducationInfo="delectEducationInfo" @getfindUserByuId="getfindUserByuId" @postId="postId"></expectedPosition>
            <!--          工作经历-->
            <workExperience id="page3" :userExperiences="userJobResume.userExperiences" @getfindUserByuId="getfindUserByuId" @delectEducationInfo="delectEducationInfo" @experienceId="experienceId"></workExperience>
            <!--          项目经验-->
            <projectExperience id="page4" :userProjects="userJobResume.userProjects" @getfindUserByuId="getfindUserByuId" @delectEducationInfo="delectEducationInfo"  @projectId="projectId"></projectExperience>
            <!--          教育经历-->
            <educationExperience id="page5" :userSchools="userJobResume.userSchools" @getfindUserByuId="getfindUserByuId"></educationExperience>
        </div>
<!--        附件简历-->
        <accessoryResume :jobResources="userJobResume.jobResources" @getfindUserByuId="getfindUserByuId"></accessoryResume>
      </div>
    </div>

  </div>
</template>
<script>
import accessoryResume from './accessoryResume.vue'
import personalSuperiority from './personalSuperiority.vue'
import expectedPosition from './expectedPosition.vue'
import workExperience from './workExperience.vue'
import projectExperience from './projectExperience.vue'
import educationExperience from './educationExperience.vue'
import userInfoAvartar from './userInfoAvartar.vue'
import _ from 'lodash'
import {findUserByuId, delectEducation, deleteTeacherCoursePreper} from "@/api";
export default {
  components:{
    accessoryResume,
    personalSuperiority,
    expectedPosition,
    workExperience,
    projectExperience,
    educationExperience,
    userInfoAvartar

  },
  data(){
    return{
      inviteCatalogue:[
        {id:1,name:'个人信息',img:'images/numberIcon1.png'},
        {id:2,name:'个人优势',img:'images/numberIcon2.png'},
        {id:3,name:'期望职位',img:'images/numberIcon3.png'},
        {id:4,name:'工作经历',img:'images/numberIcon6.png'},
        {id:5,name:'项目经验',img:'images/numberIcon4.png'},
        {id:6,name:'教育经历',img:'images/numberIcon5.png'},
      ],
      // 当前高亮的 title 标志
      tabIndex: 0,
      userInfo:{},
      userJobResume:{},
      postIdValue:'',
      experienceIdValue:'',
      projectIdValue:''
    }
  },
  methods:{
    changeInvite(key) {
      // debugger
      // 获取点击的按钮对应页面的id
      var PageId = document.querySelector('#page' + key)
      // 使用平滑属性，滑动到上方获取的距离
      // 下方我只设置了top，当然 你也可以加上 left 让他横向滑动
      // widow 根据浏览器滚动条，如果你是要在某个盒子里面产生滑动，记得修改
      window.scrollTo({
        'top': PageId.offsetTop - 100,
        'behavior': 'smooth'
      })
      this.tabIndex=key

    },
    //项目经验
    projectId(item){
      this.projectIdValue=item
    },
    //经历
    experienceId(item){
      this.experienceIdValue=item
    },
    //期望
    postId(item){
      this.postIdValue=item
    },
  //  删除信息
    delectEducationInfo(){
      let data={

      }
      if(this.postIdValue){
        let post={
          id:this.postIdValue
        }
        data.post=post
      }
      if(this.experienceIdValue){
        let experience={
          id:this.experienceIdValue
        }
        data.experience=experience
      }
      if(this.projectIdValue){
        let project={
          id:this.projectIdValue
        }
        data.project=project
      }
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delectEducation(data).then(res=>{
          if(res.code===0){
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getfindUserByuId()
          }else{
            this.$message.error(res.msg);
          }
        })
      }).catch(() => {

      });
    },
  //  获取个人简历信息
    getfindUserByuId(){
      let data={
        uId:this.userInfo.id
      }
      findUserByuId(data).then(res=>{
        let asState=res.data.jobUser.asState.toString()
        this.userJobResume=res.data;
        this.userJobResume.jobUser.asState=asState;
        var date = new Date();
        var year = date.getFullYear();
        let workTimeSlice=res.data.jobUser.workTime.slice(0, 4);
        this.userJobResume.jobUser.workTimeSlice=year-workTimeSlice
        let birthdayTime=res.data.jobUser.birthday.slice(0, 4)
        this.userJobResume.jobUser.birthdayTime=year-birthdayTime
      })
    },

  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.getfindUserByuId()
  },
  created() {
  },
  destroyed() {
  },
}
</script>
<style scoped>
@import "../../../../public/css/inviteInfo.css";
</style>
