<template>
  <div class="numberCase">
    <p class="numberServecenter">行业人才供给匹配谱系图项目</p>
    <div class="numberServeFont">
      <p>
        谱系图利用大数据云图直观呈现行业人才需求和院校人才培养供给匹配情况。通过行业人才需求预测与相关专业培养规模对比，明确人才供需差距，<br>
        找出缺口。同时，通过对比岗位群与专业群的结构层次、产业布局与专业布局，以及分析市场人才能力素质要求与院校人才培养规格的差异，从中找<br>
        到职业院校专业人才培养改进调整的方向，指导专业布局，优化专业设置。
      </p>
    </div>
    <div class="numberFlexMap" style="margin-top: 30px">
      <img src="images/bigNumber.png">
      <div class="numberLink" >
        <img style="width: 20px;height: 20px" src="images/aNumber.png">
        <p>友情链接:</p>
        <p @click="changeBigNumber">点击查看详情</p>
      </div>
    </div>
    <p class="numberServecenter">电子商务创新人才服务平台项目</p>
    <div class="numberServeFont">
      <p>
        本项目是大数据专项服务平台职称创新型人才服务于发展的落地应用，旨在对标学校双高建设方案中“加强技术技能人才建设”一项，依托大数据技术、<br>
        数据资源及数据分析应用成果，对内指导人才培养，对外提供公共服务。
      </p>
    </div>
    <div class="numberFlexMap" style="margin-top: 30px;margin-bottom: 100px">
      <img src="images/numberImg.png">
      <div class="numberLink" >
        <img style="width: 20px;height: 20px" src="images/aNumber.png">
        <p>友情链接:</p>
        <p>点击查看详情</p>
      </div>
    </div>

  </div>
</template>
<script>
export default{
  data(){
    return{

    }
  },
  methods:{
  //  去大数据页面
    changeBigNumber(){
      let routeUrl = this.$router.resolve({
        path: "/bigNumber",
      });
      window.open(routeUrl.href);
    }
  }
}
</script>
<style scoped>
@import "../../../public/css/bigNumber.css";
</style>









